import React, {Fragment} from 'react';
import {
  Button,
  Modal,
  Grid,
  Paper,
  CircularProgress,
  Table,
  TableRow,
  TableBody,
  TablePagination,
  TableCell,
  TableSortLabel,
  Box,
  makeStyles,
  DialogTitle, Dialog, DialogActions, DialogContent, IconButton 
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import {
  StyledTableHead,
  StyledTableRow,
  StyledTableCell,
  StyledTableFooter,
  StyledTableFooterReport,
  StyledTableColCell
} from "~/components/StyledTable";
import moment from 'moment';
import './styles.scss';
import Cookies from "universal-cookie";
const cookies = new Cookies(window.document.cookie);

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

export function ReportViewer(props) {
    const {title, px, py, onConfirm, clickOutsideToClose, onClose, previewProgress, downloadProgress, items, page, rowsPerPage, handleDownload, handlePageChange, handleRowsPerPageChange,
    order, orderBy, onRequestSort, canDownload} = props;
    return (
        <Dialog
            fullScreen={true}
            fullWidth={true}
            open={true}
            onClose={() => (clickOutsideToClose || clickOutsideToClose === undefined) ? onConfirm() : null}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Box py={py || 6} px={px || 6}>
                {onClose ? (
                    <IconButton aria-label="close" className="closeButton" onClick={() => onClose()}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
                
                <DialogContent className="alert-dialog-message">
                    {previewProgress ? (
                    <Box display="flex" p={5} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
                    ):(
                    <Table id="report-list">
                        <StyledTableHead style={{fontWeight:'bold'}}>
                            <TableRow>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:'bold', fontSize:18}} colSpan={8}>Merged User Report</StyledTableColCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} >
                                    OEID
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} >
                                    {cookies.get("mclient") === "acg" ? 'Producer Name' : 'Officer Name' }
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} >
                                    PORT BU
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} >
                                    Portfolio Name
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} >
                                    Total Rewards Earned
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} >
                                    W9 Submission Date
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} >
                                    Profile Merge Date
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} >
                                    Profile Merged by OEID-PBU
                                </StyledTableColCell>
                            </TableRow>
                        </StyledTableHead>
                        <TableBody>
                            {
                            (items || rowsPerPage > 0
                                ? stableSort(items, getSorting(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : items)
                            .map((item, index) => (
                                <Fragment key={index}>
                                    <StyledTableRow>
                                        <StyledTableColCell style={{width:"300px",wordWrap: "anywhere", verticalAlign:"text-top"}}>{item.OEID}</StyledTableColCell>
                                        <StyledTableColCell style={{width:"300px",wordWrap: "anywhere", verticalAlign:"text-top"}}>{item.OfficerName}</StyledTableColCell>
                                        <StyledTableColCell style={{width:"300px",wordWrap: "anywhere", verticalAlign:"text-top"}}>{item.PORTBU}</StyledTableColCell>
                                        <StyledTableColCell style={{width:"300px",wordWrap: "anywhere", verticalAlign:"text-top"}}>{item.PortfolioName}</StyledTableColCell>
                                        <StyledTableColCell style={{width:"300px",wordWrap: "anywhere", verticalAlign:"text-top"}}>{item.RewardsAvailableAmount}</StyledTableColCell>
                                        <StyledTableColCell style={{width:"250px",wordWrap: "anywhere", verticalAlign:"text-top"}}>{item.W9SubmissionDate}</StyledTableColCell>
                                        <StyledTableColCell style={{width:"300px",wordWrap: "anywhere", verticalAlign:"text-top"}}>{item.ProfileMergeDate}</StyledTableColCell>
                                        <StyledTableColCell style={{width:"300px",wordWrap: "anywhere", verticalAlign:"text-top"}}>{item.LastOEID_PBULoggedIn}</StyledTableColCell>
                                    </StyledTableRow>
                                </Fragment>
                            ))
                            }
                        </TableBody>
                        <StyledTableFooterReport>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 50, { label: 'All', value: -1 }]}
                                    colSpan={8}
                                    count={items.length || 0}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onChangePage={(event, page)=>handlePageChange(event, page)}
                                    onChangeRowsPerPage={(event) => handleRowsPerPageChange(event)}
                                />
                            </TableRow>
                            <TableRow>
                                <StyledTableCell colSpan={8}>
                                {downloadProgress ? (
                                    <Box width="100px" display="flex" mt={1.875} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
                                    ) : (
                                      <div>{canDownload && <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
                                      <Button variant="contained" color="primary" className="btns save" onClick={(event)=>handleDownload(event)} >Download</Button>
                                      </Box>}
                                      </div>
                                    )}
                                </StyledTableCell>
                            </TableRow>
                        </StyledTableFooterReport>
                    </Table>
                )}
                </DialogContent>
            </Box>
        </Dialog>
    );
}