import React from "react";
import "./styles.scss";
import {Grid, Box, TextField, MenuItem, CircularProgress} from "@material-ui/core";

const taxationOverview =(props)=> {
    const {taxationData, onDateSelect, years, selectedYear} = props;
    const {totalCount, submitted, notSubmitted} = taxationData;

    const submittedBarWidth = totalCount> 0? (submitted.count / totalCount * 100):0;
    const submittedTRWidth  = submitted.count> 0? (submitted.thr / submitted.count * 100):0;
    const submittedTnRWidth = submitted.count> 0? (submitted.thnr / submitted.count * 100):0;
    const notSubmittedBarWidth = totalCount> 0? (notSubmitted.count / totalCount * 100):0;
    const notSubmittedTRWidth  = notSubmitted.count> 0? (notSubmitted.thr / notSubmitted.count * 100):0;
    const notSubmittedTnRWidth = notSubmitted.count> 0? (notSubmitted.thnr / notSubmitted.count * 100):0;
    return (
      <Grid container>
        <Grid item xs={12} className="taxStatusBox">
          <Grid container>
            <Grid item xs={9} md={9} lg={11}>
              <Box pb={2} fontWeight={500}>
                {" "}
                W9 Submission Status
              </Box>
            </Grid>
            <Grid item xs={3} md={3} lg={1}>
              <TextField
                    fullWidth={true}
                    className="dateSelect"
                    select
                    value={selectedYear}
                    autoComplete="off"
                    variant="outlined"
                    onChange={(event) => onDateSelect(event)}
                >
                    {years ? years.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    )) :
                        (
                            <Box width="100px" display="flex" mt={1.875} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
                        )
                    }
                </TextField>
            </Grid>
          </Grid>

        <Grid container>
            <Grid item xs={6} className="rightBorder">
                <Grid item xs={12} container justify="flex-end" direction="row" alignItems="center">
                    <Box m={2}>
                        <div className="statusWrap">
                            <div className="statusText">
                                <span className="statusColor" style={{backgroundColor: "#f39f90"}}></span>
                                <span>{" "} Threshold Reached (TR)</span>
                            </div>
                            <div className="statusAmount">{submitted.thr || 0}</div>
                        </div>
                    </Box>
                    <Box m={2}>
                        <div className="statusWrap">
                            <div className="statusText">
                                <span className="statusColor" style={{backgroundColor: "#b2c7d3"}}></span>
                                <span>{" "} Threshold not Reached (TnR)</span>
                            </div>
                            <div className="statusAmount">{submitted.thnr || 0}</div>
                        </div>
                    </Box>
                </Grid>
                <Grid container className="section-container">
                     <Grid item md={3} className="section-title">{`Submitted - ${submitted.count}`}</Grid>
                    <Grid item md={9}>
                        <Box bgcolor="#d8d8d8" display="flex" width="96%" height="18px" my={1}>
                            <Box bgcolor="#f39f90" width={`${submittedTRWidth}%`} maxWidth="100%" />
                            <Box bgcolor="#b2c7d3" width={`${submittedTnRWidth}%`} maxWidth="100%" />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            
            <Grid item xs={6}>
                 <Grid item xs={12} style={{ paddingLeft: "5rem" }}container justify="flex-end" direction="row" alignItems="center">
                    <Box m={2}>
                        <div className="statusWrap">
                            <div className="statusText">
                                 <span className="statusColor" style={{backgroundColor: "#f39f90"}}></span>
                                <span>{" "} Threshold Reached (TR) </span>
                            </div>
                            <div className="statusAmount">{notSubmitted.thr || 0}</div>
                        </div>
                    </Box>
                    <Box m={2}>
                        <div className="statusWrap">
                            <div className="statusText">
                                <span className="statusColor" style={{backgroundColor: "#b2c7d3"}}></span>
                                <span>{" "} Threshold not Reached (TnR)</span>
                            </div>
                            <div className="statusAmount">{notSubmitted.thnr || 0}</div>
                        </div>
                    </Box>
                </Grid>
                <Grid container className="section-container">
                    <Grid item md={3} className="section-title">{`Not Submitted - ${notSubmitted.count}`}</Grid>
                    <Grid item md={9}>
                        <Box bgcolor="#d8d8d8" display="flex" width="100%" height="18px" my={1}>
                            <Box bgcolor="#f39f90" width={`${notSubmittedTRWidth}%`} maxWidth="100%" />
                            <Box bgcolor="#b2c7d3" width={`${notSubmittedTnRWidth}%`} maxWidth="100%"  />
                        </Box>
                    </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
  );
}

export default  taxationOverview;