import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'
import { Container, Grid, Paper, Button, CircularProgress, Box, Table, TableHead, TableRow, TableBody, TableFooter, TablePagination, TableCell } from '@material-ui/core';
import { StyledTableHead, StyledTableRow, StyledTableCell, StyledTableFooter } from '~/components/StyledTable'
import { Link } from "react-router-dom"
import { getFileStatus, getFileRewards, getFileValidationStatus, getFileErrorByStatus, approveRejectFile } from '~/redux/helpers/upload'
import Notification from '~/components/Notification'
import { ConfirmDialog, AlertDialog } from '~/components/Dialogs'
import './styles.scss'
import currency from 'currency.js';
import Cookies from "universal-cookie";
const cookies = new Cookies(window.document.cookie);

class RewardsUploadStatus extends Component {
    state = {
        FileRecordID: this.props.match.params.FileRecordID,
        fetchingDetails: true,
        fetchingValidationStatus: false,
        fetchingError: {},
        FileStatus: null,
        FileStatusID: 0,
        FileName: null,
        FileDetails: {},
        FileErrors: {},
        error: false,
        validationError: null,
        showErrorGrid: false,
        errorStatus: '',
        processingApproveReject: false,
        showApproveConfirm: false,
        showRejectConfirm: false,
    }
    componentDidMount() {
        const { FileRecordID } = this.state;
        this.getFileStatus();
        this.fetchRewardDetails();
    }
    onConfirmApprove = () => {
        this.processApproveReject('Approve')
    }
    onConfirmReject = () => {
        this.processApproveReject('Reject')
    }
    onCancelApprove = () => {
        this.setState({
            showApproveConfirm: false
        })
    }
    onCancelReject = () => {
        this.setState({
            showRejectConfirm: false
        })
    }
    returnPromotionIds(list) {
        let str = "";
        list && list.forEach(elm => {
            if (!str.includes(elm.PromotionId)) {
                str = str + elm.PromotionId + ",";
            }
        })
        str = str.replace(/,\s*$/, "");
        return str;
    }

    getFileStatus = () => {
        const { FileRecordID } = this.state;
        getFileStatus(FileRecordID).then(response => {
            if (response && response.StatusId) {
                this.setState({
                    isError: response.StatusId == 2 ? false : true,
                    validationError: response.StatusId == 2 ? "Exception" : "File Rejected",
                    FileStatusID: response.StatusId,
                    showErrorGrid: (response.StatusId == 2 || response.StatusId == 3) ? true : false,
                    showProgramGrid: (response.StatusId == 7 || response.StatusId == 8) ? false : true,
                    errorStatus: response.InternalStatusName,
                    FileName: response.FileName
                });
            }
        })
    }

    paginateErrors(error) {
        //const {}
        let Data = error.Data;
        let pageNumber = error.PageNumber || 0;
        let rowsPerPage = error.RowsPerPage || 10;
        let lowerLimit = (pageNumber * rowsPerPage);
        let upperLimit = (pageNumber * rowsPerPage) + rowsPerPage > error.Data.length ? error.Data.length : (pageNumber * rowsPerPage) + rowsPerPage;
        return Data.slice(lowerLimit, upperLimit).map((item, index) =>
            (<Fragment key={index}>
                <StyledTableRow>
                    {_.map(item, (value, key) => <StyledTableCell>{value}</StyledTableCell>)}
                </StyledTableRow>
            </Fragment>))
    }

    fetchRewardDetails = () => {
        const { FileRecordID } = this.state
        this.setState({
            fetchingDetails: true
        }, () => {
            getFileRewards(FileRecordID)
                .then(response => {
                    if (response.Data.length == 0) {
                        this.setState({
                            fetchingDetails: false
                        }, () => this.fetchValidationStatus())
                    } else {
                        if (!response.error) {
                            //let validationError = null
                            //if(response.Data[0].PromotionFund < response.Data[0].TotalRewards){
                            // validationError = 'The sum of the total Rewards in this file is greater than the assigned fund value for this promotion. Please correct the assigned funds for this promotion and resubmit the file.'
                            // }
                            //if(response.Data[0].PromotionFund == 0 && response.Data[0].TotalRewardees == 0 && response.Data[0].TotalRewards == 0){
                            //   validationError = 'The sum of the total Rewards in this file is greater than the assigned fund value for this promotion. Please correct the assigned funds for this promotion and resubmit the file.'
                            //}
                            this.setState({
                                FileDetails: response.Data,
                                fetchingDetails: false
                            }, () => this.fetchValidationStatus())
                        } else {
                            this.setState({
                                fetchingDetails: false,
                                error: response.error
                            })
                        }
                    }
                }).catch(err => {
                    alert("Something went wrong.");
                })
        })
    }
    fetchValidationStatus = () => {
        const { FileRecordID, FileErrors } = this.state
        this.setState({
            fetchingDetails: true,
            fetchingValidationStatus: true
        }, () => {
            getFileValidationStatus(FileRecordID)
                .then(response => {
                    if (!response.error) {
                        let FileErrors = {}
                        response.Data.map(item => {
                            //if(item.StatusID != 1 && item.StatusID != 5){
                            //    isError = true
                            //}
                            if (item.FileCount > 0) {
                                FileErrors[item.OrderNo] = {
                                    isLoading: true,
                                    ShowGrid: item.ShowGrid,
                                    Description: item.Description,
                                    IsError: item.isError,
                                    Data: []
                                }
                                this.fetchFileError(FileRecordID, item.OrderNo, item.StatusID, item.Description, item.ShowGrid, item.isError)
                            }
                        })
                        this.setState({
                            fetchingDetails: false,
                            fetchingValidationStatus: false,
                            FileErrors
                        })
                    }
                })
        })
    }
    fetchFileError = (FileRecordID, OrderNo, StatusID, Description, ShowGrid, IsError) => {
        getFileErrorByStatus(FileRecordID, StatusID)
            .then(response => {
                if (!response.error) {
                    const { FileErrors } = this.state;
                    console.log(ShowGrid);
                    console.log("Errordata", response.Data);
                    this.setState({
                        FileErrors: {
                            ...FileErrors,
                            [OrderNo]: {
                                isLoading: false,
                                Description: Description,
                                Data: response.Data,
                                ShowGrid: ShowGrid,
                                IsError: IsError
                            }
                        }
                    }, () => console.log(this.state.FileErrors))
                }
            })
    }

    handleApproveRejectFile = (Status) => {
        if (Status == 'Approve') {
            this.setState({
                showApproveConfirm: true
            })
        } else {
            this.setState({
                showRejectConfirm: true
            })
        }
    }

    processApproveReject = (Status) => {
        const { FileRecordID, FileErrors } = this.state
        this.setState({
            processingApproveReject: true,
            showRejectConfirm: false,
            showApproveConfirm: false
        }, () => {
            const {clientKey} = this.props;
            if (Status == "Resubmit") {
                this.props.history.push(`/${clientKey}/rewards/upload`)
            } else {
                approveRejectFile(FileRecordID, Status)
                    .then(response => {
                        if (!response.error) {
                            this.props.history.push(`/${clientKey}/rewards/upload`)
                        }
                    })
            }
        })
    }
    handlePageChange = (e, PageNumber, error) => {
        error.PageNumber = PageNumber;
        this.setState({ ...this.state });
    }
    handleRowsPerPageChange = (e, error) => {
        error.RowsPerPage = e.target.value;
        this.setState({ ...this.state });
    }
    render() {
        const { showProgramGrid, showErrorGrid, errorStatus, FileName, FileDetails, FileRecordID, FileErrors, fetchingDetails, fetchingValidationStatus, error, isError, showApproveConfirm, showRejectConfirm, processingApproveReject, validationError, rowsPerPage, page } = this.state;
        const {clientKey} = this.props;
        if (fetchingDetails) {
            return <Box display="flex" p={10} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
        }
        // console.log("FileErrors", FileErrors)
        return (
            <div id="fileUploadCont">
                <Container>
                    <Link to={`/${clientKey}/rewards/upload`}>  <Button variant="contained" className="backBtn">Back</Button> </Link>
                    <Paper square className="mainContainer">
                        <Grid container>
                            <Grid item md={12}>
                                <div className="headingText">Fulfillment File Details</div>
                                <div className="subhead bold" style={{margin:0}}>File Name and Summary:</div>
                            </Grid>
                            <hr className="hr" />
                            {fetchingDetails ? (
                                <Box display="flex" p={10} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
                            ) : (
                                    <Grid item md={7} xs={12}> <input type="text" disabled className="inputField" value={FileName} /></Grid>
                                )}

                            <Grid container>
                                {showProgramGrid && <Grid item md={7}>
                                    <Grid container>
                                        {fetchingDetails ? (
                                            <Box display="flex" p={10} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
                                        ) : (
                                                <Fragment style={{ overflowX: 'auto' }}>
                                                    <Table>
                                                        <StyledTableHead>
                                                            <TableRow>
                                                                <StyledTableCell className="programIdText">{cookies.get("mclient") === "hd" ? 'Promotion ID' : 'Program ID'}</StyledTableCell>
                                                                <StyledTableCell className="programIdText">{cookies.get("mclient") === "hd" ? 'Total Amount' : 'Total Rewards'}</StyledTableCell>
                                                                <StyledTableCell className="programIdText">{cookies.get("mclient") === "hd" ? 'Total Count ' : 'Total Rewardees'}</StyledTableCell>
                                                            </TableRow>
                                                        </StyledTableHead>
                                                        <TableBody>
                                                            {FileDetails.map((item, index) => (
                                                                <StyledTableRow>
                                                                    <StyledTableCell>{item.PromotionID}</StyledTableCell>
                                                                    <StyledTableCell>{currency(item.TotalRewards, { formatWithSymbol: true }).format()}</StyledTableCell>
                                                                    <StyledTableCell>{item.TotalRewardees}</StyledTableCell>
                                                                </StyledTableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </Fragment>
                                            )}
                                    </Grid>

                                </Grid>}
                                <Grid item md={12} style={{marginTop:"30px"}}>
                                    <div className="subhead bold" style={{margin:0}}>File Status</div>
                                </Grid>
                                <hr className="hr" />
                                <div className="subhead bold" style={{margin: "0.5rem 0rem"}}>Errors: </div>
                                {
                                    isError ? (
                                        <Grid item md={12}>
                                            <div className="warningText">{validationError}</div>
                                        </Grid>
                                    ) : (
                                        <Grid item md={12} style={{paddingLeft:"20px"}}>
                                            <div className="subhead bold" style={{margin: "0.5rem 0rem"}}>No Errors or Exception found</div>
                                        </Grid>
                                        )
                                }
                                {
                                    showErrorGrid ? (
                                        <Grid item md={12} style={{paddingLeft:"20px"}}>
                                            {showErrorGrid ? _.map(FileErrors, (error, key) => error.IsError == 1 && (
                                                <Fragment key={key}>
                                                    <div className="subhead">{error.Description.replace("$PromotionId$", this.returnPromotionIds(error.Data))}</div>
                                                    {error.isLoading ? (
                                                        <Box display="flex"><CircularProgress color="primary" /></Box>
                                                    ) : (
                                                            <div>
                                                                {error.ShowGrid == "1" && <Table>
                                                                    <StyledTableHead>
                                                                        <TableRow>
                                                                            {_.map(error.Data[0], (value, key) => <StyledTableCell>{key}</StyledTableCell>)}
                                                                        </TableRow>
                                                                    </StyledTableHead>
                                                                    <TableBody>
                                                                        {/* {error.Data.map((item, index) => (
                                                                            <Fragment key={index}>
                                                                                <StyledTableRow>
                                                                                    {_.map(item, (value, key) => <StyledTableCell>{value}</StyledTableCell>)}
                                                                                </StyledTableRow>
                                                                            </Fragment>
                                                                        ))} */}
                                                                        {this.paginateErrors(error)}
                                                                    </TableBody>
                                                                    <StyledTableFooter>
                                                                        <TableRow>
                                                                            <TablePagination
                                                                                rowsPerPageOptions={[10, 25, 50, 100]}
                                                                                colSpan={8}
                                                                                count={error.Data.length || 0}
                                                                                rowsPerPage={error.RowsPerPage || 10}
                                                                                page={error.PageNumber || 0}
                                                                                SelectProps={{
                                                                                    inputProps: { 'aria-label': 'rows per page' },
                                                                                    native: true,
                                                                                }}
                                                                                onChangePage={(e, pageNumber) => this.handlePageChange(e, pageNumber, error)}
                                                                                onChangeRowsPerPage={(e) => this.handleRowsPerPageChange(e, error)}
                                                                            />
                                                                        </TableRow>
                                                                    </StyledTableFooter>
                                                                </Table>}
                                                            </div>
                                                        )}
                                                </Fragment>
                                            )) : null
                                            }
                                        </Grid>
                                    ) : <div className="subhead">{errorStatus}</div>
                                }

                                {
                                    showErrorGrid ? (
                                        <Grid item md={12} style={{paddingLeft:"20px"}}>
                                            {showErrorGrid ? _.map(FileErrors, (error, key) => error.IsError == 0 && (
                                                <Fragment key={key}>
                                                    <div className="subhead bold" style={{margin: "0.5rem 0rem"}}>Warnings: </div>
                                                    <div className="subhead">{error.Description.replace("$PromotionId$", this.returnPromotionIds(error.Data))}</div>
                                                    {error.isLoading ? (
                                                        <Box display="flex"><CircularProgress color="primary" /></Box>
                                                    ) : (
                                                            <div>
                                                                {error.ShowGrid == "1" && <Table>
                                                                    <StyledTableHead>
                                                                        <TableRow>
                                                                            {_.map(error.Data[0], (value, key) => <StyledTableCell>{key}</StyledTableCell>)}
                                                                        </TableRow>
                                                                    </StyledTableHead>
                                                                    <TableBody>
                                                                        {/* {error.Data.map((item, index) => (
                                                                            <Fragment key={index}>
                                                                                <StyledTableRow>
                                                                                    {_.map(item, (value, key) => <StyledTableCell>{value}</StyledTableCell>)}
                                                                                </StyledTableRow>
                                                                            </Fragment>
                                                                        ))} */}
                                                                        {this.paginateErrors(error)}
                                                                    </TableBody>
                                                                    <StyledTableFooter>
                                                                        <TableRow>
                                                                            <TablePagination
                                                                                rowsPerPageOptions={[10, 25, 50, 100]}
                                                                                colSpan={8}
                                                                                count={error.Data.length || 0}
                                                                                rowsPerPage={error.RowsPerPage || 10}
                                                                                page={error.PageNumber || 0}
                                                                                SelectProps={{
                                                                                    inputProps: { 'aria-label': 'rows per page' },
                                                                                    native: true,
                                                                                }}
                                                                                onChangePage={(e, pageNumber) => this.handlePageChange(e, pageNumber, error)}
                                                                                onChangeRowsPerPage={(e) => this.handleRowsPerPageChange(e, error)}
                                                                            />
                                                                        </TableRow>
                                                                    </StyledTableFooter>
                                                                </Table>}
                                                            </div>
                                                        )}
                                                </Fragment>
                                            )) : null
                                            }
                                        </Grid>
                                    ) : null
                                }
                                
                                <hr className="hr" />
                                <Grid container item md={12} xs={12}>
                                    {processingApproveReject ? (
                                        <Box display="flex" justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
                                    ) : (
                                            (isError) ? (
                                                <Button className="approveBtn" xs={6} onClick={() => this.processApproveReject('Resubmit')}>Resubmit File</Button>
                                            ) : (
                                                    <Fragment>
                                                        <Button className="rejectBtn" xs={6} onClick={() => this.handleApproveRejectFile('Reject')}>Reject</Button>
                                                        <Button className="approveBtn" xs={6} onClick={() => this.handleApproveRejectFile('Approve')}>Approve</Button>
                                                    </Fragment>
                                                )
                                        )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Container>
                {error && this.renderSnackbar(error)}
                {showApproveConfirm && this.renderApproveDialog('', 'Do you want to continue to Approve the file?')}
                {showRejectConfirm && this.renderRejectDialog('', 'The fulfillment file will be Rejected and needs to be re uploaded. Do you want to Reject this file?')}
            </div>
        )
    }
    renderApproveDialog = (title, message) => {
        return <ConfirmDialog title={title} message={message} onCancel={this.onCancelApprove} onConfirm={this.onConfirmApprove} />
    }
    renderRejectDialog = (title, message) => {
        return <ConfirmDialog title={title} message={message} onCancel={this.onCancelReject} onConfirm={this.onConfirmReject} />
    }
    renderSnackbar = message => {
        return <Notification variant="error" message={message} />
    }
}

export default connect(state => (
    { ...state.user, ...state.promotion }
))(RewardsUploadStatus)