import Cookies from 'universal-cookie'
import axios from 'axios'
import config from '~/config';
import { getClientInfoFromConfig } from '~/config/clients';
import { getAccessToken } from '~/redux/helpers/user'

const cookies = new Cookies(window.document.cookie)

export const userInfo = () => async (dispatch) => {
	try {
		const accessToken = await getAccessToken()
		if (accessToken) {
			const response = await axios({
				url: `${config.base_url}/api/OAuth/oauth/self`,
				//url: `https://azhcutalsc.execute-api.us-east-2.amazonaws.com/dev/oauth/self`,
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `${accessToken}`,
				}
			})
			const responseBody = await response.data
			if (!responseBody.error) {
				console.log("self api response success");
				dispatch({
					type: 'LOGIN_SUCCESS',
					payload: {
						...responseBody.user
					}
				})
				return true
			}
		}
		return logout()
	} catch (error) {
		cookies.remove('mrefreshToken')
		cookies.remove('maccessToken')
		cookies.remove('mclient')
		cookies.remove('mclientId')
		dispatch({
			type: 'LOGIN_FAILED',
			payload: error.message || "An error has occured."
		})
		return false
	}
}

export const getUserDetails = (Id) => async (dispatch) => {
	try {
		const accessToken = await getAccessToken()
		if (accessToken) {
			const response = await axios({
				url: `${config.base_url}/api/GetUserDetails`,
				//url: `https://azhcutalsc.execute-api.us-east-2.amazonaws.com/dev/GetUserDetails`,
				method: 'POST',
				data: JSON.stringify({
					Id: Id
				}),
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `${accessToken}`,
				}
			})
			const responseBody = await response.data
			if (!responseBody.error) {
				console.log("self api response success");
				dispatch({
					type: 'LOGIN_SUCCESS',
					payload: {
						...responseBody.user
					}
				})
				return true
			}
		}
		return logout()
	} catch (error) {
		cookies.remove('mrefreshToken')
		cookies.remove('maccessToken')
		cookies.remove('mclient')
		cookies.remove('mclientId')
		dispatch({
			type: 'LOGIN_FAILED',
			payload: error.message || "An error has occured."
		})
		return false
	}
}


export const login = (credentials) => async (dispatch) => {
	try {
		let cookies = new Cookies(window.document.cookie)
		const response = await axios({
			url: `${config.base_url}/api/OAuth/oauth/login`,
			//url: `https://flfqpgrv3e.execute-api.us-east-2.amazonaws.com/dev/oauth/login`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			data: JSON.stringify(credentials)
		})
		const responseBody = await response.data
		if (!responseBody.error) {
			const { accessToken, refreshToken, user } = responseBody
			cookies.set('maccessToken', accessToken, { path: '/' + credentials.client })
			cookies.set('mrefreshToken', refreshToken, { path: '/' + credentials.client })
			cookies.set('mclient', credentials.client, { path: '/' + credentials.client })
			cookies.set('mclientId', (user && user.ClientID || ""), { path: '/' + credentials.client })
			dispatch({
				type: 'LOGIN_SUCCESS',
				payload: {
					...responseBody.user
				}
			});
			return true;
		} else {
            cookies.remove('mrefreshToken');
            cookies.remove('maccessToken');
            cookies.remove('mclient');
            cookies.remove('mclientId');
			dispatch({
				type: 'LOGIN_FAILED',
				payload: {
					error: responseBody.error || 'Oops! Something went wrong.'
				}
			});
			return false;
		}
	} catch (error) {
		let errMsg = (error.response && error.response.data.error);
		console.log("Error response", error.response);
		console.log("Error response data", error.response.data);
		console.log("Error response data error ", error.response.data);
		if (error.response.status == 500) {
			errMsg = (error.response.data.error && error.response.data.error.error.error)
		}
        cookies.remove('mrefreshToken');
        cookies.remove('maccessToken');
        cookies.remove('mclient');
        cookies.remove('mclientId');
		dispatch({
			type: 'LOGIN_FAILED',
			payload: {
				error: (errMsg) || "An error has occured."
			}
		})
		return false
	}
}

export const setNewPassword = (credentials) => async (dispatch) => {
	try {
		const accessToken = await getAccessToken();

		const response = await axios({
			url: `${config.base_url}/api/OAuth/oauth/updatePassword`,
			//url: 'https://flfqpgrv3e.execute-api.us-east-2.amazonaws.com/dev/oauth/updatePassword',
			//url: `https://azhcutalsc.execute-api.us-east-2.amazonaws.com/dev/oauth/updatePassword`,
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `${accessToken}`,
			},
			data: JSON.stringify(credentials)
		})
		const responseBody = await response.data;
		//const error = responseBody["data"];
		
		if (!responseBody.error) {
           //const { accessToken, refreshToken, user } = responseBody
			dispatch({
				type: 'UPDATE_PASSWORD_SUCCESS',
				payload: {
					PasswordChangedDate: new Date()
				}
			})
		} else {
			dispatch({
				type: 'UPDATE_PASSWORD_FAILED',
				payload: responseBody.error || 'Oops! Something went wrong.'
			})
		}
	} catch (error) {
		dispatch({
			type: 'UPDATE_PASSWORD_FAILED',
			payload: (error.response && error.response.data && error.response.data.error) || "An error has occured."
		})
	}
}

export const logout = () =>{

    try {
        let cookies = new Cookies(window.document.cookie)
        cookies.remove('maccessToken');
        cookies.remove('mrefreshToken');
        cookies.remove('mclient');
        cookies.remove('mclientId');

        return {
            type: 'LOGOUT_SUCCESS',
            payload: {}
        }
    } catch (error) {
        console.log("logout failed", error)
    }
}

export const fetchClientConfigSettings = () => async (dispatch) => {
	try {
		const accessToken = await getAccessToken()
		if (accessToken) {
			const response = await axios({
				url: `${config.base_url}/api/getCodeWiseClientConfig`,
				//url: `https://wj0yq02ibj.execute-api.us-east-2.amazonaws.com/dev/getCodeWiseClientConfig`,
				method: 'POST',
				data: JSON.stringify({
					Code: "C00001"
				}),
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `${accessToken}`,
				},

			})
			const responseBody = await response.data
            console.log("responseBody", responseBody);
			if (!responseBody.error) {
				console.log("CONFIG_THEME_FETCH_SUCCESS");
				dispatch({
					type: 'CONFIG_THEME_FETCH_SUCCESS',
					payload: responseBody.ClientConfigs[0]
				})
				return true
			} else {
				console.log("CONFIG_THEME_FETCH_FAILED");
				dispatch({
					type: 'CONFIG_THEME_FETCH_FAILED',
					payload: responseBody.error || 'Oops! Something went wrong.'
				})
				return false;
			}
		}
	} catch (error) {
		console.log("CONFIG_THEME_FETCH_FAILED")
		dispatch({
			type: 'CONFIG_THEME_FETCH_FAILED',
			payload: error.message || "An error has occured."
		})
		return false
	}
}

//Multi tantent clients
export const getClientInfo = (clientKey) => async (dispatch) => {
	try {
		const clientInfo = await getClientInfoFromConfig(clientKey);

		const client = cookies.get('mclient');

		if (clientInfo && (!client || (client == clientKey))) {
			dispatch({
				type: 'CLIENT_METADATA_SUCCESS',
				payload: {
					clientKey: clientKey || "",
					metadata: clientInfo || null
				}
			})
			return true;
		}
		return false;

	} catch (error) {
		dispatch({
			type: 'CLIENT_METADATA_FAILED',
			payload: { error: error.message || "An error has occured." }
		})
		return false
	}
}

export const fetchClientConfig = () => async (dispatch) => {
	try {
		const accessToken = await getAccessToken()
		if (accessToken) {
			const response = await axios({
				url: `${config.base_url}/api/getAllClientConfigs`,
				//url: `https://wj0yq02ibj.execute-api.us-east-2.amazonaws.com/dev/getAllClientConfigs`,
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': `${accessToken}`,
				},

			})
			const responseBody = await response.data
            console.log("responseBody", responseBody);
			if (!responseBody.error) {
				dispatch({
					type: 'CONFIG_FETCH_SUCCESS',
					payload: responseBody.ClientConfigs
				})
				return true
			}
            dispatch({
                type: 'CONFIG_FETCH_FAILED',
                payload: responseBody.error || 'Oops! Something went wrong.'
            })
            return false;
		}
	} catch (error) {
		dispatch({
			type: 'CONFIG_FETCH_FAILED',
			payload: error.message || "An error has occured."
		})
		return false
	}
}