import React, { Component } from 'react' 
import { Grid, Container, Box, Paper, TextField, Button, withStyles, Dialog } from '@material-ui/core'; 
import {Link} from 'react-router-dom' 
import {AlertDialog}  from '~/components/Dialogs' 
 
 
const styles = theme => ({ 
    backBtn: { 
        color: "#173b85",
        padding: "10px 40px", 
        fontSize: "14px", 
        fontWeight: "500",
        border: '1px solid #173b85;',
      },
    categoryLink:{ 
        background:'#fcfefe', color:'#8f8f90', padding:'1rem', marginBottom:'5px', border:'1px solid #dfe2e4', 
        '&:last-child':{ 
            marginBottom:'0' 
        }, 
        '&:hover':{ 
            border:'2px solid #93b2c2', 
            boxSizing:'content-box' 
        } 
    }  
}) 
 
 
class SupportTicket extends Component{ 
 
    state = { 
        open: false, 
    } 
 
    handleDialog() { 
        this.setState({open: false }); 
    } 
 
 
    render() { 
        const {classes, clientKey} = this.props; 
        const {open} = this.state; 
        return ( 
            <div id="supportTicket"> 
                <Container>  
                <Box display="flex" my={2} alignItems="flex-end">  
                    <Box flexGrow={1} fontWeight={500} fontSize="25px"> Create a Support Ticket  </Box> 
                    <Box>  <Link to={`/${clientKey}/support`} className={classes.backBtn}> Back</Link> </Box> 
                </Box> 
                <Grid container spacing={1} > 
                    <Grid item xs={12} md={4}> 
                        <Paper> <Box px={2} pt={2} px={3} fontSize={16}> Choose a category</Box> 
                         <Box display="flex" flexDirection="column" p={1}>  
                            <Link to="#" className={classes.categoryLink}> Payments Issue</Link> 
                            <Link to="#" className={classes.categoryLink}> Rewards Issue</Link> 
                            <Link to="#" className={classes.categoryLink}> Other</Link> 
                         </Box> 
                        </Paper> 
                    </Grid> 
                    <Grid item xs={12} md={8}>  
                        <Paper style={{ padding:'0.5rem'}}>  
                            <Box border={2} borderColor="#93b2c2" bgcolor="#fafafa" p={{xs:'1rem', sm:'1.5rem', md:'2rem'}}>  
                            <Box fontSize={14} fontWeight={500}> Ticket No. 001 </Box> 
                            <Box fontSize={14} fontWeight={500} pt={{md:1, xs:1.5, sm:1}} pb={{md:3, xs:1, sm:2}}> Reward ID: R000123 </Box> 
                            <TextField label="Sub" variant="outlined" margin="normal" style={{backgroundColor:'#fff'}} fullWidth={true} />  
                            <TextField label="Body" variant="outlined"  margin="normal" multiline rows="6" style={{backgroundColor:'#fff'}} fullWidth={true} />  
                            <Link to="#" > <Button variant="contained" color="primary" style={{margin:'1.5rem 0'}} onClick={()=> this.setState({open: true})}>  Submit Ticket</Button> </Link> 
                            </Box> 
                         </Paper> 
                    </Grid> 
                </Grid> 
                { open && ( 
                <AlertDialog clickOutsideToClose={false} open={this.state.open} color="#1c4b6b" title={"Your ticket number 001 has been successfully submitted!"} onConfirm={() => this.handleDialog()}/> 
                )} 
                </Container> 
                
            </div> 
        ) 
    } 
 
  
} 
 
export default withStyles(styles)(SupportTicket) ;