import React, {Fragment} from 'react';
import {
  Modal,
  Button,
  Grid,
  Paper,
  CircularProgress,
  Table,
  TableRow,
  TableBody,
  TablePagination,
  TableCell,
  TableSortLabel,
  Box,
  withStyles,
  makeStyles,
  DialogTitle, Dialog, DialogActions, DialogContent, IconButton 
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

import {
  StyledTableHead,
  StyledTableRow,
  StyledTableCell,
  StyledTableFooter,
  StyledTableColRow,
  StyledTableColCell,
  StyledTableFooterReport
} from "~/components/StyledTable";
import moment from 'moment';
import './styles.scss';
import Cookies from "universal-cookie";
const cookies = new Cookies(window.document.cookie);

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

export function ReportViewer(props) {
    const {title, px, py, onConfirm, clickOutsideToClose, onClose, previewProgress, downloadProgress, items, page, rowsPerPage, handleDownload, handlePageChange, handleRowsPerPageChange,
      order, orderBy, onRequestSort, canDownload, clientConfig} = props;
      console.log('deepakitems', props.items);
    return (
        // <Dialog
        //     fullScreen={true}
        //     fullWidth={true}
        //     open={true}
        //     onClose={() => (clickOutsideToClose || clickOutsideToClose === undefined) ? onConfirm() : null}
        //     aria-labelledby="alert-dialog-title"
        //     aria-describedby="alert-dialog-description"
        // >
        //     <Box py={py || 6} px={px || 6}>
        //         {onClose ? (
        //             <IconButton aria-label="close" className="closeButton" onClick={() => onClose()}>
        //                 <CloseIcon />
        //             </IconButton>
        //         ) : null}       
        //         <DialogContent className="alert-dialog-message">
        //             {previewProgress ? (
        //             <Box display="flex" p={5} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
        //             ):(
        //             <Table id="report-list">
        //                 <StyledTableHead style={{fontWeight:'bold'}}>
        //                     <TableRow>
        //                         <StyledTableColCell style={{textAlign:"center", fontWeight:'bold', fontSize:18}} colSpan={14}>Promotion Summary Report</StyledTableColCell>
        //                     </TableRow>
        //                     <StyledTableRow>
        //                         <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "PromoId" ? order : false}>
        //                             <TableSortLabel
        //                               active={orderBy === "PromoId"}
        //                               direction={orderBy === "PromoId" ? order : 'asc'}
        //                               onClick={(event) => onRequestSort(event, "PromoId")}
        //                             >
        //                               Promotion ID
        //                               {orderBy === "PromoId" ? (
        //                                 <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
        //                                   {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
        //                                 </span>
        //                               ) : null}
        //                             </TableSortLabel>
        //                         </StyledTableColCell>
        //                         <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "PromoName" ? order : false}>
        //                             <TableSortLabel
        //                               active={orderBy === "PromoName"}
        //                               direction={orderBy === "PromoName" ? order : 'asc'}
        //                               onClick={(event) => onRequestSort(event, "PromoName")}
        //                             >
        //                               Promotion Name
        //                               {orderBy === "PromoName" ? (
        //                                 <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
        //                                   {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
        //                                 </span>
        //                               ) : null}
        //                             </TableSortLabel>
        //                         </StyledTableColCell>
        //                         <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "EmpEarnedIncentive" ? order : false}>
        //                             <TableSortLabel
        //                               active={orderBy === "EmpEarnedIncentive"}
        //                               direction={orderBy === "EmpEarnedIncentive" ? order : 'asc'}
        //                               onClick={(event) => onRequestSort(event, "EmpEarnedIncentive")}
        //                             >
        //                               Number of Employees Earned Incentives
        //                               {orderBy === "EmpEarnedIncentive" ? (
        //                                 <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
        //                                   {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
        //                                 </span>
        //                               ) : null}
        //                             </TableSortLabel>
        //                         </StyledTableColCell>
        //                         <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "EmpRedeemedIncentive" ? order : false}>
        //                             <TableSortLabel
        //                               active={orderBy === "EmpRedeemedIncentive"}
        //                               direction={orderBy === "EmpRedeemedIncentive" ? order : 'asc'}
        //                               onClick={(event) => onRequestSort(event, "EmpRedeemedIncentive")}
        //                             >
        //                               Number of Employees Redeemed Incentive
        //                               {orderBy === "EmpRedeemedIncentive" ? (
        //                                 <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
        //                                   {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
        //                                 </span>
        //                               ) : null}
        //                             </TableSortLabel>
        //                         </StyledTableColCell>
        //                         <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "PerEmpRedeemedIncentive" ? order : false}>
        //                             <TableSortLabel
        //                               active={orderBy === "PerEmpRedeemedIncentive"}
        //                               direction={orderBy === "PerEmpRedeemedIncentive" ? order : 'asc'}
        //                               onClick={(event) => onRequestSort(event, "PerEmpRedeemedIncentive")}
        //                             >
        //                               Percent Employees Redeemed Incentive
        //                               {orderBy === "PerEmpRedeemedIncentive" ? (
        //                                 <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
        //                                   {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
        //                                 </span>
        //                               ) : null}
        //                             </TableSortLabel>
        //                         </StyledTableColCell>
        //                         <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "TotalRedeemedIncentive" ? order : false}>
        //                             <TableSortLabel
        //                               active={orderBy === "TotalRedeemedIncentive"}
        //                               direction={orderBy === "TotalRedeemedIncentive" ? order : 'asc'}
        //                               onClick={(event) => onRequestSort(event, "TotalRedeemedIncentive")}
        //                             >
        //                               Total Incentives Redeemed
        //                               {orderBy === "TotalRedeemedIncentive" ? (
        //                                 <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
        //                                   {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
        //                                 </span>
        //                               ) : null}
        //                             </TableSortLabel>
        //                         </StyledTableColCell>
        //                         <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "PerRedeemedIncentive" ? order : false}>
        //                             <TableSortLabel
        //                               active={orderBy === "PerRedeemedIncentive"}
        //                               direction={orderBy === "PerRedeemedIncentive" ? order : 'asc'}
        //                               onClick={(event) => onRequestSort(event, "PerRedeemedIncentive")}
        //                             >
        //                               Percent Incentives Redeemed
        //                               {orderBy === "PerRedeemedIncentive" ? (
        //                                 <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
        //                                   {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
        //                                 </span>
        //                               ) : null}
        //                             </TableSortLabel>
        //                         </StyledTableColCell>
        //                         <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "DepositIncentive" ? order : false}>
        //                             <TableSortLabel
        //                               active={orderBy === "DepositIncentive"}
        //                               direction={orderBy === "DepositIncentive" ? order : 'asc'}
        //                               onClick={(event) => onRequestSort(event, "DepositIncentive")}
        //                             >
        //                               Total Incentive Deposit
        //                               {orderBy === "DepositIncentive" ? (
        //                                 <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
        //                                   {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
        //                                 </span>
        //                               ) : null}
        //                             </TableSortLabel>
        //                         </StyledTableColCell>
        //                         <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "Incentive_Exp_Date" ? order : false}>
        //                             <TableSortLabel
        //                               active={orderBy === "Incentive_Exp_Date"}
        //                               direction={orderBy === "Incentive_Exp_Date" ? order : 'asc'}
        //                               onClick={(event) => onRequestSort(event, "Incentive_Exp_Date")}
        //                             >
        //                               {clientConfig && clientConfig.Value.toLowerCase() == "y" ? "Incentive Expiration Date" : "Promotion Closed Date"}
        //                               {orderBy === "Incentive_Exp_Date" ? (
        //                                 <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
        //                                   {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
        //                                 </span>
        //                               ) : null}
        //                             </TableSortLabel>
        //                         </StyledTableColCell>
        //                         <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "PromoStatus" ? order : false}>
        //                             <TableSortLabel
        //                               active={orderBy === "PromoStatus"}
        //                               direction={orderBy === "PromoStatus" ? order : 'asc'}
        //                               onClick={(event) => onRequestSort(event, "PromoStatus")}
        //                             >
        //                               Promotion Status
        //                               {orderBy === "PromoStatus" ? (
        //                                 <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
        //                                   {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
        //                                 </span>
        //                               ) : null}
        //                             </TableSortLabel>
        //                         </StyledTableColCell>
        //                         <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "ExpiredFund" ? order : false}>
        //                             <TableSortLabel
        //                               active={orderBy === "ExpiredFund"}
        //                               direction={orderBy === "ExpiredFund" ? order : 'asc'}
        //                               onClick={(event) => onRequestSort(event, "ExpiredFund")}
        //                             >
        //                               {clientConfig && clientConfig.Value.toLowerCase() == "y" ? "Expired Funds" : "Unclaimed Fund"}
        //                               {orderBy === "ExpiredFund" ? (
        //                                 <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
        //                                   {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
        //                                 </span>
        //                               ) : null}
        //                             </TableSortLabel>
        //                         </StyledTableColCell>
        //                     </StyledTableRow>
        //                 </StyledTableHead>
        //                 <TableBody>
        //                     {
        //                     (items || rowsPerPage > 0
        //                         ? stableSort(items, getSorting(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        //                         : items)
        //                     .map((item, index) => (
        //                         <Fragment key={index}>
        //                             <StyledTableRow>
        //                                 <StyledTableColCell style={{textAlign:"center"}}>{item.PromoId}</StyledTableColCell>
        //                                 <StyledTableColCell style={{textAlign:"center"}}>{item.PromoName}</StyledTableColCell>
        //                                 <StyledTableColCell style={{textAlign:"center"}}>{item.EmpEarnedIncentive}</StyledTableColCell>
        //                                 <StyledTableColCell style={{textAlign:"center"}}>{item.EmpRedeemedIncentive}</StyledTableColCell>
        //                                 <StyledTableColCell style={{textAlign:"center"}}>{item.PerEmpRedeemedIncentive}</StyledTableColCell>
        //                                 <StyledTableColCell style={{textAlign:"center"}}>{item.TotalRedeemedIncentive}</StyledTableColCell>
        //                                 <StyledTableColCell style={{textAlign:"center"}}>{item.PerRedeemedIncentive}</StyledTableColCell>
        //                                 <StyledTableColCell style={{textAlign:"center"}}>{item.DepositIncentive}</StyledTableColCell>
        //                                 <StyledTableColCell style={{textAlign:"center"}}>{item.Incentive_Exp_Date ? moment(item.Incentive_Exp_Date).format("MM/DD/YYYY") : ""}</StyledTableColCell>
        //                                 <StyledTableColCell style={{textAlign:"center"}}>{item.PromoStatus}</StyledTableColCell>
        //                                 <StyledTableColCell style={{textAlign:"center"}}>{item.ExpiredFund}</StyledTableColCell>
        //                             </StyledTableRow>
        //                         </Fragment>
        //                     ))
        //                     }
        //                 </TableBody>
        //                 <StyledTableFooterReport>
        //                     <TableRow>
        //                         <TablePagination
        //                             rowsPerPageOptions={[10, 25, 50, { label: 'All', value: -1 }]}
        //                             colSpan={11}
        //                             count={items.length || 0}
        //                             rowsPerPage={rowsPerPage}
        //                             page={page}
        //                             SelectProps={{
        //                                 inputProps: { 'aria-label': 'rows per page' },
        //                                 native: true,
        //                             }}
        //                             onChangePage={(event, page)=>handlePageChange(event, page)}
        //                             onChangeRowsPerPage={(event) => handleRowsPerPageChange(event)}
        //                         />
        //                     </TableRow>
        //                     <TableRow>
        //                         <StyledTableCell colSpan={11} style={{justifyContent:"flex-end"}}>
        //                         {downloadProgress ? (
        //                             <Box width="100px" display="flex" mt={1.875} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
        //                             ) : (
        //                             <div>{canDownload && <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
        //                                     <Button variant="contained" color="primary" className="btns save" onClick={(event)=>handleDownload(event)} >Download</Button>
        //                                 </Box>}
        //                             </div>
        //                             )}
        //                         </StyledTableCell>
        //                     </TableRow>   
        //                 </StyledTableFooterReport>
        //             </Table>
        //         )}
        //         </DialogContent>
        //     </Box>
        // </Dialog>
        <>
        {cookies.get("mclient") === "bmw" && 
        <Dialog
            fullScreen={true}
            fullWidth={true}
            open={true}
            onClose={() => (clickOutsideToClose || clickOutsideToClose === undefined) ? onConfirm() : null}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Box py={py || 6} px={px || 6}>
                {onClose ? (
                    <IconButton aria-label="close" className="closeButton" onClick={() => onClose()}>
                        <CloseIcon />
                    </IconButton>
                ) : null}       
                <DialogContent className="alert-dialog-message">
                    {previewProgress ? (
                    <Box display="flex" p={5} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
                    ):(
                    <Table id="report-list">
                        <StyledTableHead style={{fontWeight:'bold'}}>
                            <TableRow>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:'bold', fontSize:18}} colSpan={17}>Promotion Summary Report</StyledTableColCell>
                            </TableRow>
                            <StyledTableRow>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "PromotionId" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "PromotionId"}
                                      direction={orderBy === "PromotionId" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "PromotionId")}
                                    >
                                      Promotion ID
                                      {orderBy === "PromotionId" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "PromotionName" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "PromotionName"}
                                      direction={orderBy === "PromotionName" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "PromotionName")}
                                    >
                                      Promotion Name
                                      {orderBy === "PromotionName" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "ExpiredFundsAdded" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "ExpiredFundsAdded"}
                                      direction={orderBy === "ExpiredFundsAdded" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "ExpiredFundsAdded")}
                                    >
                                      Expired Funds Added
                                      {orderBy === "ExpiredFundsAdded" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "NewFundsAdded" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "NewFundsAdded"}
                                      direction={orderBy === "NewFundsAdded" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "NewFundsAdded")}
                                    >
                                      New Funds Added
                                      {orderBy === "NewFundsAdded" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "AssignedFunds" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "AssignedFunds"}
                                      direction={orderBy === "AssignedFunds" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "AssignedFunds")}
                                    >
                                      Assigned Funds
                                      {orderBy === "AssignedFunds" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "AllocatedFund" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "AllocatedFund"}
                                      direction={orderBy === "AllocatedFund" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "AllocatedFund")}
                                    >
                                      Allocated Fund
                                      {orderBy === "AllocatedFund" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "RewardAvailable" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "RewardAvailable"}
                                      direction={orderBy === "RewardAvailable" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "RewardAvailable")}
                                    >
                                      Reward Available
                                      {orderBy === "RewardAvailable" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "Initiated" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "Initiated"}
                                      direction={orderBy === "Initiated" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "Initiated")}
                                    >
                                      Initiated
                                      {orderBy === "Initiated" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "PaymentInProgress" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "PaymentInProgress"}
                                      direction={orderBy === "PaymentInProgress" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "PaymentInProgress")}
                                    >
                                      Payment In Progress
                                      {orderBy === "PaymentInProgress" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "PaymentFailed" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "PaymentFailed"}
                                      direction={orderBy === "PaymentFailed" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "PaymentFailed")}
                                    >
                                      Payment Failed
                                      {orderBy === "PaymentFailed" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "PaymentSettled" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "PaymentSettled"}
                                      direction={orderBy === "PaymentSettled" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "ExpiredFund")}
                                    >
                                      Payment Settled
                                      {orderBy === "PaymentSettled" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                {props.items[0].CanExpire === "N" &&
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "Unclaimed" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "Unclaimed"}
                                      direction={orderBy === "Unclaimed" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "Unclaimed")}
                                    >
                                      Unclaimed Fund
                                      {orderBy === "Unclaimed" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                }
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "FulfillmentDate" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "FulfillmentDate"}
                                      direction={orderBy === "FulfillmentDate" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "FulfillmentDate")}
                                    >
                                      Fulfillment Date
                                      {orderBy === "FulfillmentDate" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                {props.items[0].CanExpire == "N" && 
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "CloserDate" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "CloserDate"}
                                      direction={orderBy === "CloserDate" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "CloserDate")}
                                    >
                                      Promotion Closed Date
                                      {orderBy === "CloserDate" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                }
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "PortalStatusName" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "PortalStatusName"}
                                      direction={orderBy === "PortalStatusName" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "PortalStatusName")}
                                    >
                                      Portal Status Name
                                      {orderBy === "PortalStatusName" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "CreatedDate" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "CreatedDate"}
                                      direction={orderBy === "CreatedDate" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "CreatedDate")}
                                    >
                                      Created Date
                                      {orderBy === "CreatedDate" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "UpdatedDate" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "UpdatedDate"}
                                      direction={orderBy === "UpdatedDate" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "UpdatedDate")}
                                    >
                                      Updated Date
                                      {orderBy === "UpdatedDate" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                            </StyledTableRow>
                        </StyledTableHead>
                        <TableBody>
                            {
                            (items || rowsPerPage > 0
                                ? stableSort(items, getSorting(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : items)
                            .map((item, index) => (
                                <Fragment key={index}>
                                    <StyledTableRow>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.PromotionId}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.PromotionName}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.ExpiredFundsAdded}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.NewFundsAdded}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.AssignedFunds}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.AllocatedFund}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.RewardAvailable}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.Initiated}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.PaymentInProgress}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.PaymentFailed}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.PaymentSettled}</StyledTableColCell>
                                        {item.CanExpire == "N" && <StyledTableColCell style={{textAlign:"center"}}>{item.Unclaimed}</StyledTableColCell> }
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.FulfillmentDate ? moment(item.Incentive_Exp_Date).format("MM/DD/YYYY") : ""}</StyledTableColCell>
                                        {item.CanExpire == "N" && <StyledTableColCell style={{textAlign:"center"}}>{item.CloserDate ? moment(item.Incentive_Exp_Date).format("MM/DD/YYYY") : ""}</StyledTableColCell>}
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.PortalStatusName}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.CreatedDate ? moment(item.Incentive_Exp_Date).format("MM/DD/YYYY") : ""}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.UpdatedDate ? moment(item.Incentive_Exp_Date).format("MM/DD/YYYY") : ""}</StyledTableColCell>
                                    </StyledTableRow>
                                </Fragment>
                            ))
                            }
                        </TableBody>
                        <StyledTableFooterReport>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 50, { label: 'All', value: -1 }]}
                                    colSpan={17}
                                    count={items.length || 0}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onChangePage={(event, page)=>handlePageChange(event, page)}
                                    onChangeRowsPerPage={(event) => handleRowsPerPageChange(event)}
                                />
                            </TableRow>
                            <TableRow>
                                <StyledTableCell colSpan={17} style={{justifyContent:"flex-end"}}>
                                {downloadProgress ? (
                                    <Box width="100px" display="flex" mt={1.875} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
                                    ) : (
                                    <div>{canDownload && <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
                                            <Button variant="contained" color="primary" className="btns save" onClick={(event)=>handleDownload(event)} >Download</Button>
                                        </Box>}
                                    </div>
                                    )}
                                </StyledTableCell>
                            </TableRow>   
                        </StyledTableFooterReport>
                    </Table>
                )}
                </DialogContent>
            </Box>
        </Dialog> }
        {cookies.get("mclient") === "acg" && 
        <Dialog
            fullScreen={true}
            fullWidth={true}
            open={true}
            onClose={() => (clickOutsideToClose || clickOutsideToClose === undefined) ? onConfirm() : null}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Box py={py || 6} px={px || 6}>
                {onClose ? (
                    <IconButton aria-label="close" className="closeButton" onClick={() => onClose()}>
                        <CloseIcon />
                    </IconButton>
                ) : null}       
                <DialogContent className="alert-dialog-message">
                    {previewProgress ? (
                    <Box display="flex" p={5} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
                    ):(
                    <Table id="report-list">
                        <StyledTableHead style={{fontWeight:'bold'}}>
                            <TableRow>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:'bold', fontSize:18}} colSpan={17}>Promotion Summary Report</StyledTableColCell>
                            </TableRow>
                            <StyledTableRow>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "PromotionId" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "PromotionId"}
                                      direction={orderBy === "PromotionId" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "PromotionId")}
                                    >
                                      Promotion ID
                                      {orderBy === "PromotionId" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "PromotionName" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "PromotionName"}
                                      direction={orderBy === "PromotionName" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "PromotionName")}
                                    >
                                      Promotion Name
                                      {orderBy === "PromotionName" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "ExpiredFundsAdded" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "ExpiredFundsAdded"}
                                      direction={orderBy === "ExpiredFundsAdded" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "ExpiredFundsAdded")}
                                    >
                                      Expired Funds Added
                                      {orderBy === "ExpiredFundsAdded" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "NewFundsAdded" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "NewFundsAdded"}
                                      direction={orderBy === "NewFundsAdded" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "NewFundsAdded")}
                                    >
                                      New Funds Added
                                      {orderBy === "NewFundsAdded" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "AssignedFunds" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "AssignedFunds"}
                                      direction={orderBy === "AssignedFunds" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "AssignedFunds")}
                                    >
                                      Assigned Funds
                                      {orderBy === "AssignedFunds" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "AllocatedFund" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "AllocatedFund"}
                                      direction={orderBy === "AllocatedFund" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "AllocatedFund")}
                                    >
                                      Allocated Fund
                                      {orderBy === "AllocatedFund" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "RewardAvailable" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "RewardAvailable"}
                                      direction={orderBy === "RewardAvailable" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "RewardAvailable")}
                                    >
                                      Reward Available
                                      {orderBy === "RewardAvailable" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "Initiated" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "Initiated"}
                                      direction={orderBy === "Initiated" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "Initiated")}
                                    >
                                      Initiated
                                      {orderBy === "Initiated" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "PaymentInProgress" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "PaymentInProgress"}
                                      direction={orderBy === "PaymentInProgress" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "PaymentInProgress")}
                                    >
                                      Payment In Progress
                                      {orderBy === "PaymentInProgress" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "PaymentFailed" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "PaymentFailed"}
                                      direction={orderBy === "PaymentFailed" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "PaymentFailed")}
                                    >
                                      Payment Failed
                                      {orderBy === "PaymentFailed" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "PaymentSettled" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "PaymentSettled"}
                                      direction={orderBy === "PaymentSettled" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "PaymentSettled")}
                                    >
                                      Payment Settled
                                      {orderBy === "PaymentSettled" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                {props.items[0].CanExpire === "Y" &&
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "Expired" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "Expired"}
                                      direction={orderBy === "Expired" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "Expired")}
                                    >
                                      Expired Funds
                                      {orderBy === "Expired" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                }
                                {props.items[0].CanExpire === "Y" &&
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "ExpirationDate" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "ExpirationDate"}
                                      direction={orderBy === "ExpirationDate" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "ExpirationDate")}
                                    >
                                      Expiration Date
                                      {orderBy === "ExpirationDate" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                }
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "FulfillmentDate" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "FulfillmentDate"}
                                      direction={orderBy === "FulfillmentDate" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "FulfillmentDate")}
                                    >
                                      Fulfillment Date
                                      {orderBy === "FulfillmentDate" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "PortalStatusName" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "PortalStatusName"}
                                      direction={orderBy === "PortalStatusName" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "PortalStatusName")}
                                    >
                                      Portal Status Name
                                      {orderBy === "PortalStatusName" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "CreatedDate" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "CreatedDate"}
                                      direction={orderBy === "CreatedDate" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "CreatedDate")}
                                    >
                                      Created Date
                                      {orderBy === "CreatedDate" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "UpdatedDate" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "UpdatedDate"}
                                      direction={orderBy === "UpdatedDate" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "UpdatedDate")}
                                    >
                                      Updated Date
                                      {orderBy === "UpdatedDate" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                            </StyledTableRow>
                        </StyledTableHead>
                        <TableBody>
                            {
                            (items || rowsPerPage > 0
                                ? stableSort(items, getSorting(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : items)
                            .map((item, index) => (
                                <Fragment key={index}>
                                    <StyledTableRow>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.PromotionId}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.PromotionName}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.ExpiredFundsAdded}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.NewFundsAdded}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.AssignedFunds}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.AllocatedFund}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.RewardAvailable}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.Initiated}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.PaymentInProgress}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.PaymentFailed}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.PaymentSettled}</StyledTableColCell>
                                        {item.CanExpire == "Y" && <StyledTableColCell style={{textAlign:"center"}}>{item.Expired}</StyledTableColCell>}
                                        {item.CanExpire == "Y" && <StyledTableColCell style={{textAlign:"center"}}>{item.ExpirationDate ? moment(item.Incentive_Exp_Date).format("MM/DD/YYYY") : ""}</StyledTableColCell>}
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.FulfillmentDate ? moment(item.Incentive_Exp_Date).format("MM/DD/YYYY") : ""}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.PortalStatusName}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.CreatedDate ? moment(item.Incentive_Exp_Date).format("MM/DD/YYYY") : ""}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.UpdatedDate ? moment(item.Incentive_Exp_Date).format("MM/DD/YYYY") : ""}</StyledTableColCell>
                                    </StyledTableRow>
                                </Fragment>
                            ))
                            }
                        </TableBody>
                        <StyledTableFooterReport>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 50, { label: 'All', value: -1 }]}
                                    colSpan={17}
                                    count={items.length || 0}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onChangePage={(event, page)=>handlePageChange(event, page)}
                                    onChangeRowsPerPage={(event) => handleRowsPerPageChange(event)}
                                />
                            </TableRow>
                            <TableRow>
                                <StyledTableCell colSpan={17} style={{justifyContent:"flex-end"}}>
                                {downloadProgress ? (
                                    <Box width="100px" display="flex" mt={1.875} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
                                    ) : (
                                    <div>{canDownload && <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
                                            <Button variant="contained" color="primary" className="btns save" onClick={(event)=>handleDownload(event)} >Download</Button>
                                        </Box>}
                                    </div>
                                    )}
                                </StyledTableCell>
                            </TableRow>   
                        </StyledTableFooterReport>
                    </Table>
                )}
                </DialogContent>
            </Box>
        </Dialog> }
        {cookies.get("mclient") === "hd" && 
        <Dialog
            fullScreen={true}
            fullWidth={true}
            open={true}
            onClose={() => (clickOutsideToClose || clickOutsideToClose === undefined) ? onConfirm() : null}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Box py={py || 6} px={px || 6}>
                {onClose ? (
                    <IconButton aria-label="close" className="closeButton" onClick={() => onClose()}>
                        <CloseIcon />
                    </IconButton>
                ) : null}       
                <DialogContent className="alert-dialog-message">
                    {previewProgress ? (
                    <Box display="flex" p={5} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
                    ):(
                    <Table id="report-list">
                        <StyledTableHead style={{fontWeight:'bold'}}>
                            <TableRow>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:'bold', fontSize:18}} colSpan={17}>Promotion Summary Report</StyledTableColCell>
                            </TableRow>
                            <StyledTableRow>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "PromotionId" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "PromotionId"}
                                      direction={orderBy === "PromotionId" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "PromotionId")}
                                    >
                                      Promotion ID
                                      {orderBy === "PromotionId" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "PromotionName" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "PromotionName"}
                                      direction={orderBy === "PromotionName" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "PromotionName")}
                                    >
                                      Promotion Name
                                      {orderBy === "PromotionName" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "ExpiredFundsAdded" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "ExpiredFundsAdded"}
                                      direction={orderBy === "ExpiredFundsAdded" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "ExpiredFundsAdded")}
                                    >
                                      Expired Funds Added
                                      {orderBy === "ExpiredFundsAdded" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "NewFundsAdded" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "NewFundsAdded"}
                                      direction={orderBy === "NewFundsAdded" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "NewFundsAdded")}
                                    >
                                      New Funds Added
                                      {orderBy === "NewFundsAdded" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "AssignedFunds" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "AssignedFunds"}
                                      direction={orderBy === "AssignedFunds" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "AssignedFunds")}
                                    >
                                      Assigned Funds
                                      {orderBy === "AssignedFunds" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "AllocatedFund" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "AllocatedFund"}
                                      direction={orderBy === "AllocatedFund" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "AllocatedFund")}
                                    >
                                      Allocated Fund
                                      {orderBy === "AllocatedFund" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "RewardAvailable" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "RewardAvailable"}
                                      direction={orderBy === "RewardAvailable" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "RewardAvailable")}
                                    >
                                      Reward Available
                                      {orderBy === "RewardAvailable" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "Initiated" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "Initiated"}
                                      direction={orderBy === "Initiated" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "Initiated")}
                                    >
                                      Initiated
                                      {orderBy === "Initiated" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "PaymentInProgress" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "PaymentInProgress"}
                                      direction={orderBy === "PaymentInProgress" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "PaymentInProgress")}
                                    >
                                      Payment In Progress
                                      {orderBy === "PaymentInProgress" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "PaymentFailed" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "PaymentFailed"}
                                      direction={orderBy === "PaymentFailed" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "PaymentFailed")}
                                    >
                                      Payment Failed
                                      {orderBy === "PaymentFailed" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "PaymentSettled" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "PaymentSettled"}
                                      direction={orderBy === "PaymentSettled" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "PaymentSettled")}
                                    >
                                      Payment Settled
                                      {orderBy === "PaymentSettled" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                {props.items[0].CanExpire === "Y" &&
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "Expired" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "Expired"}
                                      direction={orderBy === "Expired" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "Expired")}
                                    >
                                      Expired Funds
                                      {orderBy === "Expired" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                }
                                {props.items[0].CanExpire === "Y" &&
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "ExpirationDate" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "ExpirationDate"}
                                      direction={orderBy === "ExpirationDate" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "ExpirationDate")}
                                    >
                                      Expiration Date
                                      {orderBy === "ExpirationDate" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                }
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "FulfillmentDate" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "FulfillmentDate"}
                                      direction={orderBy === "FulfillmentDate" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "FulfillmentDate")}
                                    >
                                      Fulfillment Date
                                      {orderBy === "FulfillmentDate" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "PortalStatusName" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "PortalStatusName"}
                                      direction={orderBy === "PortalStatusName" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "PortalStatusName")}
                                    >
                                      Portal Status Name
                                      {orderBy === "PortalStatusName" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "CreatedDate" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "CreatedDate"}
                                      direction={orderBy === "CreatedDate" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "CreatedDate")}
                                    >
                                      Created Date
                                      {orderBy === "CreatedDate" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "UpdatedDate" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "UpdatedDate"}
                                      direction={orderBy === "UpdatedDate" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "UpdatedDate")}
                                    >
                                      Updated Date
                                      {orderBy === "UpdatedDate" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                            </StyledTableRow>
                        </StyledTableHead>
                        <TableBody>
                            {
                            (items || rowsPerPage > 0
                                ? stableSort(items, getSorting(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : items)
                            .map((item, index) => (
                                <Fragment key={index}>
                                    <StyledTableRow>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.PromotionId}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.PromotionName}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.ExpiredFundsAdded}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.NewFundsAdded}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.AssignedFunds}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.AllocatedFund}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.RewardAvailable}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.Initiated}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.PaymentInProgress}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.PaymentFailed}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.PaymentSettled}</StyledTableColCell>
                                        {item.CanExpire == "Y" && <StyledTableColCell style={{textAlign:"center"}}>{item.Expired}</StyledTableColCell>}
                                        {item.CanExpire == "Y" && <StyledTableColCell style={{textAlign:"center"}}>{item.ExpirationDate ? moment(item.Incentive_Exp_Date).format("MM/DD/YYYY") : ""}</StyledTableColCell>}
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.FulfillmentDate ? moment(item.Incentive_Exp_Date).format("MM/DD/YYYY") : ""}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.PortalStatusName}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.CreatedDate ? moment(item.Incentive_Exp_Date).format("MM/DD/YYYY") : ""}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.UpdatedDate ? moment(item.Incentive_Exp_Date).format("MM/DD/YYYY") : ""}</StyledTableColCell>
                                    </StyledTableRow>
                                </Fragment>
                            ))
                            }
                        </TableBody>
                        <StyledTableFooterReport>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 50, { label: 'All', value: -1 }]}
                                    colSpan={17}
                                    count={items.length || 0}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onChangePage={(event, page)=>handlePageChange(event, page)}
                                    onChangeRowsPerPage={(event) => handleRowsPerPageChange(event)}
                                />
                            </TableRow>
                            <TableRow>
                                <StyledTableCell colSpan={17} style={{justifyContent:"flex-end"}}>
                                {downloadProgress ? (
                                    <Box width="100px" display="flex" mt={1.875} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
                                    ) : (
                                    <div>{canDownload && <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
                                            <Button variant="contained" color="primary" className="btns save" onClick={(event)=>handleDownload(event)} >Download</Button>
                                        </Box>}
                                    </div>
                                    )}
                                </StyledTableCell>
                            </TableRow>   
                        </StyledTableFooterReport>
                    </Table>
                )}
                </DialogContent>
            </Box>
        </Dialog> }
        </>
    );
}