import Cookies from 'universal-cookie'
 import axios from 'axios'
 import config from '~/config'
 import { getAccessToken } from '~/redux/helpers/user'
 
 export const fetchReportStatus = async () => {
 	const accessToken = await getAccessToken()
 	try {
 		const response = await axios({
             //url: `https://24sdmxag6g.execute-api.us-east-1.amazonaws.com/develop/getSignedUrl`,
             url: `${config.base_url}/api/Reward/ReportRewardStatus`,
 			method: 'GET',
 			headers: {
 				'Content-Type': 'application/json',
 				'Authorization': `${accessToken}`,
 			}
 		})
 		return await response.data
 	} catch (error) {
 	    let errMsg = (error.response && error.response.data.error);
 	    if( error && error.response && error.response.status == 500){
 	        errMsg = (error.response.data.error && error.response.data.error.error.error)
 	    }
 		return {
 			error: (errMsg) || "An error has occured."
 		}
 	}
}