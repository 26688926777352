import React, { Fragment } from "react";
import { Box, Paper, Grid } from '@material-ui/core'
import './styles.scss'

const ErrorPage404 = () => {
    return (
        <div>
            <div>
                <div id="overlay" style={{ position: "fixed", width: "100%", height: "100%", top: 0, left: 0 }}>
                    <div className="backdrop" style={{ position: "absolute", left: 0, right: 0, top: 0, bottom: 0, height: "100%", width: "100%", background: "black", zIndex: 9, opacity: 0.5 }}></div>
                    <div style={{ background: `url('../static/media/login-bg.b164f405.jpg')`, width: "50%", height: "100%", zIndex: 0, backgroundRepeat: "round", position: "absolute" }}>
                    </div>

                    <div className="popup" style={{ position: "fixed", left: "50%", right: "50%", top: "50%", width: "400px", height: "170px", textAlign: "center", marginLeft: "-232px", marginTop: "-140px", background: "white", padding: "20px 30px", zIndex: "10" }}>
                        <h1>404 Error !</h1>
                        <span style={{ color: "grey", fontSize: "18px" }}>
                            Page not found.
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ErrorPage404;