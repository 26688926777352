import React from 'react';
import { Route, Switch } from 'react-router-dom';
import App from './App';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import Cookies from 'universal-cookie';
import config from '~/config';
import { fetchSSODetails } from '~/redux/helpers/sso.js';
import ErrorPage404 from "~/views/misc/404error.js";

const cookies = new Cookies();
export class Wrapper extends React.Component {

  updateCookie(name, value) {
    document.cookie = name + '=' + value + `; Path=/;`;
  }

  getSSODetails() {
    return fetchSSODetails();
  }

  setCookie(name, value) {
    document.cookie = name + '=' + value + `; Path=/;`;
  }

  deleteCookie(name) {
    document.cookie =
      name + `=; Path=/`;
  }

  deleteAllCookies() {
    this.deleteCookie('maccessToken');
    this.deleteCookie('mrefreshToken');
    this.deleteCookie('mclient');
  }

  render() {
    return (
      <div>
        <Switch>
          <Route
            key={1}
            exact
            path={'/:clientKey/ssoLogin'}
            render={(props) => {
              const params = new Map(
                window.location.search
                  .slice(1)
                  .split('&')
                  .map((param) => param.split('='))
              );
              let token = params.get('token');
              const { clientKey } = props.match.params;

              this.deleteAllCookies();
              this.setCookie('maccessToken', token);

              if (token) {
                this.getSSODetails().then((response) => {
                  if (response.error) {
                    props.history.push('/error');
                    return false;
                  }

                  if (response.accessToken) {
                    this.deleteAllCookies();
                    cookies.set('maccessToken', response.accessToken, { path: '/' })
                    cookies.set('mrefreshToken', response.refreshToken, { path: '/' })
                    cookies.set('mclient', clientKey, { path: '/' })

                    window.location.href = `${config.base_url}/${clientKey}/promotion`;
                    //window.location.href = "http://localhost:3000/promotion" 

                  } else {
                    props.history.push('/error');
                    return false;
                  }
                });
              }
              return (
                <>
                  <div>
                    <CircularProgress color="primary" />
                  </div>
                </>
              );
            }}
          />
          <Route key={2} path={'/:clientKey'} component={App} />
          <Route exact path="*"><ErrorPage404 /></Route>
        </Switch>
      </div>
    );
  }
}

export default connect((state) => ({
  ...state.user, ...state.permissions
}))(Wrapper);