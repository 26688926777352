import React, { Component, Fragment } from 'react'
import { Button, Grow, Popper, ClickAwayListener, Menu, MenuItem, Grid, Paper, Link } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import NavBar from './NavBar';
import { connect } from 'react-redux';
import { logout, login } from '~/redux/actions/user';
import './styles.scss'


class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false,
            anchorEl: null,
        }
    }

    handleToggle = (event) => {
        this.setState({
            menuOpen: !this.state.menuOpen,
            anchorEl: (event.currentTarget),
        })
    };

    handleClose = () => {
        this.setState({
            menuOpen: false,
            anchorEl: null,
        })
    };

    logout = () => {
        this.props.dispatch(logout());
        const { CODE } = this.props.clientConfig.data;
        let body = document.body;
        body.classList.remove(CODE);
    }

    render() {
        const { menuOpen, anchorEl } = this.state;
        const { info, metadata, clientKey } = this.props.user;
        //const { clientKey } = this.props.match.params || "";
        return (
            <Fragment>
                <div id="header">
                    <Paper square className="navBar">
                        <Grid container spacing={0}>
                            <Grid item xs={3} md={4} lg={4}>
                                <div className="logo-wrap"> <img alt={metadata && metadata.alt} src={require(`~/assets/images/${clientKey}/logo.png`)} /></div>
                            </Grid>
                            <Grid item xs={7} md={6} lg={6}>
                                <NavBar {...this.props} />
                            </Grid>
                            <Grid item xs={2} md={2} lg={2}>
                                <div className="RightNav">
                                    {info.UserTypeID === 1 ?
                                        <Link component="button" variant="body2" onClick={() => this.props.history.push(`/${clientKey}/settings`)} >
                                            <img alt="Settings" src={require('~/assets/images/setting-icon.png')} />Settings
                                        </Link> : null
                                    }
                                    <Link component="button" variant="body2" >
                                        <img alt="Account" src={require('~/assets/images/user-ac.png')} />
                                    </Link>
                                    <div id="header-menu-list">
                                        <Link
                                            size="small"
                                            aria-controls="header-menu"
                                            aria-haspopup="menu"
                                            onClick={(event) => this.handleToggle(event)}>
                                            <ArrowDropDownIcon />
                                        </Link>
                                        <Menu
                                            id="header-menu"
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={menuOpen}
                                            onClose={() => this.handleClose()}
                                        >
                                            <MenuItem onClick={() => this.logout()}>Logout</MenuItem>
                                        </Menu>
                                    </div>
                                </div>
                            </Grid>

                        </Grid>
                    </Paper>

                </div>


            </Fragment>
        )
    }
}
export default connect(state => (
    { ...state.user, ...state.clientConfig }
))(Header)
