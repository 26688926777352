import React, { Component, Fragment } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import SupportListView from './ListView';
import SupportTicket from './SupportTicket';
import StatusView from './StatusView';

class AuthRoute extends Component {
    isAllowed(claims, name) {
        let permissions = claims;
        let str = `${name && name.toLowerCase()}`;
        let isEnabled = permissions && permissions.includes(str);
        if (isEnabled) {
            return true
        }
        return false;
    }
    render() {
        const { component: Component, name, claims, clientKey, ...rest } = this.props;
        let isAccessable = this.isAllowed(claims, name);
        return (
            <Route exact={true} {...rest} render={(props) => (
                (isAccessable === true) ?
                    <Fragment>
                        <Component {...props} clientKey={clientKey} claims={claims} />
                    </Fragment>
                    : null
            )} />
        )
    }
}

class Support extends Component {
    render() {
        const { claims, clientKey } = this.props;
        return (
            <Fragment>
                <Switch>
                    <AuthRoute exact path='/:clientKey/support' component={SupportListView} clientKey={clientKey} name="support_view" claims={claims} />
                    <AuthRoute exact path='/:clientKey/support/ticket' component={SupportTicket} clientKey={clientKey} name="support_view" claims={claims} />
                    <AuthRoute exact path='/:clientKey/support/status' component={StatusView} clientKey={clientKey} name="support_view" claims={claims} />
                </Switch>
            </Fragment>
        )
    }
}

export default Support;