import React, { Component } from 'react'
import { Button, Typography, Card, CardContent } from '@material-ui/core';
import './styles.scss';
import config from '~/config';

export class SSOLoginScreen extends Component {
    constructor(props) {
        super(props)
    }

    UserLogin = (clientKey) => {
        console.log("config.env: ", config.env);
        switch (config.env) {
            case 'develop':
                switch (clientKey) {
                    case 'hd':
                        window.location.href = 'https://it-federation.usbank.com/idp/startSSO.ping?PartnerSpId=IncedoCashInAdmin';
                        break;
                    case 'acg':
                        window.location.href = 'https://it-federation.usbank.com/idp/startSSO.ping?PartnerSpId=ACGCashInAdmin';
                        break;
                    case 'bmw':
                        window.location.href = 'https://it-federation.usbank.com/idp/startSSO.ping?PartnerSpId=BMWCashInAdmin';
                        break;
                }
                break;

            case 'uat':
                switch (clientKey) {
                    case 'hd':
                        window.location.href = 'https://uat-federation.usbank.com/idp/startSSO.ping?PartnerSpId=IncedoCashInAdmin';
                        break;
                    case 'acg':
                        window.location.href = 'https://uat-federation.usbank.com/idp/startSSO.ping?PartnerSpId=ACGCashInAdmin';
                        break;
                    case 'bmw':
                        window.location.href = 'https://uat-federation.usbank.com/idp/startSSO.ping?PartnerSpId=BMWCashInAdmin';
                        break;
                }
                break;

            case 'Prod':
                switch (clientKey) {
                    case 'hd':
                        window.location.href = 'https://federation.usbank.com/idp/startSSO.ping?PartnerSpId=IncedoCashInAdmin';
                        break;
                    case 'acg':
                        window.location.href = 'https://federation.usbank.com/idp/startSSO.ping?PartnerSpId=ACGCashInAdmin';
                        break;
                    case 'bmw':
                        window.location.href = 'https://federation.usbank.com/idp/startSSO.ping?PartnerSpId=BMWCashInAdmin';
                        break;
                }
                break;
        }
    }

    render() {
        const urlParams = new URLSearchParams(window.location.search);
        const errorMessage = urlParams.get('error');
        const { clientKey } = this.props.match.params || "";
        let clientName = ''
        switch (clientKey.toLowerCase()) {
            case 'hd':
                clientName = 'H-D';
                break;
            case 'bmw':
                clientName = 'BMW';
                break;
            case 'acg':
                clientName = 'ACG';
                break;
        }

        return (
            <div className='root'>
                <Card style={{ maxWidth: 350, height: 500 }} >
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                        <img alt="logo" src={require(`~/assets/images/logo/${clientKey.toLowerCase()}.jpg`)}/>
                    </div>
                    <CardContent>
                        <Button fullWidth={false} variant="contained" color="secondary" style={{
                            width: "250px",
                            height: "50px",
                            marginLeft: "35px",
                            marginTop: "50px"
                        }} onClick={() => this.props.history.push(`/${clientKey}/incedoLogin`)}>
                            Incedo Login
                        </Button>

                        <Button fullWidth={false} variant="contained"
                            color="primary" style={{
                                width: "250px",
                                height: "50px",
                                marginLeft: "35px",
                                marginTop: "35px"
                            }} onClick={() => this.UserLogin(clientKey)}>
                            {clientName} Admin Login
                        </Button>
                        <div style={{ marginTop: '10px', color: 'red' }}>{errorMessage === 'true' ? 'An error occurred during the process. Please try again later or contact support if the problem persists' : ''}</div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <img alt="CA$H IN" src={require(`~/assets/images/CA$H IN Logo_70x50.jpg`)} />
                        </div>
                        <Typography style={{
                            color: "black",
                            marginTop: "15px",
                            textAlign: "center",
                            fontSize: "10px"
                        }}>
                            © 2024
                        </Typography>
                    </CardContent>
                </Card>
            </div>
        )
    }
}