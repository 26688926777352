import React, { Component, Fragment } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import RewardListView from '../Rewards/ListView';
import FileUpload from '../Rewards/FileUpload';
import RewardsUploadStatus from '../Rewards/Status';
import SweepStakeFileUpload from '../Rewards/FileUpload/sweepstake';
import RewardsSweepStakeUploadStatus from '../Rewards/Status/sweepstakestatus';

import './styles.scss'

class AuthRoute extends Component {

    isAllowed(claims, name) {
        let permissions = claims;
        let str = `${name && name.toLowerCase()}`;
        let isEnabled = permissions && permissions.includes(str);
        if (isEnabled) {
            return true
        }
        return false;
    }

    render() {
        const { component: Component, name, claims, clientKey, ...rest } = this.props;
        let isAccessable = this.isAllowed(claims, name);
        return (
            <Route exact={true} {...rest} render={(props) => (
                (isAccessable === true) ?
                    <Fragment>
                        <Component {...props} clientKey={clientKey} />
                    </Fragment>
                    : null
            )} />
        )
    }
}

export class Rewards extends Component {
    render() {
        const { claims, clientKey } = this.props;
        return (
            <Fragment>
                <Switch>
                    <AuthRoute exact path='/:clientKey/rewards' component={RewardListView} claims={claims} clientKey={clientKey} name={"rewards_view"} />
                    <AuthRoute exact path='/:clientKey/rewards/upload' component={FileUpload} claims={claims} clientKey={clientKey} name={"rewards_uploadfulfilment"} />
                    <AuthRoute exact path='/:clientKey/rewards/upload/status/:FileRecordID' component={RewardsUploadStatus} claims={claims} clientKey={clientKey} name={"rewards_uploadfulfilment"} />
                    <AuthRoute exact path='/:clientKey/rewards/uploadsweepstake' component={SweepStakeFileUpload} claims={claims} clientKey={clientKey} name={"rewards_uploadfulfilment"} />
                    <AuthRoute exact path='/:clientKey/rewards/uploadsweepstake/status/:FileRecordID' component={RewardsSweepStakeUploadStatus} claims={claims} clientKey={clientKey} name={"rewards_uploadfulfilment"} />
                </Switch>
            </Fragment>
        )
    }
}

export default Rewards;