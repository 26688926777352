import React, { Fragment } from "react";
import {
  CircularProgress,
  Table,
  TableRow,
  TableBody,
  TablePagination,
  TableCell,
  TableSortLabel,
  Box
} from "@material-ui/core";
import {
  StyledTableHead,
  StyledTableRow,
  StyledTableCell,
  StyledTableFooter
} from "~/components/StyledTable";
import { Link } from "react-router-dom";
import ToggleSwitch from "../../../components/Switch";
import { ConfirmDialog } from "../../../components/Dialogs";
import { updateUserActionItems } from "~/redux/helpers/user.js"

export default class UserManagementList extends React.Component {
  state = {
    isLoading: true,
    fetchingList: true,
    page: 0,
    rowsPerPage: 5,
    clientId: 1,
    open: false,
    openStatusDialog: false,
    SortColumn: "",
    SortOrder: ""
  };

  userToDelete;

  componentDidMount = async () => {
    this.updateUsersList();
  }

  handlePageChange = (event, page) => {
    const { SortColumn, SortOrder } = this.state;
    let newSortOrder = SortOrder === "asc" ? "ASC" : "DESC";
    this.setState(
      {
        page
      },
      () => this.updateUsersList(SortColumn, newSortOrder)
    );
  };

  handleRowsPerPageChange = event => {
    const { SortColumn, SortOrder } = this.state;
    let newSortOrder = SortOrder === "asc" ? "ASC" : "DESC";
    this.setState(
      {
        page: 0,
        rowsPerPage: parseInt(event.target.value, 10)
      },
      () => this.updateUsersList(SortColumn, newSortOrder)
    );
  };

  updateUsersList = (SortColumn, SortOrder) => {
    const { clientId, page, rowsPerPage } = this.state;
    this.setState(
      {
        fetchingList: true,
        isLoading: true
      },
      () => {
        this.props
          .fetchUsersList({
            clientId,
            pageSize: rowsPerPage,
            pageNo: page + 1,
            SortColumn: SortColumn || null,
            SortOrder: SortOrder || null
          })
          .then(() => {
            this.setState({
              isLoading: false,
              fetchingList: false
            });
          });
      }
    );
  };

  handleSorting(SortColumn) {
    const { SortOrder } = this.state;
    let newSortOrder = SortOrder === "asc" ? "desc" : "asc";
    this.setState({ SortColumn: SortColumn, SortOrder: newSortOrder, fetchingList: true }, () => {
      this.updateUsersList(SortColumn, newSortOrder === "asc" ? "ASC" : "DESC");
    });
  }

  handleUserActiveSwitch = async (user) => {
    //let user = this.getUserToDelete();
    this.setState(
      {
        fetchingList: true,
        isLoading: true
      },
      () => {
        let data = { Id: user.Id, ActionType: 1, IsActive: !user.IsActive, IsDeleted: false, LastUpdatedBy: 1 }
        updateUserActionItems(data).then(response => {
          if (response && !response.errorMessage) {
            user.IsActive = !user.IsActive;
            this.setState({ ...this.state, fetchingList: false, isLoading: false, });
          }
        }).catch(err => {
          alert(err);
        })
      })
  }

  handleUserDelete = (user) => {
    this.setUserToDelete(user);
    this.setState({ open: true })
  }

  setUserToDelete = (user) => {
    this.userToDelete = user;
  }

  getUserToDelete = () => {
    return this.userToDelete;
  }

  deleteUser = () => {
    let user = this.getUserToDelete();
    const { users} = this.props.users;
    const { list } = users;
    this.setState({
      open: false,
      isLoading: true
    }, () => {
      let data = { Id: user.Id, ActionType: 2, IsActive: user.IsActive, IsDeleted: true, LastUpdatedBy: 1 }
      updateUserActionItems(data).then(response => {
        if (response && !response.errorMessage) {
          list.forEach((_user, i) => {
            if (_user.Id === user.Id) {
              list.splice(i, 1);
              this.setState({ ...this.state, fetchingList: false, isLoading: false, });
            }
          })
        }
      }).catch(err => {
        alert(err);
      })
    })
  }

  getUserType = (id) => {
    const { userTypes } = this.props;
    let userType = userTypes && userTypes.filter(type => type.Id === id)[0];
    return userType && userType.UserType;
  }

  formatContactNumber = (number) => {
    return number && JSON.stringify(number).replace(/[^\d]/g, "").replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  }

  render() {
    const { open, isLoading, page, rowsPerPage, openStatusDialog, SortColumn, SortOrder } = this.state;
    const { openNotifier, userTypes, myDetails, clientKey } = this.props;
    const { users } = this.props.users;
    const { list, totalCount } = users;
    console.log(users);
    return (
      <div>
        <Table>
          <StyledTableHead>
            <TableRow>
              <StyledTableCell>
                <TableSortLabel
                  active={SortColumn === "name"}
                  direction={SortColumn === "name" ? SortOrder : 'asc'}
                  onClick={() => this.handleSorting("name")}
                >
                  Name
                   {SortColumn === "name" ? (
                    <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20, width: 1, }}>
                      {SortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell>
                <TableSortLabel
                  active={SortColumn === "phone"}
                  direction={SortColumn === "phone" ? SortOrder : 'asc'}
                  onClick={() => this.handleSorting("phone")}
                >
                  Phone
                  {SortColumn === "phone" ? (
                    <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20, width: 1, }}>
                      {SortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell>
                <TableSortLabel
                  active={SortColumn === "email"}
                  direction={SortColumn === "email" ? SortOrder : 'asc'}
                  onClick={() => this.handleSorting("email")}
                >
                  Email
                  {SortColumn === "email" ? (
                    <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20, width: 1, }}>
                      {SortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell>
                <TableSortLabel
                  active={SortColumn === "usertype"}
                  direction={SortColumn === "usertype" ? SortOrder : 'asc'}
                  onClick={() => this.handleSorting("usertype")}
                >
                  User Type
                  {SortColumn === "usertype" ? (
                    <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20, width: 1, }}>
                      {SortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell>Set Permission</StyledTableCell>
              <StyledTableCell>Edit</StyledTableCell>
              <StyledTableCell>Action</StyledTableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {!isLoading && userTypes && userTypes.length > 0 ? (
              list && list.map((user, index) => (
                <Fragment key={index}>
                  <StyledTableRow>
                    <StyledTableCell>{`${user.FirstName}  ${user.LastName}`}</StyledTableCell>
                    <StyledTableCell>{this.formatContactNumber(user.PhoneNumber)}</StyledTableCell>
                    <StyledTableCell>{user.EmailAddress}</StyledTableCell>
                    <StyledTableCell>{user.UserType}</StyledTableCell>
                    <StyledTableCell>
                      {user.UserTypeId !== 1 && user.CreatedBy !== "-99" || (myDetails && myDetails.user && myDetails.user.info && myDetails.user.info.CreatedBY === "-99" && user.CreatedBy !== "-99") ? (
                        <Link to={`/${clientKey}/settings/permissions/${user.Id}`}>
                          <span className={"oval"}>
                            <img src={require('~/assets/images/gear.png')} alt="permission" />
                          </span>
                        </Link>
                      ) : null}
                    </StyledTableCell>
                    <StyledTableCell>
                      {user.UserTypeId !== 1 && user.CreatedBy !== "-99" || (myDetails && myDetails.user && myDetails.user.info && myDetails.user.info.CreatedBY === "-99" && user.CreatedBy !== "-99") ? (
                        <Link
                          to={{
                            pathname: `/${clientKey}/settings/editUser/${user.Id}/user`,
                            state: {
                              id: user.Id,
                              firstName: user.FirstName,
                              lastName: user.LastName,
                              contactNumber: user.PhoneNumber,
                              userTypeId: user.UserTypeId,
                              email: user.EmailAddress,
                              password: user.password,
                              userName: user.UserName,
                              SSOUserId:  user.SSOUserId,
                              IsSSO: user.IsSSO
                            }
                          }}
                        >
                          <span className={"oval"}>
                            <img src={require('~/assets/images/edit.png')} alt="edit" />
                          </span>
                        </Link>
                      ) : null}
                    </StyledTableCell>
                    <StyledTableCell>
                      {user.UserTypeId !== 1 && user.CreatedBy !== "-99" || (myDetails && myDetails.user && myDetails.user.info && myDetails.user.info.CreatedBY === "-99" && user.CreatedBy !== "-99") ? (
                        <div className="actions">
                          <ToggleSwitch title={user.IsActive ? "activated" : "Deactivated"} active={user.IsActive} handleToggle={() => this.handleUserActiveSwitch(user)} />
                          {!user.IsDeleted ? <span
                            className={"oval"}
                            onClick={() => this.handleUserDelete(user)}
                          >
                            <img src={require('~/assets/images/delete.png')} alt="Delete" title="Delete" />
                          </span> : null}
                        </div>
                      ) : null}
                    </StyledTableCell>
                  </StyledTableRow>
                  <TableRow></TableRow>
                </Fragment>
              ))
            ) : (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Box
                      display="flex"
                      p={5}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <CircularProgress color="primary" />
                    </Box>
                  </TableCell>
                </TableRow>
              )}
          </TableBody>
          <StyledTableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                colSpan={8}
                count={totalCount || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true
                }}
                onChangePage={this.handlePageChange}
                onChangeRowsPerPage={this.handleRowsPerPageChange}
              />
            </TableRow>
          </StyledTableFooter>
        </Table>
        <ConfirmDialog
          open={open}
          title={""}
          message={"Are you sure you want to delete user profile?"}
          onCancel={() => {
            this.setState({ open: false });
          }}
          onConfirm={this.deleteUser}
        />
        {/* <ConfirmDialog
          open={openStatusDialog}
          title={""}
          message={"Are you sure you want to update user status?"}
          onCancel={() => {
            this.setState({ openStatusDialog: false });
          }}
          onConfirm={() => {
            this.setState({ openStatusDialog: false})
            this.handleUserActiveSwitch();
          }}
        /> */}
      </div>
    );
  }
}