import Cookies from "universal-cookie";
import axios from "axios";
import config from "~/config";
import { getAccessToken } from "~/redux/helpers/user";

export const fetchUserPermissionsMinified = ({ ClientId, UserId }) => async dispatch => {
    try {
        const accessToken = await getAccessToken();
        const response = await axios({
            //url: `https://x2sea7snqf.execute-api.us-east-2.amazonaws.com/dev/GetClaimsByUserId`,
            //url: `http://elan-bankers-dev.tfnhdzy2me.us-east-2.elasticbeanstalk.com/api/Settings/GetClaimsByUserId`,
            url: `${config.base_url}/api/Settings/GetClaimsByUserId`,
            //url: `https://99kwa7tkf7.execute-api.us-east-2.amazonaws.com/uat/GetClaimsByUserId`,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `${accessToken}`,
            },
            data: JSON.stringify({
                ClientId: ClientId,
                UserId: UserId
            })
        });

        const responseBody = await response.data;
        if (!responseBody.error) {
            dispatch({
                type: "USER_PERMISSIONS_MINI_SUCCESS",
                payload: responseBody.claims
            });
            return true;
        }

        dispatch({
            type: "USER_PERMISSIONS_MINI_FAILED",
            payload: responseBody.error || "Oops! Something went wrong."
        });
        return false;

    } catch (error) {
        dispatch({
            type: "USER_PERMISSIONS_MINI_FAILED",
            payload: error.message || "An error has occured."
        });
        return false;
    }
};
