import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux'
import { Grid, TextField, Button, Box, CircularProgress } from '@material-ui/core';


export default class ResetPassword extends Component {
	state = {
		processing: false,
		errors: [],
		buttonDisabled: true,
	}
	static getDerivedStateFromProps(nextProps, prevState) {
		if(nextProps.error){
			return {
				errors: [
					nextProps.error
				]
			}			
		}
		return null
	}
	validateResetPassword = (newPassword, oldPassword, conformPassword) => {
		const errors = [];
		const pwd = newPassword && newPassword.replace(/[\s;~^&*,?)()\[\]{} <> _ \\ \/ |` ]/, '');
		if (pwd.trim().length < 8) {
			errors.push("Password should be at least 8 charcters long");
		}
		if (!/[A-Z]/.test(pwd)) {
			errors.push("Your New Password must contain at least one uppercase character.");
		}
        if (!/[a-z]/.test(pwd)) {
			errors.push("Your New Password must contain at least one lowercase character.");
		}
		if (pwd.search(/[0-9]/) < 0) {
			errors.push("Your password must contain at least one digit.");
		}
		if (pwd !== conformPassword) {
			errors.push("New Password and Confirm Password should be same.");
		}
		if (pwd.search(/\W|_/g) < 0) {
			errors.push("Your password must contain at least one special character( @, !, #, $, %, ., = ).");
			//isValid = false
		}
		return errors;
	}
	handlePasswordReset = (event) => {
		event.preventDefault()
		this.setState({
			processing: true
		}, async () => {
			try{
				const { processReset } = this.props
				const newPassword = this.newPassword.value
				const oldPassword = this.oldPassword.value
				const confirmPassword = this.confirmPassword.value

				const errors = this.validateResetPassword(newPassword, oldPassword, confirmPassword)
				if (!errors.length) {
					await processReset(oldPassword, newPassword)
					if(this.props.error){
						this.setState({
							buttonDisabled: true,
							processing: false
						})
					}
				} else {
					throw errors
				}
			} catch(errors){
				this.setState({ 
					errors: errors,
					processing: false
				});
			}
		})
	}
    handlePasswordChange(e) {
        const pwd = e.target.value && e.target.value.replace(/[\s;~^&*,?)()\[\]{} <> _ \\ \/ |` ]/, '');
        //this.setState({ user: { ...this.state.user, password: pwd.trim() } });

        this.newPassword.value = pwd.trim();
        if (this.oldPassword.value && this.newPassword.value && this.confirmPassword.value && !this.state.processing) {
			this.setState({
				buttonDisabled: false,
			})
		} else {
			this.setState({
				buttonDisabled: true
			})
		}
    }
	handleInputChange = e => {
		if (this.oldPassword.value && this.newPassword.value && this.confirmPassword.value && !this.state.processing) {
			this.setState({
				buttonDisabled: false,
			})
		} else {
			this.setState({
				buttonDisabled: true
			})
		}
	}
	render() {
		const { processing, buttonDisabled, errors } = this.state
		return (
			<Fragment>
				<div className="welcome-message">
					Set a New Password
				</div>
				<form noValidate onSubmit={this.handlePasswordReset}>
					<TextField
						type="password"
						fullWidth={true}
						autoComplete="off"
						placeholder="OLD PASSWORD"
						variant="outlined"
                        onPaste={(e) => e.preventDefault()} onCopy={(e) => e.preventDefault()}
                        onDrag={(e) => e.preventDefault()} onCopy={(e) => e.preventDefault()}
                        onDrop={(e) => e.preventDefault()} onCopy={(e) => e.preventDefault()}
                        onPaste={(e) => e.preventDefault()} onCopy={(e) => e.preventDefault()}
						onChange={this.handleInputChange}
						inputProps={{
							ref: el => this.oldPassword = el
						}}
					/>
					<TextField
						type="password"
						fullWidth={true}
						autoComplete="off"
						placeholder="NEW PASSWORD"
						variant="outlined"
                        onPaste={(e) => e.preventDefault()} onCopy={(e) => e.preventDefault()}
                        onDrag={(e) => e.preventDefault()} onCopy={(e) => e.preventDefault()}
                        onDrop={(e) => e.preventDefault()} onCopy={(e) => e.preventDefault()}
                        onPaste={(e) => e.preventDefault()} onCopy={(e) => e.preventDefault()}
						//onChange={this.handleInputChange}
                        onChange={this.handlePasswordChange.bind(this)}
						inputProps={{
							ref: el => this.newPassword = el
						}}
					/>
					<TextField
						type="password"
						fullWidth={true}
						autoComplete="off"
						placeholder="CONFIRM NEW PASSWORD"
						variant="outlined"
                        onPaste={(e) => e.preventDefault()} onCopy={(e) => e.preventDefault()}
                        onDrag={(e) => e.preventDefault()} onCopy={(e) => e.preventDefault()}
                        onDrop={(e) => e.preventDefault()} onCopy={(e) => e.preventDefault()}
                        onPaste={(e) => e.preventDefault()} onCopy={(e) => e.preventDefault()}
						onChange={this.handleInputChange}
						inputProps={{
							ref: el => this.confirmPassword = el
						}}
					/>
					<div className="reset-form-footer">
						{this.state.error && (
							<Box color="error.main" className="error">
								{this.state.error}
							</Box>
						)}
						{!this.state.isValid && (
							<Box color="error.main">
								<ul className="error">
									{this.state.errors.map(err => <li key={err}>{err}</li>)}
								</ul>
							</Box>
						)}
						{processing ? (
							<CircularProgress color="primary" />
						) : (
								<Button disabled={buttonDisabled} type="submit" fullWidth={false} variant="contained" color="primary">
									CONFIRM
								</Button>
							)}
					</div>
				</form>
			</Fragment>
		)
	}

}