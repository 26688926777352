import React from "react";
import {
  Grid,
  Box,
  Paper,
  TextField,
  NativeSelect,
  Button,
  CircularProgress
} from "@material-ui/core";
import "./style.scss";
import { Link } from "react-router-dom";
import { AlertDialog } from "../../../components/Dialogs";
import { createUser, updateUserDetails } from "~/redux/actions/settings";
import { getUserTypes } from "~/redux/helpers/user";
import { connect } from "react-redux";
import { Tab, Tabs } from  '@material-ui/core'

const style = {
  paper: {
    padding: "41px 64px 99px 54px",
    color: "#3f3f3f",
    margin: "26px 0"
  }
};

class User extends React.Component {
  constructor(props) {
    super(props);
    const { state } = this.props.location;
    this.state = {
      user: state,
      isSpinnerActive: false,
      isDialogActive: false,
      userTypes: [],
      isPasswordEditable: props.location.pathname.includes("addUser") ? true :false,
      submitDialogueMessage: ""
    };
  }

  componentDidMount = async () => {
    const response = await getUserTypes({ RoleId: 1 });
    //const userResponse = await getUserDetails();
    if (response.result && !response.ErrorMessage) {
     // this.setState({ userTypes: response.result });
     const { pathname } =  await this.props.location;
     let isAddUserScreen =  await pathname.includes("addUser");
          if(isAddUserScreen)
          {
            this.setState({ userTypes: response.result , user:{...this.state.user, isSSO: false}});
          }else{
            this.setState({ userTypes: response.result , user:{...this.state.user, isSSO: this.state.user.IsSSO == 1 ? true : false}});
          }
    } else {
      alert("Something went wrong.");
    }
  }

  handleUserTypeChange(e) {
    this.setState({ user: { ...this.state.user, userTypeId: e.target.value } });
  }
  handleFirstNameChange(e) {
    this.setState({ user: { ...this.state.user, firstName: e.target.value } });
  }
  handleUserNameChange(e) {
    this.setState({ user: { ...this.state.user, userName: e.target.value.replace(/[\s ]/, '') } });
  }
  handleLastNameChange(e) {
    this.setState({ user: { ...this.state.user, lastName: e.target.value } });
  }
  handleContactChange(e) {
    this.setState({ user: { ...this.state.user, contactNumber: e.target.value.replace(/[\s.;^!@%$#&*,?%a-zA-Z)() ]/, '') } });
  }
  handlePasswordChange(e) {
    const pwd = e.target.value && e.target.value.replace(/[\s;~^&*,?)()\[\]{} <> _ \\ \/ |` ]/, '');
    this.setState({ user: { ...this.state.user, password: pwd.trim() } });
  }
  handleEmailChange(e) {
    this.setState({ user: { ...this.state.user, email: e.target.value.replace(/[\s;^!%$#&*,?%)() ]/, '') } });
  }
  async handleSubmit() {
    const { pathname } = await this.props.location;
    let isAddUserScreen = await pathname.includes("addUser");
    const { user, isPasswordEditable } = this.state;
    let firstNameError = !user || !user.firstName || !user.firstName.length > 0;
    let lastNameError = !user || !user.lastName || !user.lastName.length > 0;
    let userNameError = !user || !user.userName || !user.userName.length > 0;
    let passwordError = isPasswordEditable && !this.validatePassword(user && user.password);
    let ssoUserIdError ;
    if(user && user.isSSO)
    {
     ssoUserIdError = !user || !user.SSOUserId || !user.SSOUserId.length > 0;
    }
    let emailError = !user || !user.email || !user.email.length > 0;
    let isEmailInvalid = !this.validateEmail(user && user.email);
    let isContactLengthInvalid = user && user.contactNumber && user.contactNumber.length > 0 ? user.contactNumber.length !== 10 : false;
   // let isPasswordLengthValid = user && user.password && user.password.length < 8;
    let errors ;
    if(user.isSSO)
    {
      errors  = firstNameError || lastNameError ||  emailError || isEmailInvalid || isContactLengthInvalid  || ssoUserIdError;
     }else{
       errors = firstNameError || lastNameError || userNameError || passwordError || emailError || isEmailInvalid || isContactLengthInvalid ;
     }
   
    
    if (!errors) {
      await isAddUserScreen ? this.addUser(user) : this.editUser(user);
    } else {
      this.setState({ errorsFound: true });
    }
  }

  addUser = async (user) => {
    const newUser = { FirstName: user.firstName, LastName: user.lastName, UserName: user.userName, PasswordHash: user.password && user.password.length > 0 ? user.password : null, EmailAddress: user.email, PhoneNumber: user.contactNumber, UserTypeId: user.userTypeId || 4, CreatedBy: 1 , IsSSO: user.isSSO , SSOUserId: user.SSOUserId };
    this.setState(
      {
        isSpinnerActive: true
      }, () => {
        this.props.createUser(newUser).then((result) => {
          if (result && result.result) {
            this.setState(
              {
                isSpinnerActive: false,
                isDialogActive: true,
                isPasswordEditable: false,
                submitDialogueMessage : "The user was successfully added!"
              })
          } else {
            this.setState(
              {
                isSpinnerActive: false,
                isDialogActive: true,
                isPasswordEditable: false,
                submitDialogueMessage : result && result.ErrorMessage || "Something went wrong"
              })   
          }
        }).catch(err => {
          this.setState(
            {
              isSpinnerActive: false,
              isDialogActive: true,
              isPasswordEditable: false,
              submitDialogueMessage : err && err.ErrorMessage || "Something went wrong"
            })  
        });
      })
  }
  editUser = (user) => {
    const userObj = { id: user.id, FirstName: user.firstName, LastName: user.lastName, UserName: user.userName, PasswordHash: user.password || null, EmailAddress: user.email, PhoneNumber: user.contactNumber, UserTypeId: user.userTypeId, LastUpdatedBy: 1 , IsSSO: user.isSSO , SSOUserId: user.SSOUserId };
    //console.log(userObj);
    this.setState(
      {
        isSpinnerActive: true
      },
      async () => {
        this.props.updateUserDetails(userObj).then((result) => {
          if (result && result.result) {
            this.setState(
              {
                isSpinnerActive: false,
                isDialogActive: true,
                submitDialogueMessage: "The user information was successfully updated."
              })
          } else {
              this.setState(
                {
                  isSpinnerActive: false,
                  isDialogActive: true,
                  isPasswordEditable: false,
                  submitDialogueMessage : result && result.ErrorMessage || "Something went wrong"
                })   
          }
        }).catch(err => {
          this.setState(
            {
              isSpinnerActive: false,
              isDialogActive: true,
              isPasswordEditable: false,
              submitDialogueMessage : err && err.ErrorMessage || "Something went wrong"
            })
        })
      })
  }

  validateEmail(email) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  validatePassword(password) {
    let re = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).*$/;
    const pwd = password && password.trim();
    if(!re.test(pwd) || (pwd.search(/\W|_/g) < 0)) {
        return false
    }

    return true;
  }

  handleChangeISO = (event, value) => {
    const { user } = this.state;
    const newUserDetail = { ...user };
    if (value === 1) {
      newUserDetail["isSSO"] = true;
    } else {
      newUserDetail["isSSO"] = false;
    }
    this.setState({ user: { ...newUserDetail } });
  };
  
  // handling events state
  handleSsoUserIdChange(e) {
    this.setState({ user: { ...this.state.user, SSOUserId: e.target.value } });
  }
  

  render() {
    const { user, isDialogActive, isSpinnerActive, userTypes, errorsFound, isPasswordEditable, submitDialogueMessage } = this.state;
    const { pathname } = this.props.location;
    const { source } = this.props.match.params;
    let firstNameError = !user || !user.firstName || !user.firstName.length > 0;
    let lastNameError = !user || !user.lastName || !user.lastName.length > 0;
    let userNameError = !user || !user.userName || !user.userName.length > 0;
    let ssoUserIdError ;
    if(user && user.isSSO)
    {
     ssoUserIdError = !user || !user.SSOUserId || !user.SSOUserId.length > 0;
    }
    let passwordError = isPasswordEditable && !this.validatePassword(user && user.password) || user && user.password && user.password.length < 8;
    let emailError = !user || !user.email || !user.email.length > 0;
    let isEmailInvalid = !this.validateEmail(user && user.email);
    let isAddUserScreen = pathname.includes("addUser");
    let isContactLengthInvalid = user && user.contactNumber && user.contactNumber.length > 0 ? user.contactNumber.length !== 10 : false;
    let showAllFields = isAddUserScreen
      ? true
      : source && source.toLowerCase().includes("user");
    let title = isAddUserScreen ? "Add User" : "Edit User";
    let submitButtonText = isAddUserScreen ? "save" : "update";
    const {clientKey} =  this.props;
    
    return (
      <Grid container justify="center" className="addUserWrapper">
        <Grid item xs={11}>
          <Box my={5}>
            <Paper style={style.paper}>
              <h3 className="heading">{title}</h3>
              <Grid container spacing={3} className="userForm">
                <Grid item xs={12} sm={3}>
                  <Box mx={1}>
                    <label className="inputLabel">
                      First Name
                      <span className="mandatory">*</span>
                    </label>
                    <TextField
                      fullWidth={false}
                      autoComplete="off"
                      variant="outlined"
                      value={user && user.firstName}
                      onChange={this.handleFirstNameChange.bind(this)}
                    />
                    {errorsFound && firstNameError && <label className="errorMessage">This Field is required.</label>}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Box mx={1}>
                    <label className="inputLabel">
                      Last Name
                      <span className="mandatory">*</span>
                    </label>
                    <TextField
                      fullWidth={false}
                      autoComplete="off"
                      variant="outlined"
                      value={user && user.lastName}
                      onChange={this.handleLastNameChange.bind(this)}
                      inputProps={{
                        ref: el => (this.promoName = el)
                      }}
                    />
                    {errorsFound && lastNameError && <label className="errorMessage">This Field is required.</label>}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Box mx={1}>
                    <label className="inputLabel">
                      Email
                      <span className="mandatory">*</span>
                    </label>
                    <TextField
                      fullWidth={false}
                      autoComplete="off"
                      variant="outlined"
                      value={user && user.email}
                      onChange={this.handleEmailChange.bind(this)}
                    />
                    {errorsFound && emailError && <label className="errorMessage">This Field is required.</label>}
                    {errorsFound && isEmailInvalid && user && user.email && <label className="errorMessage">Please enter a valid email.</label>}
                  </Box>
                </Grid>
                {showAllFields ? (
                  <Grid item xs={12} sm={9}>
                    <Box mx={1}>
                      <label className="inputLabel">Phone Number
                      {/* <span className="mandatory">*</span> */}
                      </label>
                      <span class="countryCode preTextFied">+1</span>
                      <TextField
                        fullWidth={false}
                        maxLength={10}
                        id="phoneNumber"
                        autoComplete="off"
                        variant="outlined"
                        value={user && user.contactNumber}
                        onChange={this.handleContactChange.bind(this)}
                        inputProps={{
                          ref: el => (this.promoName = el),
                          maxLength: 10
                        }}
                      />
                    </Box>
                    {user && user.contactNumber && errorsFound && isContactLengthInvalid && <label className="errorMessage">Phone number must be of 10 digits.</label>}
                  </Grid>
                ) : null}

                  {/* added  by lalit */}

 {/* tabs */}

 {isAddUserScreen ? 
 (
   <Grid item xs={12} sm={12} style={{ padding: "3px 10px" }} >
     <Box
       mx={1}
       mt={1}
       ml={2}
       display="flex"
       border={1}
       borderColor="#0b1941"
       width="450px"
     >
       <Tabs
         value={user && user.isSSO ? 1 : 0}
         onChange={(event, value) =>
           this.handleChangeISO(event, value)
         }
         variant="fullWidth"
         style={{ flexGrow: 1, margin: 5, height: 25, minHeight: 25, padding: 0 }}
         TabIndicatorProps={{
           style: {
             color: '#fff',
             borderRadius: "4px",
             textTransform: "capitalize",
             backgroundColor: "#008ce6",
             minHeight: "20px",
             height: "25px",
             opacity: 0.5
           }
         }}

       >
         <Tab
           key="tab-0"
           label={
             <span stye>
               <span style={{ position: "relative", textTransform: "none" }}>
                 <span>Setup User Name</span>
                 {user && !user.isSSO && (
                   <img
                     style={{
                       bottom: "-15%",
                       right: "-25%",
                       width: "22px",
                       height: "22px",
                       position: "absolute",
                       color: "black"
                     }}
                     src={require(`~/assets/icons/checkTick.svg`)}
                     alt=""
                   />
                 )}
               </span>
             </span>
           }

           style={{ minHeight: "20px", height: "25px", color: "black" }}

         />
         <Tab
           key="tab-1"
           label={
             <span style={{ position: "relative", textTransform: "none" }}>
               <span >
                 <span style={{ marginRight: "8px" }}>
                   Single Sign On (SSO) ID
                 </span>
                 {user && user.isSSO && (
                   <img
                     style={{
                       bottom: "-15%",
                       right: "-15%",
                       width: "22px",
                       height: "22px",
                       position: "absolute",
                       color: "black"
                     }}
                     src={require(`~/assets/icons/checkTick.svg`)}
                     alt=""
                   />
                 )}
               </span>
             </span>
           }
           style={{
             minHeight: "20px", height: "25px",
             color: "black"
           }}
         />
       </Tabs>
     </Box>
   </Grid>
 ) : null}


 {/* tabs */}

                {showAllFields && user && !user.isSSO ? (
                  <Grid item xs={12} sm={3}> 
                    <Box mx={1}>
                      <label className="inputLabel">
                        User Name
                        <span className="mandatory">*</span>
                      </label>
                      <TextField
                        fullWidth={false}
                        disabled={!isAddUserScreen}
                        autoComplete="off"
                        variant="outlined"
                        value={user && user.userName}
                        onChange={this.handleUserNameChange.bind(this)}
                      />
                      {errorsFound && userNameError && <label className="errorMessage">This Field is required.</label>}
                    </Box>
                  </Grid>
                ) : null}
                {showAllFields && user && !user.isSSO ? (
                  <Grid item xs={12} sm={9}>
                    <Box mx={1}>
                      <label className="inputLabel">Password
                      <span className="mandatory">*</span>
                      </label>
                      <TextField
                        fullWidth={false}
                        type={"password"}
                        autoComplete="off"
                        onPaste={(e) => e.preventDefault()} onCopy={(e) => e.preventDefault()}
                        onDrag={(e) => e.preventDefault()} onCopy={(e) => e.preventDefault()}
                        onDrop={(e) => e.preventDefault()} onCopy={(e) => e.preventDefault()}
                        onPaste={(e) => e.preventDefault()} onCopy={(e) => e.preventDefault()}
                        disabled={!isPasswordEditable}
                        maxLength={40}
                        variant="outlined"
                        value={isPasswordEditable ? user && user.password : "*****************************************"}
                        onChange={this.handlePasswordChange.bind(this)}
                      />
                      {!isAddUserScreen ? <div className="hyperlink">
                        <Link onClick={() => { this.setState({ user: { ...this.state.user, password: "" }, isPasswordEditable: true }) }}>Reset password</Link>
                      </div> : null}
                      {isPasswordEditable && errorsFound &&  (!user || !user.password || !user.password.length > 0) && <label className="errorMessage">This Field is required.</label>}
                      {isPasswordEditable && user && user.password && (user.password.length < 8) && errorsFound && passwordError && <label className="errorMessage">Password must be atleast 8 characters long.</label>}
                      {isPasswordEditable && user && user.password && user.password.length > 7 && errorsFound && passwordError && <label className="errorMessage">Password must contain at least 1 uppercase, 1 lowercase, 1 numeric character and 1 special character( @, !, #, $, %, ., = ). </label>}
                    </Box>
                  </Grid>
                ) : null}
 {showAllFields && user && user.isSSO ? (
   <Grid item xs={12} sm={3}>
     <Box mx={1}>
       <label className="inputLabel">
         Sso UserId
         <span className="mandatory">*</span>
       </label>
       <TextField
         fullWidth={false}
         disabled={!isAddUserScreen}
         autoComplete="off"
         variant="outlined"
         inputProps={{maxLength:150}}
        value={user && user.SSOUserId}
        onChange={this.handleSsoUserIdChange.bind(this)}
       />
       {errorsFound && ssoUserIdError && <label className="errorMessage">This Field is required.</label>}
     </Box>
   </Grid>
 ) : null}


                <Grid item xs={9} sm={9}>
                  <Box mx={1}>
                    <label className="inputLabel">
                      User Type
                      <span className="mandatory">*</span>
                    </label>
                    <NativeSelect
                      value={user && user.userTypeId}
                      variant="outlined"
                      onChange={this.handleUserTypeChange.bind(this)}
                      inputProps={{
                        name: "age",
                        id: "demo-simple-select-outlined"
                      }}
                    >
                      {userTypes && userTypes.map(type =>
                        <option value={type.UserTypeId} selected={type.UserTypeId === 4}>{type.UserType}</option>)}
                    </NativeSelect>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Box mx={1}>
                    <div className="controls">
                      <Link to={`/${clientKey}/settings`}>
                        <Button variant="contained" className="btns cancel">
                          Cancel
                        </Button>
                      </Link>
                      {!isSpinnerActive ?
                        <Button
                          variant="contained"
                          className="btns save"
                          onClick={this.handleSubmit.bind(this)}
                        >
                          {submitButtonText}
                        </Button> :
                        <Box display="inline" justifyContent="center" alignItems="center">
                          <CircularProgress color="primary" style={{ margin: "0px 40px", position: "absolute" }} />
                        </Box>
                      }
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </Grid>
        <AlertDialog
          open={isDialogActive}
          title={""}
          message={submitDialogueMessage}
          onConfirm={() => {
            this.setState({ open: false });
            this.props.history.push(`/${clientKey}/settings`);
          }}
        />
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  users: state.users
});

export default connect(mapStateToProps, {
  createUser, updateUserDetails
})(User);
