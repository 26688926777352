import React, { Component, Fragment } from 'react'
import { Route, Switch } from 'react-router-dom';
import TabView from './TabView';
import PromotionSummary from './PromotionSummary';
import Reconciliation from './Reconciliation';
import Outstanding from './Outstanding';
import ProfileMerge from './ProfileMerge';
import "./styles.scss";

class AuthRoute extends Component {
    isAllowed(claims, name) {
        let permissions = claims;
        let str = `${name && name.toLowerCase()}`;
        let isEnabled = permissions && permissions.includes(str);
        if (isEnabled) {
            return true
        }
        return false;
    }
    render() {
        const { component: Component, name, claims, clientKey, ...rest } = this.props;
        let isAccessable = this.isAllowed(claims, name);
        return (
            <Route exact={true} {...rest} render={(props) => (
                (isAccessable === true) ?
                    <Fragment>
                        <Component {...props} clientKey={clientKey} claims={claims} />
                    </Fragment>
                    : null
            )} />
        )
    }
}

class Settings extends Component {
    render() {
        const { claims, clientKey } = this.props;
        return (
            <div className="reportsWrapper">
                <Fragment>
                    <Switch>
                        <AuthRoute exact path='/:clientKey/reports' component={TabView} clientKey={clientKey} name={"reports_view"} claims={claims} />
                        <AuthRoute exact path='/:clientKey/reports/promotionsummary' component={PromotionSummary} clientKey={clientKey} name={"reports_promotionsummary_view"} claims={claims} />
                        <AuthRoute exact path='/:clientKey/reports/reconciliation' component={Reconciliation} clientKey={clientKey} name={"reports_reconciliation_view"} claims={claims} />
                        <AuthRoute exact path='/:clientKey/reports/outstanding' component={Outstanding} clientKey={clientKey} name={"reports_outstanding_view"} claims={claims} />
                        <AuthRoute exact path='/:clientKey/reports/profilemerge' component={ProfileMerge} clientKey={clientKey} name={"reports_mergeduser_view"} claims={claims} />
                    </Switch>
                </Fragment>
            </div>
        )
    }
}

export default Settings;