import React, { Fragment } from "react";
import {
  Paper,
  AppBar,
  Tabs,
  Tab,
  Box,
  Grid,
  TextField,
  MenuItem,
  Button,
  InputAdornment,
  OutlinedInput,
  CircularProgress,
} from "@material-ui/core";
import DatePicker from "react-datepicker";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.scss";
import Cookies from "universal-cookie";
const cookies = new Cookies(window.document.cookie);
const ReportFilter = ({
  fromDate,
  toDate,
  canView,
  canDownload,
  previewProgress,
  downloadProgress,
  handleToDate,
  handleFromDate,
  handlePreview,
  handleDownload,
  selectedYear,
  years,
  handleYear,
  portBU,
  officerID,
  officerName,
  location,
  status,
  promotionID,
  handleFilters,
  name,
  reportStatus,
  dateRangeText,
  dateRangeFrom,
  dateRangeTo,
}) => {
  //Prevent date edit from input field
  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  return (
    <Grid container id="report-filter" spacing={2}>
      <Grid item xs={12} md={12}>
        <Box pl={2} style={{ color: "#1c4b6b" }}>
          View/Download Reports
        </Box>
      </Grid>
      <Grid item xs={12} md={12}>
        <Box>
          {dateRangeText ? "" : <h4>Date Range</h4>}
        </Box>
      </Grid>
      {dateRangeFrom ? 
      "" : <Grid item xs={12} md={3}>
        <Box mx={1}>
          <label className="inputLabel">From</label>
          <DatePicker
            id="datePicker_"
            onChangeRaw={(e) => handleDateChangeRaw(e)}
            customInput={
              <OutlinedInput
                variant="outlined"
                className="full-width"
                endAdornment={
                  <InputAdornment position="end">
                    <DateRangeIcon />
                  </InputAdornment>
                }
              />
            }
            maxDate={new Date()}
            className="datePicdesign dateField"
            selected={fromDate}
            onChange={(fromDate) => handleFromDate(fromDate)}
          />
        </Box>
      </Grid>
      }
      {dateRangeTo ? "" : 
      <Grid item xs={12} md={3}>
        <Box mx={1}>
          <label className="inputLabel">To</label>
          <DatePicker
            id="datePicker_"
            onChangeRaw={(e) => handleDateChangeRaw(e)}
            customInput={
              <OutlinedInput
                variant="outlined"
                className="full-width"
                endAdornment={
                  <InputAdornment position="end">
                    <DateRangeIcon />
                  </InputAdornment>
                }
              />
            }
            maxDate={new Date()}
            className="datePicdesign dateField"
            selected={toDate}
            onChange={(toDate) => handleToDate(toDate)}
          />
        </Box>
      </Grid>
      }
      {name == "outstanding" && (
        <Grid item xs={12} md={3}>
          <Box
            mx={1}
            justifyContent="flex-end"
            alignItems="flex-end"
            alignContent="flex-end"
          >
            <label className="inputLabel">Port BU</label>
            <TextField
              fullWidth={true}
              width="200px"
              value={portBU}
              autoComplete="off"
              variant="outlined"
              onChange={(e) => {
                handleFilters("PortBu", e.target.value);
              }}
            ></TextField>
          </Box>
        </Grid>
      )}
      {name == "outstanding" && (
        <Grid item xs={12} md={3}>
          <Box
            mx={1}
            justifyContent="flex-end"
            alignItems="flex-end"
            alignContent="flex-end"
          >
            <label className="inputLabel">
            {cookies.get("mclient") === "acg" ? 'P-ID' : 'Officer Id' }
            </label>
            <TextField
              fullWidth={true}
              value={portBU}
              autoComplete="off"
              variant="outlined"
              onChange={(e) => {
                handleFilters("OfficeId", e.target.value);
              }}
            ></TextField>
          </Box>
        </Grid>
      )}
      {name == "outstanding" && (
        <Grid item xs={12} md={3}>
          <Box
            mx={1}
            justifyContent="flex-end"
            alignItems="flex-end"
            alignContent="flex-end"
          >
            <label className="inputLabel">
            {cookies.get("mclient") === "acg" ? 'Producer Name' : 'Officer Name' }
            </label>
            <TextField
              fullWidth={true}
              value={portBU}
              autoComplete="off"
              variant="outlined"
              onChange={(e) => {
                handleFilters("OfficeName", e.target.value);
              }}
            ></TextField>
          </Box>
        </Grid>
      )}
      {name == "outstanding" && (
        <Grid item xs={12} md={3}>
          <Box
            mx={1}
            justifyContent="flex-end"
            alignItems="flex-end"
            alignContent="flex-end"
          >
            <label className="inputLabel">Location</label>
            <TextField
              fullWidth={true}
              value={location}
              autoComplete="off"
              variant="outlined"
              onChange={(e) => {
                handleFilters("LocationCode", e.target.value);
              }}
            ></TextField>
          </Box>
        </Grid>
      )}
      {name == "outstanding" && (
        <Grid item xs={12} md={3}>
          <Box
            mx={1}
            justifyContent="flex-end"
            alignItems="flex-end"
            alignContent="flex-end"
          >
            <label className="inputLabel">Status</label>
            <TextField
              select
              fullWidth={true}
              value={status}
              autoComplete="off"
              variant="outlined"
              onChange={(e) => {
                handleFilters("RewardStatus", e.target.value);
              }}
            >
              {reportStatus &&
                reportStatus.map((obj) => (
                  <MenuItem value={obj.value}>{obj.label}</MenuItem>
                ))}
            </TextField>
          </Box>
        </Grid>
      )}

      {name == "outstanding" && (
        <Grid item xs={12} md={3}>
          <Box
            mx={1}
            justifyContent="flex-end"
            alignItems="flex-end"
            alignContent="flex-end"
          >
            <label className="inputLabel">Promotion Id</label>
            <TextField
              fullWidth={true}
              value={promotionID}
              autoComplete="off"
              variant="outlined"
              onChange={(e) => {
                handleFilters("PromotionID", e.target.value);
              }}
            ></TextField>
          </Box>
        </Grid>
      )}
      {years && (
        <Grid container item xs={12} md={6} justify="flex-end">
          <Box
            mx={1}
            justifyContent="flex-end"
            alignItems="flex-end"
            alignContent="flex-end"
            width="200px"
          >
            <label className="inputLabel">Financial Year</label>
            <TextField
              fullWidth={true}
              select
              value={selectedYear}
              autoComplete="off"
              variant="outlined"
              onChange={(event) => handleYear(event)}
            >
              {years ? (
                years.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))
              ) : (
                <Box
                  width="100px"
                  display="flex"
                  mt={1.875}
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress color="primary" />
                </Box>
              )}
            </TextField>
          </Box>
        </Grid>
      )}
      <Grid item xs={12} md={12}>
        <Box mx={1} display="flex" justifyContent="flex-start">
          {previewProgress ? (
            <Box
              width="100px"
              display="flex"
              mt={1.875}
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress color="primary" />
            </Box>
          ) : (
            <Link to="#" style={{ marginTop: "30px", marginRight: "25px" }}>
              <Button
                variant="outlined"
                color="primary"
                className="btns clearBtn"
                onClick={handlePreview}
              >
                Preview
              </Button>
            </Link>
          )}
          {downloadProgress ? (
            <Box
              width="100px"
              display="flex"
              mt={1.875}
              justifyContent="center"
              alignItems="center"
            >
              <CircularProgress color="primary" />
            </Box>
          ) : (
            <div style={{ marginTop: "30px" }}>
              {canDownload && (
                <Link to="#" style={{ marginTop: "30px" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    className="btns save"
                    onClick={handleDownload}
                  >
                    Download
                  </Button>
                </Link>
              )}
            </div>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default ReportFilter;
