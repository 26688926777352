import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import "./styles.scss";
import UserManagementList from "../UserManagementList/index";
import NotificationManagementList from "../NotificationManagementList/index";
import Configuration from "../Configuration/index";
import { TabPanel, a11yProps } from "../../../components/TabPanel/index";
import { Grid, Button } from "@material-ui/core";
import { fetchUsersList } from "~/redux/actions/settings";
import { connect } from "react-redux";
import Notifier from "../../../components/Notifier";
import { getUserTypes } from "~/redux/helpers/user.js"

class TabView extends React.Component {
    state = {
        value: 0,
        notifierMessage: "",
        notifierOpen: false,
        userTypes: []
    };

    componentDidMount = async () => {
        const response = await getUserTypes({ RoleId: 1 });
        if (response.result && !response.ErrorMessage) {
          this.setState({ userTypes: response.result });
        } else {
          alert("Something went wrong.");
        }
    }

  handleNotifierClose = () => {
    this.setState({ notifierMessage: "", notifierOpen: false });
  }

  openNotifier = (message) => {
    this.setState({ notifierMessage: message, notifierOpen: true });
  }

  render() {
    const { value, notifierMessage, notifierOpen, userTypes } = this.state;
    const { users, fetchUsersList, user, claims, clientKey } = this.props;
    const canViewCongigurationTab = claims && claims.includes("configuration_view");

    const handleChange = (event, newValue) => {
      this.setState({ value: newValue });
    };
    return (
      <div>
        <Grid container justify="center" className="settingsWrapper">
          <Grid item xs={11}>
            <Box my={5}>
              <div position="static" className="tabHeader">
                <Grid container>
                  <Grid xs={7}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="simple tabs example"
                    >
                      <Tab label="User Management" {...a11yProps(0)} />
                      {canViewCongigurationTab && <Tab label="Configuration" {...a11yProps(1)} />}
                    </Tabs>
                  </Grid>
                  {value==0 &&<Grid xs={5}>
                    <div className="btnContainer">
                      <Button
                        variant="contained"
                        className="btns save"
                        onClick={() =>
                          this.props.history.push(`/${clientKey}/settings/addUser`)
                        }
                      >
                        Add User
                      </Button>
                    </div>
                  </Grid>}
                </Grid>
              </div>

              <div className="tabContent">
                <TabPanel value={value} index={0}>
                    <UserManagementList users={users} myDetails={user} clientKey={clientKey} fetchUsersList={fetchUsersList} userTypes={userTypes} openNotifier={(message) => this.openNotifier(message)} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Configuration renewalDate={fetchUsersList} />
                </TabPanel>
                {/*<TabPanel value={value} index={1}>
                  <NotificationManagementList users={users} fetchUsersList={fetchUsersList} userTypes={userTypes} openNotifier={(message) => this.openNotifier(message)} />
                      </TabPanel>*/}
              </div>
            </Box>
          </Grid>
        </Grid>
        <Notifier message={notifierMessage} open={notifierOpen} handleClose={this.handleNotifierClose} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  users: state.users,
  user: state.user
});

export default connect(mapStateToProps, {
  fetchUsersList
})(TabView);
