import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Container, Grid, Paper, Box, Button, CircularProgress, Table, TableHead, TableRow, TableBody, TableFooter, TablePagination, TableCell, TableSortLabel } from '@material-ui/core';
import { StyledTableHead, StyledTableRow, StyledTableCell, StyledTableFooter } from '~/components/StyledTable'
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import { Link } from "react-router-dom";
import FileUploadComponent from '~/components/FileUploadComponent'
import Notification from '~/components/Notification'
import { getSignedUrl, processUploadedFile, getFileUploadHistory, getFileStatus, downloadSampleFile } from '~/redux/helpers/upload';
import './styles.scss';
import Popper from '@material-ui/core/Popper';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';


class FileUpload extends Component {
    state = {
        FileName: null,
        File: null,
        FileRecordID: null,
        PreSignedURL: null,
        isLoading: false,
        error: null,
        fetchingUploadHistory: false,
        uploadHistory: [],
        rowsPerPage: 10,
        fileProgress: 0,
        totalCount: 0,
        page: 0,
        dropperEnabled: false,
        downloadProcessing:false,
        infoOpen:false,
        anchorEl:null,
    }
    componentDidMount() {
        this.fetchUploadHistory()
    }
    getUploadSignedUrl = (e) => {
        this.setState({
            error: null
        }, () => {
            if (this.uploadInput.files) {
                const FileName = this.uploadInput && this.uploadInput.files && this.uploadInput.files[0] && this.uploadInput.files[0].name;
                const extPattern = /\.[csv]+$/i;
                const expression = /^([a-zA-Z0-9-_ ]{2,60})_(promo|incremental)_([0-9]{1,2}[0-9]{1,2}[0-9]{2,4})\.(csv)+$/
                if(!extPattern.test(FileName && FileName.toLowerCase())) {
                    this.uploadInput.value = ''
                    this.setState({
                        FileName: null,
                        File: null,
                        error: 'File Extension is not supported (only .csv is supported.)',
                        isLoading: false,
                    })
                    return
                }
                if(!expression.test(FileName && FileName.toLowerCase())) {
                    this.uploadInput.value = ''
                    this.setState({
                        FileName: null,
                        File: null,
                        error: 'Invalid file name.',
                        isLoading: false,
                    })
                    return
                }
                this.setState({
                    File: this.uploadInput.files[0],
                    FileType: this.uploadInput.files[0].type,
                    isLoading: true,
                    error: null
                }, async () => {
                    const params = {
                        FileName: this.uploadInput.files[0].name,
                        UserName: this.props.user.info.Username || this.props.user.info.UserName
                    }
                    const response = await getSignedUrl(params)
                    if (response && response.error) {
                        this.uploadInput.value = ''
                        this.setState({
                            FileName: null,
                            File: null,
                            error: response.error,
                            isLoading: false,
                        })
                    } else {
                        this.setState({
                            PreSignedURL: response.PreSignedURL,
                            FileRecordID: response.FileRecordID,
                        })
                    }
                })
            }

        })
    }

    getFileProgress = () => {
        const { FileRecordID } = this.state;
        getFileStatus(FileRecordID).then(response => {
            let progress = 1;
            console.log("ProcessingStatus", response);
            if (response && response.ProcessingStatus) {
                progress = 8.34 * response.ProcessingStatus;
            } else {
                progress = 0;
            }
                
            const {clientKey}= this.props;
            this.setState(
                { fileProgress: progress },
                () => {
                    if (parseInt(progress) >= 100) {
                        //this.props.history.push(`/rewards/upload/status/${FileRecordID}`);
                        //setTimeout(this.props.history.push(`/rewards/upload/status/${FileRecordID}`), 10000)
                        setTimeout(function () {
                            this.props.history.push(`/${clientKey}/rewards/upload/status/${FileRecordID}`)
                        }.bind(this), 1000)
                    }
                }
            );
            console.log("progress log", progress);

            if (response && (response.ProcessingStatus == null || response.ProcessingStatus < 12)) {
                //check status in every 2 seconds
                setTimeout(this.getFileProgress, 1000)
            }
        })

    }

    onUploadComplete = () => {
        const { FileRecordID } = this.state
        this.setState({
            PreSignedURL: null
        }, () => {
            processUploadedFile(FileRecordID).then(response => {
                this.getFileProgress();
            })
                .catch(error => {
                    console.log("Erro in onUploadComplete", error)
                })
        })
    }

    handlePageChange = (event, page) => {
        const { SortColumn, SortOrder } = this.state;
		let newSortOrder = SortOrder === "asc" ? "ASC" : "DESC";
        this.setState({
            page
        }, () => this.fetchUploadHistory(SortColumn, newSortOrder))
    }

    handleRowsPerPageChange = (event) => {
        const { SortColumn, SortOrder } = this.state;
		let newSortOrder = SortOrder === "asc" ? "ASC" : "DESC";
        this.setState({
            page: 0,
            rowsPerPage: parseInt(event.target.value, 10)
        }, () => this.fetchUploadHistory(SortColumn, newSortOrder))
    }

    handleSorting(SortColumn) {
        const { SortOrder } = this.state;
        let newSortOrder = SortOrder === "asc" ? "desc" : "asc";
        this.setState({ SortColumn: SortColumn, SortOrder: newSortOrder, fetchingUploadHistory: true }, () => {
            this.fetchUploadHistory(SortColumn, newSortOrder === "asc" ? "ASC" : "DESC");
        });
    }

    fetchUploadHistory = (SortColumn, SortOrder) => {
        const { page, rowsPerPage } = this.state
        this.setState({
            fetchingUploadHistory: true
        }, () => {
            getFileUploadHistory(page + 1, rowsPerPage, SortColumn, SortOrder)
                .then(response => {
                    //console.log("reward history", response);
                    if (!response.error) {
                        this.setState({
                            fetchingUploadHistory: false,
                            uploadHistory: response.promotion,
                            totalCount: response.TotalCount
                        })
                    } else {
                        this.setState({
                            fetchingUploadHistory: false,
                            error: response.error
                        })
                    }
                })
                .catch(error => {
                    console.log(error)
                    this.setState({
                        fetchingUploadHistory: false
                    })
                })
        })
    }
    
    handleFileDownload =() => {
        this.setState({
            downloadProcessing:true
        }, ()=>{
            downloadSampleFile().then(response => {
                if(!response.error) {
                    window.location.href= response.data
                }
                this.setState({downloadProcessing:false});
            }).catch(error => {
                this.setState({downloadProcessing:false});
            })
            
        });
    }
    
  showInfo = (event) => {
      this.setState({
          infoOpen:!this.state.infoOpen,
          anchorEl:event.currentTarget
      });
  };

    hideInfo = () => {
        this.setState({
            infoOpen: false,
            anchorEl: null,
        })
    };

    render() {
        const { anchorEl, infoOpen, downloadProcessing, fileProgress, isLoading, PreSignedURL, File, fetchingUploadHistory, uploadHistory, rowsPerPage, page, error, dropperEnabled } = this.state;
        const {clientKey}= this.props;
        return (
            <Box pt={5} id="fileUploadCont">
                <Container>
                    <Link to={`/${clientKey}/rewards/`}>  <Button variant="contained" className="backBtn">Back</Button> </Link>
                    <Paper square className="mainContainer">
                        <Grid container>
                            <Grid item md={10}> <div className="headingText">  Upload Fulfillment file </div></Grid>
                            <Grid item md={2}>
                                {downloadProcessing ?
                                    <Box width="50px" display="flex" justifyContent="center" alignItems="flex-start"><CircularProgress color="primary" /></Box>
                                    :<Button size="small" style={{ float: "right" }} variant="contained" color="primary" onClick={()=> this.handleFileDownload()} >Download Sample File</Button>
                                }
                            </Grid>
                            <Grid item md={5}>
                                <input type="text" value={File ? File.name : ''} className="inputField" placeholder="File Name" />
                                <label className={`${dropperEnabled && 'dropper'} dragNdropWrap`} htmlFor="choose-file" id="dragContainer" onDrop={(e) => {e.preventDefault(); console.log(e.dataTransfer.files[0]); this.uploadInput.files = e.dataTransfer.files; this.getUploadSignedUrl(e);  this.setState({dropperEnabled: false})}} onDragLeave={() => {this.setState({dropperEnabled: false})}} onDragExit={() => this.setState({dropperEnabled: false})} onDragOver={(e) => {e.preventDefault(); this.setState({dropperEnabled: true})}}>
                                    <Grid container>
                                        <Grid item md={9}>
                                            <Box display="flex" alignItems="center" flexDirection="row" p={2} justifyContent="space-between" color="#fff">
                                                <Box>
                                                    <CloudUploadOutlinedIcon />
                                                </Box>
                                                <Box>Drag & Drop files</Box>
                                            </Box>
                                        </Grid>
                                        <Grid item md={3}>
                                            <div className="chooseFileDesign">  Choose File  </div>
                                        </Grid>
                                    </Grid>
                                </label>
                            </Grid>
                            <Grid container item md={7} direction="row" justify="flex-end" alignItems="flex-start">
                                {this.uploadIns()}
                            </Grid>
                            <Grid container>
                                <Grid item md={5} >
                                    <FileUploadComponent
                                        key='ex1'
                                        url={PreSignedURL}
                                        file={File}
                                        progressRenderer={(progress, hasError, cancelHandler) => {
                                            let message = 'Uploading ...';
                                            if (hasError) {
                                                message = 'Failed to upload';
                                            } else if (progress === 100) {
                                                message = 'Processing...';
                                            }
                                            if (isLoading) {
                                                if (progress <= 0) {
                                                    return <Box display="flex" p={10} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
                                                } else {
                                                    return (
                                                        <div className="_react_fileupload_progress_content">
                                                            <div className="loaderWrap">
                                                                <div className="loadingText">{message}</div>
                                                                <Grid container direction="row" alignItems="center">
                                                                    <Grid item md={10}>
                                                                        <div className="loaderBar">
                                                                            <div className="loaderPercent" style={{ width: `${fileProgress}%` }}></div>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item md={2}>
                                                                        <div className="percentText">{`${parseInt(fileProgress)}%`}</div>
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            }
                                            return ''
                                        }}
                                        onChange={e => console.log("Change", e)}
                                        onProgress={(e, request, progress) => { console.log('progress', e, request, progress); }}
                                        onComplete={this.onUploadComplete}
                                        onError={(e, request) => { console.log('error', e, request); }}
                                        onAbort={(e, request) => { console.log('abort', e, request); }}
                                        formRenderer={(onSubmit) => (
                                            <form className="_react_fileupload_form_content" ref="form" method="PUT" onSubmit={onSubmit}>
                                                <input type="file" accept=".csv" name="file" id="choose-file" ref={el => this.uploadInput = el} onChange={(this.getUploadSignedUrl)} style={{ display: 'none' }} />
                                            </form>
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                    {this.renderUploadHistory()}
                </Container>
                {error && this.renderSnackbar(error)}
            </Box>
        )
    }
    renderSnackbar = message => {
        return <Notification variant="error" message={message} />
    }
    renderUploadHistory = () => {
        const { fetchingUploadHistory, totalCount, uploadHistory, rowsPerPage, page, SortColumn, SortOrder } = this.state
        const {clientKey} = this.props;
        return (
            <Box my={5}>
                <Box my={2} className="list-title">Fulfillment History</Box>
                <Grid item >
                    <Table>
                        <StyledTableHead>
                            <TableRow>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={SortColumn === "File_Name"}
                                        direction={SortColumn === "File_Name" ? SortOrder : 'asc'}
                                        onClick={() => this.handleSorting("File_Name")}
                                    >
                                        File Name
                                         {SortColumn === "File_Name" ? (
                                            <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20, width: 1, }}>
                                                {SortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </span>
                                        ) : null}
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={SortColumn === "Status"}
                                        direction={SortColumn === "Status" ? SortOrder : 'asc'}
                                        onClick={() => this.handleSorting("Status")}
                                    >
                                        Status
                                         {SortColumn === "Status" ? (
                                            <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20, width: 1, }}>
                                                {SortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </span>
                                        ) : null}
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <TableSortLabel
                                        active={SortColumn === "DateTime"}
                                        direction={SortColumn === "DateTime" ? SortOrder : 'asc'}
                                        onClick={() => this.handleSorting("DateTime")}
                                    >
                                        Date/Time
                                         {SortColumn === "DateTime" ? (
                                            <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20, width: 1, }}>
                                                {SortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </span>
                                        ) : null}
                                    </TableSortLabel>
                                </StyledTableCell>
                                <StyledTableCell></StyledTableCell>
                            </TableRow>
                        </StyledTableHead>
                        <TableBody>
                            {fetchingUploadHistory ? (
                                <TableRow>
                                    <TableCell colSpan={4}>
                                        <Box width="100%" display="flex" p={5} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
                                    </TableCell>
                                </TableRow>
                            ) : (
                                    uploadHistory.map((item, index) => (
                                        <Fragment key={item.File_Name}>
                                            <StyledTableRow onClick={() => {
                                                if (item.Status.toLowerCase() == 'pending approval') {
                                                    this.props.history.push(`/${clientKey}/rewards/upload/status/${item.FileID}`)
                                                }
                                            }}>
                                                <StyledTableCell>{item.File_Name}</StyledTableCell>
                                                <StyledTableCell><span className={`file-status ${item.Status.toLowerCase()}`}>{item.Status}</span></StyledTableCell>
                                                <StyledTableCell>{moment(item.DateTime).utc().local().format('MM/DD/YYYY hh:mm A')}</StyledTableCell>
                                                <StyledTableCell></StyledTableCell>
                                            </StyledTableRow>
                                        </Fragment>
                                    ))
                                )}
                        </TableBody>
                        <StyledTableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 50, 100]}
                                    colSpan={10}
                                    count={totalCount || 0}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onChangePage={this.handlePageChange}
                                    onChangeRowsPerPage={this.handleRowsPerPageChange}
                                />
                            </TableRow>
                        </StyledTableFooter>
                    </Table>
                </Grid>
            </Box>
        )
    }

    uploadIns = ()=> {
        return(
            <ol className="upload-instruction" style={{width:"380px"}}>
                <h4>Instructions to Upload File:</h4>
                <li value="1">Download Sample Template and save on your local machine. </li> 
                <li>Copy-Paste the data in Sample Template File and apply corrections if any. </li> 
                <li>Save the File in CSV format with the valid naming convention and close it. </li> 
                <li>Do not open the saved CSV file in Excel format otherwise leading zero’s will be truncated from columns. </li> 
                <li>Upload the saved CSV File in the system. </li> 
            </ol>
        )
    }
}


export default connect(state => (
    { ...state.user, ...state.promotion }
))(FileUpload)