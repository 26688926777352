import React, { Fragment, Component } from "react";
import {
  Grid,
  Paper,
  CircularProgress,
  Table,
  TableRow,
  TableBody,
  TablePagination,
  TableCell,
  MenuItem,
  Box,
  TextField
} from "@material-ui/core";
import {
  StyledTableHead,
  StyledTableRow,
  StyledTableCell,
  StyledTableFooter
} from "~/components/StyledTable";
import ReportFilter from "~/components/ReportFilter";
import Notification from '~/components/Notification';
import { fetchYearList, fetchMergeReport } from '~/redux/actions/reports';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import moment from 'moment';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { ReportViewer} from './ReportViewer';

class ProfileMerge extends Component {
    state = {
        page: 0,
        rowsPerPage: 10,
        fromDate:new Date(moment().startOf('day').add(-1, 'days')),
        toDate:new Date(moment().endOf('day')),
        canView:true,
        canDownload:true,
        previewProgress:false,
        downloadProgress:false,
        items:[],
        order:"",
        orderBy:"",
        error:null,
        showReport:false,
        yearList: null,
        selectedYear:"",
    };

    componentDidMount() {
        this.props.closeNotifier();
        const {reports} = this.props;
        //this.getYearList();
        reports.error = null;
    }

    handleToDate =(toDate) =>{
        this.setState({toDate:toDate});
    }

    handleFromDate =(fromDate) =>{
        this.setState({fromDate:fromDate});
    }

    handlePageChange = (event, page) => {
        this.setState({
            page
        })
    }

    handleRowsPerPageChange = (event) => {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10),
            page:0
        })
    }

    getMergeReportData=()=>{
        const { fromDate, toDate,  selectedYear} = this.state;
        if(!moment(toDate).isSameOrAfter(fromDate, "day")) {
            this.setState({
                previewProgress: false,
                downloadProgress:false,
                showReport:false
            });
            this.props.openNotifier("To date should be same or greater than from date");
            return false;
        }

        this.props.dispatch(fetchMergeReport({ fromDate, toDate, selectedYear, IsDownload: false})).then((response) => {
            if (!response) {
                //error message
                this.setState({
                    previewProgress: false,
                })
                return false;
            }
            //console.log("length", this.props.reports.list.length);
            this.setState({
                previewProgress: false,
                downloadProgress:false,
                items:this.props.reports.list,
                showReport:this.props.reports.list&&this.props.reports.list.length? true :false,
                error:this.props.reports.list&&this.props.reports.list.length? false :true
            })
        })
    }

    handlePreview =(e) =>{
        this.setState(
            {previewProgress:true},
            () =>{
               //Get Data from API 
               this.getMergeReportData();
            }
        );
    }

    handleClose =() => {
        this.setState({showReport:false});
    }

    handleDownload =(e) =>{
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileName = "merged-user-report";
        const fileExtension = '.xlsx';
        const {items, fromDate, toDate, selectedYear, showReport} = this.state;
        if(!moment(toDate).isSameOrAfter(fromDate, "day")) {
            this.setState({
                previewProgress: false,
                downloadProgress:false,
                showReport:false
            });
            this.props.openNotifier("To date should be same or greater than from date");
            return false;
        }

        this.setState(
            {downloadProgress:true},
                () => {
                //download functionality
                // if(items.length>0){
                //     console.log("data exist");
                //     const ws = XLSX.utils.json_to_sheet(this.props.reports.list);
                //     const wb = { Sheets: { 'Outstanding Report': ws }, SheetNames: ['Outstanding Report'] };
                //     const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                //     const data = new Blob([excelBuffer], {type: fileType});
                //     FileSaver.saveAs(data, fileName + fileExtension);

                //     this.setState({downloadProgress:false});
                    
                // } else {
                    console.log("data not exist call api");
                   this.props.dispatch(fetchMergeReport({ fromDate, toDate, selectedYear, IsDownload: true})).then((response) => {
                        if (!response) {
                            //just retrn false in case of API though any error
                            this.setState({
                                downloadProgress: false,
                            })
                            return false;
                        }
                        if(this.props.reports.list && this.props.reports.list.length) {
                            const ws = XLSX.utils.json_to_sheet(this.props.reports.list);
                            const wb = { Sheets: { 'Merged User Report': ws }, SheetNames: ['Merged User Report'] };
                            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                            const data = new Blob([excelBuffer], {type: fileType});
                            FileSaver.saveAs(data, fileName + fileExtension);

                            this.setState({downloadProgress:false, error:false});
                        } else {
                            //error message
                            this.setState({
                                downloadProgress:false,
                                error:this.props.reports.list&&this.props.reports.list.length? false :true
                            });
                        }
                    })

                // }

            }
        );
    }

    handleRequestSort = (event, property) => {
        const {order, orderBy} = this.state;
        const isAsc = orderBy === property && order === 'asc';
        this.setState({
            order:(isAsc ? 'desc' : 'asc'),
            orderBy:property
        });
    }

    getYearList = async () => {
		await this.props.dispatch(fetchYearList()).then((response) => {
			//set state here on success
			if (!response)
				return false;
			this.setState({
				yearList: this.props.reports.yearList,
                selectedYear:this.props.reports.selectedYear
			})
		})
	}

    handleYear = (event) => {
        this.setState({
            selectedYear: event.target.value
        })
    }

  render() {
    const {yearList, selectedYear, order, orderBy, showReport, items, fromDate, toDate, canView, canDownload, previewProgress, downloadProgress, page, rowsPerPage } = this.state;
    const { reports, claims } = this.props;
    let isView = claims && claims.includes("reports_mergeduser_view");
    let isDownload = claims && claims.includes("reports_mergeduser_download");
    return (
        <Grid container spacing={2} mt={0} pt={0}>
            <Grid item md={12}>
                <Paper square display="flex" >
                    <Grid item md={12}>
                        <ReportFilter
                            fromDate={fromDate}
                            toDate={toDate}
                            previewProgress={previewProgress}
                            downloadProgress={downloadProgress}
                            canView={isView}
                            canDownload={isDownload}
                            handleToDate={(e) =>this.handleToDate(e)}
                            handleFromDate={(e) =>this.handleFromDate(e)}
                            handlePreview={(e) =>this.handlePreview(e)}
                            handleDownload={(e) =>this.handleDownload(e)}
                            handleYear   ={(event) => this.handleYear(event)}
                            selectedYear ={selectedYear}
                            years={null}
                        />
                    </Grid>
                </Paper>
            </Grid>
            <Grid item md={12}>
                <Paper square display="flex" >
                    {showReport && this.renderReportViewer(order, orderBy, page, rowsPerPage, downloadProgress, previewProgress, items, isDownload)}
                    {this.state.error && <Box display="flex" alignSelf="center" justifyContent="center" border="none">No Record Found </Box>}
                </Paper>
            </Grid>
            {reports.error && this.renderSnackbar(reports.error)}
        </Grid>
    );
    }
    renderSnackbar = message => {
        return <Notification variant="error" message={message} />
    }

    renderReportViewer = (order, orderBy, page, rowsPerPage, downloadProgress, previewProgress, items, canDownload) => {
        return <ReportViewer title="" 
        onConfirm={this.handleClose} 
        clickOutsideToClose={false} 
        order={order}
        orderBy={orderBy}
        canDownload={canDownload}
        onRequestSort={(event, property) => this.handleRequestSort(event, property)}
        page={page} 
        rowsPerPage={rowsPerPage} 
        handlePageChange={(event, page) =>this.handlePageChange(event, page)}
        handleRowsPerPageChange={(event)=>this.handleRowsPerPageChange(event)}
        handleDownload={(event) =>this.handleDownload(event)}
        previewProgress={previewProgress} 
        downloadProgress={downloadProgress} 
        items={items} 
        onClose={this.handleClose} />
    }
}

export default connect(state => ({ ...state.user, ...state.reports }))(ProfileMerge);