import React, {Fragment} from 'react';
import {
  Button,
  Modal,
  Grid,
  Paper,
  CircularProgress,
  Table,
  TableRow,
  TableBody,
  TablePagination,
  TableCell,
  TableSortLabel,
  Box,
  makeStyles,
  DialogTitle, Dialog, DialogActions, DialogContent, IconButton 
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import {
  StyledTableHead,
  StyledTableRow,
  StyledTableCell,
  StyledTableFooter,
  StyledTableFooterReport,
  StyledTableColCell
} from "~/components/StyledTable";
import moment from 'moment-timezone';
import './styles.scss';
import Cookies from "universal-cookie";
const cookies = new Cookies(window.document.cookie);

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

export function ReportViewer(props) {
    const {title, px, py, onConfirm, clickOutsideToClose, onClose, previewProgress, downloadProgress, items, page, rowsPerPage, handleDownload, handlePageChange, handleRowsPerPageChange,
    order, orderBy, onRequestSort, canDownload} = props;
    return (
        <Dialog
            fullScreen={true}
            fullWidth={true}
            open={true}
            onClose={() => (clickOutsideToClose || clickOutsideToClose === undefined) ? onConfirm() : null}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Box py={py || 6} px={px || 6}>
                {onClose ? (
                    <IconButton aria-label="close" className="closeButton" onClick={() => onClose()}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
                
                <DialogContent className="alert-dialog-message">
                    {previewProgress ? (
                    <Box display="flex" p={5} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
                    ):(
                    <Table id="report-list">
                        <StyledTableHead style={{fontWeight:'bold'}}>
                            <TableRow>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:'bold', fontSize:18}} colSpan={15}>Reconciliation Report</StyledTableColCell>
                            </TableRow>
                            <TableRow>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "RewardId" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "RewardId"}
                                      direction={orderBy === "RewardId" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "RewardId")}
                                    >
                                      Reward ID
                                      {orderBy === "RewardId" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "OEID" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "OEID"}
                                      direction={orderBy === "OEID" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "OEID")}
                                    >
                                      OEID
                                      {orderBy === "OEID" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "OfficerName" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "OfficerName"}
                                      direction={orderBy === "OfficerName" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "OfficerName")}
                                    >
                                      {cookies.get("mclient") === "acg" ? 'Producer Name' : 'Officer Name' }
                                      {orderBy === "OfficerName" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "PBU" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "PBU"}
                                      direction={orderBy === "PBU" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "PBU")}
                                    >
                                      Port BU
                                      {orderBy === "PBU" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "PortfolioName" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "PortfolioName"}
                                      direction={orderBy === "PortfolioName" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "PortfolioName")}
                                    >
                                      Portfolio Name
                                      {orderBy === "PortfolioName" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "PromotionId" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "PromotionId"}
                                      direction={orderBy === "PromotionId" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "PromotionId")}
                                    >
                                      Promotion ID
                                      {orderBy === "PromotionId" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "LOC" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "LOC"}
                                      direction={orderBy === "LOC" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "LOC")}
                                    >
                                      LOC
                                      {orderBy === "LOC" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "RewardAmount" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "RewardAmount"}
                                      direction={orderBy === "RewardAmount" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "RewardAmount")}
                                    >
                                      Reward Amount
                                      {orderBy === "RewardAmount" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "PaymentType" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "PaymentType"}
                                      direction={orderBy === "PaymentType" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "PaymentType")}
                                    >
                                      Payment Type
                                      {orderBy === "PaymentType" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "RewardSubmittedDate" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "RewardSubmittedDate"}
                                      direction={orderBy === "RewardSubmittedDate" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "RewardSubmittedDate")}
                                    >
                                      Reward Submitted Date
                                      {orderBy === "RewardSubmittedDate" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "RedeemedDate" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "RedeemedDate"}
                                      direction={orderBy === "RedeemedDate" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "RedeemedDate")}
                                    >
                                      Redeemed Date
                                      {orderBy === "RedeemedDate" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "RewardStatus" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "RewardStatus"}
                                      direction={orderBy === "RewardStatus" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "RewardStatus")}
                                    >
                                      Reward Status
                                      {orderBy === "RewardStatus" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "RewardReasonCode" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "RewardReasonCode"}
                                      direction={orderBy === "RewardReasonCode" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "RewardReasonCode")}
                                    >
                                      Return Reason Code
                                      {orderBy === "RewardReasonCode" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "RewardReasonDesc" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "RewardReasonDesc"}
                                      direction={orderBy === "RewardReasonDesc" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "RewardReasonDesc")}
                                    >
                                      Return Reason Description
                                      {orderBy === "RewardReasonDesc" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                                <StyledTableColCell style={{textAlign:"center", fontWeight:900}} sortDirection={orderBy === "FieldClearingReferenceNumber" ? order : false}>
                                    <TableSortLabel
                                      active={orderBy === "FieldClearingReferenceNumber"}
                                      direction={orderBy === "FieldClearingReferenceNumber" ? order : 'asc'}
                                      onClick={(event) => onRequestSort(event, "FieldClearingReferenceNumber")}
                                    >
                                      Field Clearing Reference Number
                                      {orderBy === "FieldClearingReferenceNumber" ? (
                                        <span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20,width: 1,}}>
                                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </span>
                                      ) : null}
                                    </TableSortLabel>
                                </StyledTableColCell>
                            </TableRow>
                        </StyledTableHead>
                        <TableBody>
                            {
                            (items || rowsPerPage > 0
                                ? stableSort(items, getSorting(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : items)
                            .map((item, index) => (
                                <Fragment key={index}>
                                    <StyledTableRow>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.RewardId}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.OEID}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.OfficerName}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.PBU}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.PortfolioName}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.PromotionId}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.LOC}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.RewardAmount}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.PaymentType}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.RewardSubmittedDate ? moment(item.RewardSubmittedDate).format("MM/DD/YYYY") : ""}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.RedeemedDate ? moment(item.RedeemedDate).format("MM/DD/YYYY") : ""}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.RewardStatus}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.RewardReasonCode}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.RewardReasonDesc}</StyledTableColCell>
                                        <StyledTableColCell style={{textAlign:"center"}}>{item.FieldClearingReferenceNumber}</StyledTableColCell>
                                    </StyledTableRow>
                                </Fragment>
                            ))
                            }
                        </TableBody>
                        <StyledTableFooterReport>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[10, 25, 50, { label: 'All', value: -1 }]}
                                    colSpan={15}
                                    count={items.length || 0}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'rows per page' },
                                        native: true,
                                    }}
                                    onChangePage={(event, page)=>handlePageChange(event, page)}
                                    onChangeRowsPerPage={(event) => handleRowsPerPageChange(event)}
                                />
                            </TableRow>
                            <TableRow>
                                <StyledTableCell colSpan={15}>
                                {downloadProgress ? (
                                    <Box width="100px" display="flex" mt={1.875} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
                                    ) : (
                                    <div>
                                        {canDownload && <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
                                            <Button variant="contained" color="primary" className="btns save" onClick={(event)=>handleDownload(event)} >Download</Button>
                                        </Box>}
                                    </div>
                                    )}
                                </StyledTableCell>
                            </TableRow>
                        </StyledTableFooterReport>
                    </Table>
                )}
                </DialogContent>
            </Box>
        </Dialog>
    );
}