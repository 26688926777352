import Cookies from 'universal-cookie'
import axios from 'axios'
import config from '~/config'
import { getAccessToken } from '~/redux/helpers/user'
import currency from 'currency.js';
import moment from "moment";

export const fetchPromotionList = ({ PromotionID, PromotionName, ExpirationDate, FulfillmentDate, Funds, Status, PageNo, PageSize, SortColumn, SortOrder}) => async (dispatch) => {
    try {
        const accessToken = await getAccessToken()
        console.log(accessToken);
        const response = await axios({
            url: `${config.base_url}/api/Promotions/getpromotion`,
            //url: ` https://yaykhniyk4.execute-api.us-east-2.amazonaws.com/dev/getpromotion`, 
			method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${accessToken}`,
            },
            data: JSON.stringify({
				PromotionID: PromotionID || 0,
				PromotionName: PromotionName || "", 
				ExpirationDate: ExpirationDate ? moment(ExpirationDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
				FulfillmentDate: FulfillmentDate ? moment(FulfillmentDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
				Funds: Funds === "" ? "" : currency(Funds).value,
				Status: Status || "",
				PageNo: PageNo || 1,
                PageSize: PageSize || 10,
                SortColumn: SortColumn || "",
                SortOrder : SortOrder || ""
            })
		})
		const responseBody = await response.data
        if (!responseBody.error) {
            console.log('Success');
			dispatch({
				type: 'PROMOTION_LIST_FETCH_SUCCESS',
                payload: responseBody.lstpromotion,
                totalCount: responseBody.TotalCount
			})
			return true
        }
        console.log('Error 1');
		dispatch({
			type: 'PROMOTION_LIST_FETCH_FAILED',
			payload: responseBody.error || "Oops! Something went wrong."
		})
		return false
    } catch (error) {
        console.log('Error 2:' + error.message);
		dispatch({
			type: 'PROMOTION_LIST_FETCH_FAILED',
			payload: error.message || "An error has occured."
		})
		return false
	}
}

export const fetchPromotionDetails = ({ promotionId }) => async (dispatch) => {
    try {
		const accessToken = await getAccessToken()
		let url = `${config.apiBase}/${promotionId}`
        const response = await axios({
            url: `${config.base_url}/api/Promotions/getpromotionbyid`,
            //url: `https://yaykhniyk4.execute-api.us-east-2.amazonaws.com/dev/getpromotionbyid`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			    'Authorization': `${accessToken}`,
            },
            data: JSON.stringify({ PromotionID: promotionId})
        })
       
		const responseBody = await response.data
		if(!responseBody.error){
			dispatch({
				type: 'PROMOTION_DETAIL_FETCH_SUCCESS',
				payload: responseBody
			})
			return true
		}
		dispatch({
			type: 'PROMOTION_DETAIL_FETCH_FAILED',
            payload: responseBody.ErrorMessage || "Oops! Something went wrong."
		})
		return false
	}catch(error){
		dispatch({
			type: 'PROMOTION_DETAIL_FETCH_FAILED',
			payload: error.message || "An error has occured."
		})
		return false
	}
}

export const createPromotion = () => async (dispatch) => {

    try {
        dispatch({
            type: 'CREATE_PROMOTION_START'
        })
        const accessToken = await getAccessToken()
        const response = await axios({
            url: `${config.base_url}/api/Promotions/createpromotion`,
            //url: ` https://yaykhniyk4.execute-api.us-east-2.amazonaws.com/dev/createpromotion`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${accessToken}`,
            },
          
        })
        const responseBody = await response.data
        if (!responseBody.error) {
            dispatch({
                type: 'CREATE_PROMOTION_SUCCESS',
                payload: responseBody
            })
        } else {
            dispatch({
                type: 'CREATE_PROMOTION_FAILED',
                payload: responseBody.ErrorMessage || "Oops! Something went wrong."
            })            
        }
    } catch (error) {
        dispatch({
            type: 'CREATE_PROMOTION_FAILED',
            payload: error.message || "An error has occured."
        })
    }
}

export const updatePromotion = ({ PromotionID, PromotionName, FulfillmentDate, ExpirationDate, Comments, Funds, RollUpValue, NewInvoiceValue, Status, UpdateBy }) => async (dispatch) => {

    try {
        dispatch({
            type: 'UPDATE_PROMOTION_START'
        })
        const accessToken = await getAccessToken()
        const response = await axios({
            url: `${config.base_url}/api/Promotions/updatepromotion`,
            //url: ` https://yaykhniyk4.execute-api.us-east-2.amazonaws.com/dev/updatepromotion`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${accessToken}`,
            },
            data: JSON.stringify({
                PromotionID: PromotionID,
                PromotionName: PromotionName,
                FulfillmentDate: FulfillmentDate ? moment(FulfillmentDate).format("YYYY-MM-DD HH:mm:ss") : "",
                ExpirationDate: ExpirationDate ? moment(ExpirationDate).format("YYYY-MM-DD HH:mm:ss") : "",
                Comments: Comments,
                Funds: currency(Funds).value,
                RollUpValue: currency(RollUpValue).value,
                NewInvoiceValue: currency(NewInvoiceValue).value,
                Status: Status || "",
                UpdatedBy: UpdateBy || ""
            })
        })
        const responseBody = await response.data
        if (!responseBody.error && !responseBody.errorMessage) {
            if(responseBody.result==2){
                dispatch({
                    type: 'UPDATE_PROMOTION_FAILED',
                    payload: "Promotion name already exist."
                })
            } else{
                dispatch({
                    type: 'UPDATE_PROMOTION_SUCCESS',
                    payload: responseBody
                })
            }
            
        } else {
            dispatch({
                type: 'UPDATE_PROMOTION_FAILED',
                payload: responseBody.error || responseBody.errorMessage || "Oops! Something went wrong."
            })            
        }
    } catch (error) {
        dispatch({
            type: 'UPDATE_PROMOTION_FAILED',
            payload: error.message || "An error has occured."
        })
    }
}

export const deletePromotion = ({PromotionID, UpdatedBy}) => async (dispatch) => {

    try {
        dispatch({
            type: 'DELETE_PROMOTION_START'
        })
        const accessToken = await getAccessToken()
        const response = await axios({
            url: `${config.base_url}/api/Promotions/updatepromotionstatus`,
            //url: ` https://yaykhniyk4.execute-api.us-east-2.amazonaws.com/dev/updatepromotionstatus`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${accessToken}`,
            },
            data: JSON.stringify({
                PromotionID: PromotionID,
                Status: 7,
                UpdatedBy: UpdatedBy ||''
            })
        })
        const responseBody = await response.data
        if (!responseBody.error && !responseBody.errorMessage) {
            dispatch({
                type: 'DELETE_PROMOTION_SUCCESS',
                payload: responseBody
            })
        } else {
            dispatch({
                type: 'DELETE_PROMOTION_FAILED',
                payload: responseBody.error || responseBody.errorMessage || "Oops! Something went wrong."
            })            
        }
    } catch (error) {
        dispatch({
            type: 'DELETE_PROMOTION_FAILED',
            payload: error.message || "An error has occured."
        })
    }
}
export const closePromotion = ({PromotionID, UpdatedBy}) => async (dispatch) => {

    try {
        dispatch({
            type: 'DELETE_PROMOTION_START'
        })
        const accessToken = await getAccessToken()
        const response = await axios({
            url: `${config.base_url}/api/Promotions/updatepromotionstatus`,
            //url: ` https://yaykhniyk4.execute-api.us-east-2.amazonaws.com/dev/updatepromotionstatus`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${accessToken}`,
            },
            data: JSON.stringify({
                PromotionID: PromotionID,
                Status: 6,
                UpdatedBy: UpdatedBy ||''
            })
        })
        const responseBody = await response.data
        if (!responseBody.error && !responseBody.errorMessage) {
            dispatch({
                type: 'DELETE_PROMOTION_SUCCESS',
                payload: responseBody
            })
        } else {
            dispatch({
                type: 'DELETE_PROMOTION_FAILED',
                payload: responseBody.error || responseBody.errorMessage || "Oops! Something went wrong."
            })            
        }
    } catch (error) {
        dispatch({
            type: 'DELETE_PROMOTION_FAILED',
            payload: error.message || "An error has occured."
        })
    }
}

export const fetchPromotionRewards = ({PromotionID}) => async (dispatch) => {
    try {
        const accessToken = await getAccessToken()
        const response = await axios({
            url: `${config.base_url}/api/Promotion/PromotionBar`,
            //url: `https://gije78mqff.execute-api.us-east-1.amazonaws.com/develop/PromotionBar`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${accessToken}`,
            },
            data: JSON.stringify({
                lPromotionId: PromotionID || 0,
            })
        })

        const responseBody = await response.data;
        console.log("promotion rewards", responseBody);
        if (!responseBody.error) {
            dispatch({
                type: 'PROMOTION_REWARDS_FETCH_SUCCESS',
                payload: responseBody.Data,
            })
            return true
        }
        dispatch({
            type: 'PROMOTION_REWARDS_FETCH_FAILED',
            payload: responseBody.error || "Oops! Something went wrong."
        })
        return false
    } catch (error) {
        dispatch({
            type: 'PROMOTION_REWARDS_FETCH_FAILED',
            payload: error.message || "An error has occured."
        })
        return false
    }
}

export const fetchFundsOverview = () => async (dispatch) => {
    try {
        const accessToken = await getAccessToken()
        const response = await axios({
            url: `${config.base_url}/api/Promotion/PromotionFundOverView`,
            //url:`https://gije78mqff.execute-api.us-east-1.amazonaws.com/develop/PromotionFundOverView`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${accessToken}`,
            },
        })
        const responseBody = await response.data;
        console.log("funds overview response", responseBody.Data);
        if (!responseBody.error) {
            dispatch({
                type: 'FUNDS_OVERVIEW_FETCH_SUCCESS',
                payload: responseBody.Data
            })
            return true
        }
        dispatch({
            type: 'FUNDS_OVERVIEW_FETCH_FAILED',
            payload: responseBody.error || "Oops! Something went wrong."
        })
        return false
    } catch (error) {
        dispatch({
            type: 'FUNDS_OVERVIEW_FETCH_FAILED',
            payload: error.message || "An error has occured."
        })
        return false
    }
}

export const fetchPromotionDetailOverview = ({ PromotionID }) => async (dispatch) => {
 
	try{
		const accessToken = await getAccessToken()
		let url = `${config.apiBase}/${PromotionID}`
        const response = await axios({
            url: `${config.base_url}/api/Promotion/PromotionDetailOverview`,
            //url: `https://gije78mqff.execute-api.us-east-1.amazonaws.com/develop/PromotionDetailOverview`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			    'Authorization': `${accessToken}`,
            },
            data: JSON.stringify({ lpromotionId: PromotionID})
        })
       
		const responseBody = await response.data
		if(!responseBody.error){
			dispatch({
				type: 'PROMOTION_DETAIL_OVERVIEW_FETCH_SUCCESS',
				payload: responseBody.Data[0]
			})
			return true
		}
		dispatch({
			type: 'PROMOTION_DETAIL_OVERVIEW_FETCH_FAILED',
            payload: responseBody.ErrorMessage || "Oops! Something went wrong."
		})
		return false
	}catch(error){
		dispatch({
			type: 'PROMOTION_DETAIL_OVERVIEW_FETCH_FAILED',
			payload: error.message || "An error has occured."
		})
		return false
	}
}

export const fetchStatusList = () => async (dispatch) => {
    try {
        const accessToken = await getAccessToken()
        const response = await axios({
            url: `${config.base_url}/api/Promotion/PromotionStatus`,
            //url: `https://k4d3lt87ha.execute-api.us-east-2.amazonaws.com/dev/PromotionStatus`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${accessToken}`,
            },
        })
        const responseBody = await response.data;
        if (!responseBody.error) {
            dispatch({
                type: 'PROMOTION_STATUS_LIST_FETCH_SUCCESS',
                payload: responseBody.Data,
            })
            return responseBody.Data
        }
        dispatch({
            type: 'PROMOTION_STATUS_LIST_FETCH_FAILED',
            payload: responseBody.error || "Oops! Something went wrong."
        })
        return false
    } catch (error) {
        dispatch({
            type: 'PROMOTION_STATUS_LIST_FETCH_FAILED',
            payload: error.message || "An error has occured."
        })
        return false
    }
}