import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react'
import ReactDOM from 'react-dom'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import App from '~/App';
import { Provider } from 'react-redux'
import store from '~/redux';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import ErrorPage404 from "~/views/misc/404error.js";
import Wrapper from './Wrapper';

const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#1b4a6b'
		}
	},
	typography: {
		fontSize: 14,
	},
	overrides: {
		MuiInputBase: {
			root: {
				fontSize: '14px'
			}
		},
		MuiTextField: {
			root: {
				'&:focus': {
					outline: 'none'
				}
			}
		},
		MuiOutlinedInput: {
			root: {
				'&$focused $notchedOutline': {
					borderWidth: 1,
				},
			}
		},
		MuiButton: {
			root: {
				borderRadius: 0,
				padding: '.5rem 2rem',
				textTransform: 'capitalize'
			},
		},
		MuiDialog: {
			paperScrollPaper: {
				borderRadius: 0,
				// padding: '3.125rem 2.75rem'
			}
		},
		MuiDialogTitle: {
			root: {
				padding: 0
			}
		},
		MuiSvgIcon: {
			root: {
				cursor: 'pointer'
			}
		},
		PrivateNotchedOutline: {
			root: {
				borderRadius: 0,
			}
		}
	}
})

ReactDOM.render((
	<MuiThemeProvider theme={theme}>
		<Provider store={store}>
            <BrowserRouter>
			<Wrapper />
                {/* <Switch>
                    <Route path="/:clientKey" component={App} />
                    <Route exact path="*">
                        <ErrorPage404 />
                    </Route>
                </Switch> */}
            </BrowserRouter>
		</Provider>
	</MuiThemeProvider>
), document.getElementById('root'))
