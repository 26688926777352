import React, { Component, Fragment } from 'react'
import { MenuItem, TextField, Icon, InputAdornment, OutlinedInput, Grid, Paper, Box, Button, Collapse, CircularProgress, Table, TableHead, TableRow, TableBody, TableFooter, TablePagination, TableCell, TableSortLabel } from '@material-ui/core'
import { StyledTableHead, StyledTableRow, StyledTableCell, StyledTableFooter } from '~/components/StyledTable'
import FilterListIcon from '@material-ui/icons/FilterList';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { Link } from "react-router-dom";
import { connect } from 'react-redux'
import FundOverviewRewards from '~/components/FundOverviewRewards'
import { fetchRewardTypeList, fetchStatusList, fetchRewardsList, removeReward } from '~/redux/actions/rewards';
import { fetchRewardsOverviewBarData, fetchRewardProgressData, reinitiatePayment, GetPaymentMethods } from "~/redux/helpers/rewards";
import DatePicker from "react-datepicker";
import currency from 'currency.js';
import './styles.scss'
import rewards from '~/redux/reducers/rewards';
import Stepper from '~/components/Steppers';
import CurrencyInput from '~/components/CurrencyInput';
import moment from 'moment';
import { ConfirmDialog, AlertDialog } from '../../../components/Dialogs';
import Notification from '~/components/Notification';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteTwoToneIcon from '@material-ui/icons/DeleteTwoTone';
import Avatar from '@material-ui/core/Avatar';
import Cookies from "universal-cookie";
const cookies = new Cookies(window.document.cookie);

const promotionStatusList = [{
	label: 'Initialized',
	value: 'INITIALIZED'
}, {
	label: 'Draft',
	value: 'DRAFT'
}, {
	label: 'Open',
	value: 'OPEN'
}, {
	label: 'In Progress',
	value: 'IN PROGRESS'
}, {
	label: 'Closed',
	value: 'CLOSED'
}, {
	label: 'Expired',
	value: 'EXPIRED'
}]

class RewardsListView extends Component {
	DateError = false;
	RewardID = null;
	constructor(props) {
		super(props);
		this.state = {
			isPaymentReinitiated: false,
			isLoading: true,
			fetchingList: true,
			fetchingDetail: false,
			expandedItem: null,
			showFilter: false,
			page: 0,
			rowsPerPage: 10,
			filterOpen: false,
			RewardID: null,
			PortBu: null,
			PortfolioName: null,
			LocationCode: null,
			OfficeId: null,
			OfficeName: null,
			Rewards: "",
			RewardType: "",
			RewardStatus: "",
			CreatedDate: null,
			ExpirationDate: null,
			PromotionID: null,
			rewardTypeList: null,
			statusList: null,
			overviewBarData: [],
			rewardProgressData: [],
			showConfirmRemoveDialog: false,
			removeProgress: false,
			removeRewardId: null,
			alertMessage: null,
			dialogOpen: false,
			SortColumn: "",
			SortOrder: "",
			showPendingModal: false,
			PaymentMethod: "",
			AlternateReward: "",
			PaymentMethods: []
		}
	}

	hideAlertMessage = () => {
		console.log("hideAlertMessage");
		this.setState({
			alertMessage: null,
		})
	}
	hidePendingModal = () => {
		this.setState({
			showPendingModal: false
		})
	}

	handleDeleteReward = (e, id) => {
		e.stopPropagation();
		this.setState({
			showConfirmRemoveDialog: true,
			removeRewardId: id,
		})
	}

	onConfirmDelete = () => {
		const { removeRewardId } = this.state;
		this.setState({
			showConfirmRemoveDialog: false,
			removeRewardId: null
		}, () => {
			const { info } = this.props.user;
			this.props.dispatch(removeReward({ rewardId: removeRewardId, username: info.UserName })).then((response) => {
				//set state here on success
				if (!response)
					return false;

				//fetch fresh reward overview
				this.getRewardsOverviewBarData();

				this.setState({
					alertMessage: 'Your Reward was successfully deleted!',
				})
			})
		})
	}

	onCancelDelete = () => {
		this.setState({
			showConfirmRemoveDialog: false,
			removeRewardId: null
		})
	}

	getRewardTypeList = () => {
		this.props.dispatch(fetchRewardTypeList()).then((response) => {
			//set state here on success
			if (!response)
				return false;
			this.setState({
				rewardTypeList: response
			})
		})
	}

	getStatusList = () => {
		this.props.dispatch(fetchStatusList()).then((response) => {
			//set state here on success
			if (!response)
				return false;
			this.setState({
				statusList: response
			})
		})
	}

	getRewardsOverviewBarData() {
		this.props.dispatch(fetchRewardsOverviewBarData()).then((response) => {
			if (response && response.data && !response.error) {
				this.setState({ overviewBarData: response.data });
			} else {
				alert(response.error || "Something went wrong.");
			}
		}).catch(err => {
			alert(err || "Something went wrong.")
		})
	}

	getRewardProgressData(lRewardID, lRewardType) {
		this.props.dispatch(fetchRewardProgressData({ lRewardID, lRewardType })).then((response) => {
			this.setState({ fetchingDetail: false });
			if (response && response.data && !response.error) {
				this.setState({ rewardProgressData: response.data });
			} else {
				alert(response && response.error || "Something went wrong.");
			}
		}).catch(err => {
			this.setState({ fetchingDetail: false });
			alert(err || "Something went wrong.")
		})
	}

	getPaymentMethods() {
		GetPaymentMethods().then((response) => {
			this.setState({ PaymentMethods: response.Data });
		})
	}

	componentDidMount() {
		this.updateRewardsList();
		this.getPaymentMethods();
		this.getStatusList();
		this.getRewardTypeList();
		this.getRewardsOverviewBarData();
	}



	toggleListItem = (index, RewardID, RewardType) => {
		//if(RewardType && RewardType.toLowerCase()=='ss')
		//return false;
		let { expandedItem } = this.state
		if (expandedItem === index) {
			expandedItem = null
		} else {
			expandedItem = index
		}
		this.setState({
			fetchingDetail: true,
			expandedItem
		}, () => {
			this.getRewardProgressData(RewardID, RewardType);
		})
	}

	filterCliCkFun = () => {
		this.setState({
			filterOpen: !this.state.filterOpen
		})
	}

	clearFilter = () => {
		this.DateError = false;
		this.setState({
			RewardID: '',
			PortBu: '',
			PortfolioName: '',
			LocationCode: '',
			OfficeId: '',
			OfficeName: '',
			Rewards: '',
			RewardType: '',
			RewardStatus: '',
			CreatedDate: null,
			ExpirationDate: null,
			PromotionID: '',
			Status: null,
			dialogOpen: false,
			expandedItem: null,
			PaymentMethod: 0,
			AlternateReward: ""
		}, () => {
			this.updateRewardsList();
			this.getStatusList();
			//this.getRewardTypeList();
			this.getRewardsOverviewBarData();
		})

	}

	handlePageChange = (event, page) => {
		const { SortColumn, SortOrder } = this.state;
		let newSortOrder = SortOrder === "asc" ? "ASC" : "DESC";
		this.setState({
			page
		}, () => this.updateRewardsList(SortColumn, newSortOrder))
	}
	getRewardToReinitiate = () => {
		return this.RewardID;
	}

	setRewardToReinitiate = (RewardID) => {
		this.RewardID = RewardID;
		this.setState({ dialogOpen: true })
	}

	reInitiatePayment = () => {
		const { rewards } = this.props;
		let RewardID = this.getRewardToReinitiate();
		this.setState({ isPaymentReinitiated: true }, () => {
			reinitiatePayment(RewardID).then((response => {
				this.setState({ isPaymentReinitiated: false });
				console.log(response)
				if (response && response.data && response.data.Data) {
					switch (response.data.Data.statusCode) {
						case 0:
							rewards && rewards.list.filter(reward => {
								if (reward && reward.RewardID == RewardID) {
									reward.RewardStatus = "Payment Initiated";
								}
							})
							break;

						case 1:
							this.setState({ showPendingModal: true }, () => {
								rewards && rewards.list.filter(reward => {
									if (reward && reward.RewardID == RewardID) {
										reward.RewardStatus = "Pending Enrollment";
									}
								});
							})
							break;

						case 2:
							rewards && rewards.list.filter(reward => {
								if (reward && reward.RewardID == RewardID) {
									reward.RewardStatus = "Payment Failed";
								}
							})
							break;
					}
					this.setState({ fetchingDetail: true }, () => {
						this.getRewardProgressData(RewardID);
						this.getRewardsOverviewBarData();
					});
				} else {
					this.setState({
						alertMessage: "Payment Re-initiate failed. Please try again."
					})
				}
			})).catch(error => {
				this.setState({ isPaymentReinitiated: false })
				alert(error || "Something went wrong.")
			})
		})
	}

	handleRowsPerPageChange = (event) => {
		const { SortColumn, SortOrder } = this.state;
		let newSortOrder = SortOrder === "asc" ? "ASC" : "DESC";
		this.setState({
			page: 0,
			rowsPerPage: parseInt(event.target.value, 10)
		}, () => this.updateRewardsList(SortColumn, newSortOrder))
	}


	handleSorting(SortColumn) {
		const { SortOrder } = this.state;
		let newSortOrder = SortOrder === "asc" ? "desc" : "asc";
		this.setState({ SortColumn: SortColumn, SortOrder: newSortOrder, fetchingList: true }, () => {
			this.updateRewardsList(SortColumn, newSortOrder === "asc" ? "ASC" : "DESC");
		});
	}

	updateRewardsList = (SortColumn, SortOrder) => {
		const { RewardID, PortBu, PortfolioName, LocationCode, OfficeId, OfficeName, Rewards, RewardType, RewardStatus, CreatedDate, ExpirationDate, PromotionID, page, rowsPerPage, PaymentMethod, AlternateReward } = this.state;
		this.DateError = (CreatedDate != null && ExpirationDate == null) || (ExpirationDate != null && CreatedDate == null) ? true : false;
		if (!this.DateError) {
			this.setState({
				fetchingList: true,
				expandedItem: null,
				rewardProgressData: []
			}, () => {
				this.props.dispatch(fetchRewardsList({ RewardID, PortBu, PortfolioName, LocationCode, OfficeId, OfficeName, Rewards, RewardType, RewardStatus, CreatedDate, ExpirationDate, PromotionID, PageNo: page + 1, PageSize: rowsPerPage, SortColumn: SortColumn || "", SortOrder: SortOrder || "", PaymentMethod, AlternateReward })).then(() => {
					this.setState({
						isLoading: false,
						fetchingList: false
					})
				})
			})
		}
	}

	render() {
		const { alertMessage, showConfirmRemoveDialog, filterOpen, overviewBarData, rewardProgressData, isPaymentReinitiated, dialogOpen, SortColumn, SortOrder, showPendingModal, AlternateReward, PaymentMethod } = this.state;
		const { rewardTypeList, statusList, isLoading, fetchingList, page, rowsPerPage, expandedItem, fetchingDetail, showFilter, RewardID, PortBu, PortfolioName, LocationCode, OfficeId, OfficeName, RewardCurrency, Rewards, RewardType, RewardStatus, CreatedDate, ExpirationDate, PromotionID, PaymentMethods } = this.state;
		const { user, rewards, permissions, clientKey } = this.props;
		let claims = permissions.minified;
		if (isLoading) {
			return <Box display="flex" p={10} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
		}
		return (
			<Grid container justify="center" id="rewards-list-view">
				<Grid item xs={11}>
					<Box my={5}>
						{/* <Box display="flex" justifyContent="flex-end" py={1}>
							<img className="filter-button" onClick={this.filterCliCkFun} src={require('~/assets/images/filter-icon.png')} alt="filter-icon" stl/>
						</Box> */}

						<Paper square display="flex">
							<FundOverviewRewards
								params={{
									title: overviewBarData.params && overviewBarData.params.title || "Rewards Overview",
								}}
								assignedFunds={{
									fundTitle: overviewBarData.assignedFunds && overviewBarData.assignedFunds.fundTitle || "Assigned Funds",
									total: overviewBarData.assignedFunds && overviewBarData.assignedFunds.total || 0,
									data: overviewBarData.assignedFunds && overviewBarData.assignedFunds.data || null,
								}}
								expiredFunds={{
									fundTitle: overviewBarData.expiredFunds && overviewBarData.expiredFunds.fundTitle || "Expired Funds",
								}}
							/>
						</Paper>
					</Box>
				</Grid>
				<Grid item xs={11}>
					<div className="list-header">
						<Grid item xs={3} md={3} lg={2}>
							<span className="trackText">Track</span>
						</Grid>
						<Grid item xs={6} md={6} lg={6}>
							<Box display="flex" justifyContent="flex-end" alignItems="right">
								{claims && claims.includes("rewards_uploadfulfilment") ? <Link to={`/${clientKey}/rewards/uploadsweepstake`} style={{ marginRight: "12px" }}> <Button size="small" variant="contained" color="primary" style={{ textTransform: 'capitalize' }}> Upload Alternate </Button> </Link> : null}
								{claims && claims.includes("rewards_uploadfulfilment") ? <Link to={`/${clientKey}/rewards/upload`} style={{ marginRight: "12px" }}> <Button size="small" variant="contained" color="primary" style={{ textTransform: 'capitalize' }}> Upload Fulfillment </Button> </Link> : null}
								<img className="filter-button" onClick={this.filterCliCkFun} src={require('~/assets/images/filter-icon.png')} alt="filter-icon" />
							</Box>
						</Grid>
					</div>
				</Grid>

				{<Grid item xs={11}>
					<Grid container={filterOpen} justify="space-between" hidden className="filterWrap">
						<Grid item xs={6} sm={3}>
							<Box mx={1}>
								<label className="inputLabel">Reward ID</label>
								<TextField
									fullWidth={true}
									autoComplete="off"
									variant="outlined"
									value={RewardID}
									onChange={event => this.setState({ RewardID: event.target.value })}
								/>
							</Box>
						</Grid>
						<Grid item xs={6} sm={3}>
							<Box mx={1}>
								<label className="inputLabel">Port BU</label>
								<TextField
									fullWidth={true}
									autoComplete="off"
									variant="outlined"
									value={PortBu}
									onChange={event => this.setState({ PortBu: event.target.value })}
									inputProps={{
										ref: el => this.PortBu = el
									}}
								/>
							</Box>
						</Grid>

						<Grid item xs={6} sm={3}>
							<Box mx={1}>
								<label className="inputLabel">Portfolio Name</label>
								<TextField
									fullWidth={true}
									autoComplete="off"
									variant="outlined"
									value={PortfolioName}
									onChange={event => this.setState({ PortfolioName: event.target.value })}
									inputProps={{
										ref: el => this.PortfolioName = el
									}}
								/>
							</Box>
						</Grid>

						<Grid item xs={6} sm={3}>
							<Box mx={1}>
								<label className="inputLabel">Location Code</label>
								<TextField
									fullWidth={true}
									autoComplete="off"
									variant="outlined"
									value={LocationCode}
									onChange={event => this.setState({ LocationCode: event.target.value })}
									inputProps={{
										ref: el => this.LocationCode = el
									}}
								/>
							</Box>
						</Grid>

						<Grid item xs={6} sm={3}>
							<Box mx={1}>
								<label className="inputLabel">
									{cookies.get("mclient") === "acg" ? 'P-ID' : 'Officer Id'}
								</label>
								<TextField
									fullWidth={true}
									autoComplete="off"
									variant="outlined"
									value={OfficeId}
									onChange={event => this.setState({ OfficeId: event.target.value })}
									inputProps={{
										ref: el => this.OfficeId = el
									}}
								/>
							</Box>
						</Grid>

						<Grid item xs={6} sm={3}>
							<Box mx={1}>
								<label className="inputLabel">
									{cookies.get("mclient") === "acg" ? 'Producer Name' : 'Officer Name'}
								</label>
								<TextField
									fullWidth={true}
									autoComplete="off"
									variant="outlined"
									value={OfficeName}
									onChange={event => this.setState({ OfficeName: event.target.value })}
									inputProps={{
										ref: el => this.OfficeName = el
									}}
								/>
							</Box>
						</Grid>

						<Grid item xs={6} sm={3}>
							<Box mx={1}>
								<label className="inputLabel">Reward Type</label>
								<TextField
									fullWidth={true}
									select
									value={RewardType}
									autoComplete="off"
									variant="outlined"
									onChange={event => this.setState({ RewardType: event.target.value })}
									inputProps={{
										ref: el => this.RewardType = el
									}}
								>
									{rewardTypeList ? rewardTypeList.map(option => (
										<MenuItem key={option.value} value={option.value}>
											{option.label}
										</MenuItem>
									)) :
										(
											<Box width="100px" display="flex" mt={1.875} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
										)
									}
								</TextField>
							</Box>
						</Grid>
						<Grid item xs={6} sm={3}>
							<Box mx={1}>
								<label className="inputLabel">Reward ($)</label>
								<CurrencyInput placeholder="" type="text"
									value={Rewards || ''}
									onChange={event => this.setState({ Rewards: event.target.value })}
								/>
							</Box>
						</Grid>

						<Grid item xs={6} sm={3}>
							<Box mx={1}>
								<label className="inputLabel">Status</label>
								<TextField
									fullWidth={true}
									select
									value={RewardStatus}
									autoComplete="off"
									variant="outlined"
									onChange={event => this.setState({ RewardStatus: event.target.value })}
									inputProps={{
										ref: el => this.RewardStatus = el
									}}
								>
									{statusList ? statusList.map(option => (
										<MenuItem key={option.value} value={option.value}>
											{option.label}
										</MenuItem>
									)) :
										(
											<Box width="100px" display="flex" mt={1.875} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
										)
									}
								</TextField>
							</Box>
						</Grid>

						<Grid item xs={6} sm={3}>
							<Box mx={1}>
								<label className="inputLabel">From Date</label>
								<DatePicker
									customInput={<OutlinedInput
										variant="outlined"
										className="full-width"
										endAdornment={<InputAdornment position="end"><DateRangeIcon /></InputAdornment>}
									/>}
									className="datePicdesign dateField"
									selected={CreatedDate}
									onChangeRaw={(e) => e.preventDefault()}
									onChange={CreatedDate => this.setState({ CreatedDate: new Date(moment(CreatedDate).startOf('day')) })}
								/>
							</Box>
							{this.DateError && <span className="errorMessage">Please specify the date range.</span>}
						</Grid>
						<Grid item xs={6} sm={3}>
							<Box mx={1}>
								<label className="inputLabel">To Date</label>
								<DatePicker
									customInput={<OutlinedInput
										variant="outlined"
										className="full-width"
										endAdornment={<InputAdornment position="end"><DateRangeIcon /></InputAdornment>}
									/>}
									onChangeRaw={(e) => e.preventDefault()}
									className="datePicdesign dateField"
									selected={ExpirationDate}
									onChange={ExpirationDate => this.setState({ ExpirationDate: new Date(moment(ExpirationDate).endOf('day')) })}
								/>
							</Box>
						</Grid>
						<Grid item xs={6} sm={3}>
							<Box mx={1}>
								<label className="inputLabel">Promotion ID</label>
								<TextField
									fullWidth={true}
									autoComplete="off"
									variant="outlined"
									value={PromotionID}
									onChange={event => this.setState({ PromotionID: event.target.value })}
									inputProps={{
										ref: el => this.funds = el
									}}
								/>
							</Box>
						</Grid>

						<Grid item xs={6} sm={3}>
							<Box mx={1}>
								<label className="inputLabel">Payment Method</label>
								<TextField
									fullWidth={true}
									select
									value={PaymentMethod}
									autoComplete="off"
									variant="outlined"
									onChange={event => this.setState({ PaymentMethod: event.target.value })}
									inputProps={{
										ref: el => this.PaymentMethod = el
									}}
								>
									{PaymentMethods && PaymentMethods.map(method =>
										<MenuItem key={2} value={method.ID}>
											{method.PaymentMethodName}
										</MenuItem>)}
									{/* <MenuItem key={3} value={3}>
											Zelle
										</MenuItem> */}
									{/* )) :
										(
											<Box width="100px" display="flex" mt={1.875} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
										)
									} */}
								</TextField>
							</Box>
						</Grid>

						<Grid item xs={6} sm={3}>
							<Box mx={1}>
								<label className="inputLabel">Alternate Reward</label>
								<TextField
									fullWidth={true}
									autoComplete="off"
									variant="outlined"
									value={AlternateReward}
									onChange={event => this.setState({ AlternateReward: event.target.value })}
									inputProps={{
										ref: el => this.AlternateReward = el
									}}
								/>
							</Box>
						</Grid>

						<Grid item xs={12} sm={6}>
							<Box mx={2} display="flex" justifyContent="flex-end" alignItems="right">
								<Link to="#" style={{ marginTop: '30px' }}> <Button onClick={this.clearFilter} variant="outlined" color="primary" className="clearBtn">Clear</Button> </Link>
								{fetchingList ? (
									<Box width="100px" display="flex" mt={1.875} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
								) : (
									<Link to="#" style={{ marginTop: '30px' }}> <Button onClick={() => this.updateRewardsList()} variant="contained" color="primary">Search</Button> </Link>
								)}
							</Box>
						</Grid>
					</Grid>
				</Grid>}

				<Grid item xs={11} id={"rewards-list-view"} style={{ overflowX: 'auto' }} className={"zui-wrapper"}>
					<div className={"zui-scroller"}>
						<Table stickyHeader aria-label="sticky table" className={"zui-table"}>
							<StyledTableHead>
								<TableRow>
									<StyledTableCell className={"zui-sticky-col"} sortDirection={SortColumn === "rewardid" ? SortOrder : false}>
										<TableSortLabel
											active={SortColumn === "rewardid"}
											direction={SortColumn === "rewardid" ? SortOrder : 'asc'}
											onClick={() => this.handleSorting("rewardid")}
										>
											Reward ID
											{SortColumn === "rewardid" ? (
												<span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20, width: 1, }}>
													{SortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
												</span>
											) : null}
										</TableSortLabel>
									</StyledTableCell>

									<StyledTableCell className={"zui-sticky-col"} sortDirection={SortColumn === "portbu" ? SortOrder : false} style={{ left: '119px' }} >
										<TableSortLabel
											active={SortColumn === "portbu"}
											direction={SortColumn === "portbu" ? SortOrder : 'asc'}
											onClick={() => this.handleSorting("portbu")}
										>
											Port BU
											{SortColumn === "portbu" ? (
												<span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20, width: 1, }}>
													{SortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
												</span>
											) : null}
										</TableSortLabel>
									</StyledTableCell>

									<StyledTableCell className={"zui-sticky-col"} sortDirection={SortColumn === "portfolioname" ? SortOrder : false} style={{ left: '222px' }}>
										<TableSortLabel
											active={SortColumn === "portfolioname"}
											direction={SortColumn === "portfolioname" ? SortOrder : 'asc'}
											onClick={() => this.handleSorting("portfolioname")}
										>
											Portfolio Name
											{SortColumn === "portfolioname" ? (
												<span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20, width: 1, }}>
													{SortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
												</span>
											) : null}
										</TableSortLabel>
									</StyledTableCell>

									<StyledTableCell>
										<TableSortLabel
											active={SortColumn === "officeid"}
											direction={SortColumn === "officeid" ? SortOrder : 'asc'}
											onClick={() => this.handleSorting("officeid")}
										>
											{cookies.get("mclient") === "acg" ? 'P-ID' : 'Officer ID'}
											{SortColumn === "officeid" ? (
												<span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20, width: 1, }}>
													{SortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
												</span>
											) : null}
										</TableSortLabel>
									</StyledTableCell>


									<StyledTableCell>
										<TableSortLabel
											active={SortColumn === "locationcode"}
											direction={SortColumn === "locationcode" ? SortOrder : 'asc'}
											onClick={() => this.handleSorting("locationcode")}
										>
											Location Code
											{SortColumn === "locationcode" ? (
												<span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20, width: 1, }}>
													{SortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
												</span>
											) : null}
										</TableSortLabel>
									</StyledTableCell>
									<StyledTableCell>
										<TableSortLabel
											active={SortColumn === "officename"}
											direction={SortColumn === "officename" ? SortOrder : 'asc'}
											onClick={() => this.handleSorting("officename")}
										>
											{cookies.get("mclient") === "acg" ? 'Producer Name' : 'Officer Name'}
											{SortColumn === "officename" ? (
												<span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20, width: 1, }}>
													{SortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
												</span>
											) : null}
										</TableSortLabel>
									</StyledTableCell>
									<StyledTableCell>
										<TableSortLabel
											active={SortColumn === "rewards"}
											direction={SortColumn === "rewards" ? SortOrder : 'asc'}
											onClick={() => this.handleSorting("rewards")}
										>
											Reward ($)
											{SortColumn === "rewards" ? (
												<span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20, width: 1, }}>
													{SortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
												</span>
											) : null}
										</TableSortLabel>
									</StyledTableCell>
									<StyledTableCell>
										<TableSortLabel
											active={SortColumn === "rewardtype"}
											direction={SortColumn === "rewardtype" ? SortOrder : 'asc'}
											onClick={() => this.handleSorting("rewardtype")}
										>
											Reward Type
											{SortColumn === "rewardtype" ? (
												<span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20, width: 1, }}>
													{SortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
												</span>
											) : null}
										</TableSortLabel>
									</StyledTableCell>
									<StyledTableCell>
										<TableSortLabel
											active={SortColumn === "rewardstatus"}
											direction={SortColumn === "rewardstatus" ? SortOrder : 'asc'}
											onClick={() => this.handleSorting("rewardstatus")}
										>
											Status
											{SortColumn === "rewardstatus" ? (
												<span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20, width: 1, }}>
													{SortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
												</span>
											) : null}
										</TableSortLabel>
									</StyledTableCell>
									<StyledTableCell>
										<TableSortLabel
											active={SortColumn === "CreatedDate"}
											direction={SortColumn === "CreatedDate" ? SortOrder : 'asc'}
											onClick={() => this.handleSorting("CreatedDate")}
										>
											Date
											{SortColumn === "CreatedDate" ? (
												<span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20, width: 1, }}>
													{SortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
												</span>
											) : null}
										</TableSortLabel>
									</StyledTableCell>
									<StyledTableCell>
										<TableSortLabel
											active={SortColumn === "PromotionID"}
											direction={SortColumn === "PromotionID" ? SortOrder : 'asc'}
											onClick={() => this.handleSorting("PromotionID")}
										>
											Promotion ID
											{SortColumn === "PromotionID" ? (
												<span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20, width: 1, }}>
													{SortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
												</span>
											) : null}
										</TableSortLabel>
									</StyledTableCell>
									<StyledTableCell>
										<TableSortLabel
											active={SortColumn === "paymentmethod"}
											direction={SortColumn === "paymentmethod" ? SortOrder : 'asc'}
											onClick={() => this.handleSorting("paymentmethod")}
										>
											Payment Method
											{SortColumn === "paymentmethod" ? (
												<span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20, width: 1, }}>
													{SortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
												</span>
											) : null}
										</TableSortLabel>
									</StyledTableCell>
									<StyledTableCell>
										<TableSortLabel
											active={SortColumn === "alternatereward"}
											direction={SortColumn === "alternatereward" ? SortOrder : 'asc'}
											onClick={() => this.handleSorting("alternatereward")}
										>
											Alternate Reward
											{SortColumn === "alternatereward" ? (
												<span style={{ border: 0, clip: 'rect(0 0 0 0)', height: 1, margin: -1, overflow: 'hidden', padding: 0, position: 'absolute', top: 20, width: 1, }}>
													{SortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
												</span>
											) : null}
										</TableSortLabel>
									</StyledTableCell>
									{cookies.get("mclient") === "hd" && !(claims && claims.includes("rewards_uploadfulfilment")) ?
										'' : <StyledTableCell>Action</StyledTableCell>
									}
								</TableRow>
							</StyledTableHead>
							<TableBody>
								{fetchingList ? (
									<TableRow>
										<TableCell colSpan={14}>
											<Box display="flex" p={5} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
										</TableCell>
									</TableRow>
								) : (
									rewards.list && rewards.list.map((item, index) => (
										<Fragment key={item.RewardID}>
											<StyledTableRow onClick={e => this.toggleListItem(index, item.RewardID, item.RewardType)}>
												<StyledTableCell className={"zui-sticky-col"}>{item.RewardName}</StyledTableCell>
												<StyledTableCell className={"zui-sticky-col"} style={{ left: '118px' }}>{item.PortBu}</StyledTableCell>
												<StyledTableCell className={"zui-sticky-col"} style={{ left: '221px' }}>{item.PortfolioName}</StyledTableCell>
												<StyledTableCell>{item.OfficeId}</StyledTableCell>
												<StyledTableCell>{item.LocationCode}</StyledTableCell>
												<StyledTableCell>{item.OfficeName}</StyledTableCell>
												<StyledTableCell> {currency(item.Rewards, { formatWithSymbol: true }).format()}</StyledTableCell>
												<StyledTableCell><Avatar className={item.RewardType && item.RewardType.toLowerCase() == 'rw' ? "avatarGrey" : ""}><span style={{ fontSize: "14px" }} >{item.RewardType ? item.RewardType : ''}</span></Avatar></StyledTableCell>
												<StyledTableCell>{item.RewardStatus}</StyledTableCell>
												<StyledTableCell>{item.CreatedDate ? moment.utc(item.CreatedDate).local().format("MM/DD/YYYY") : ""}</StyledTableCell>
												<StyledTableCell>{item.PromotionID}</StyledTableCell>
												<StyledTableCell>{item.PaymentMethodType}</StyledTableCell>
												<StyledTableCell>{item.AlternateReward}</StyledTableCell>
												{cookies.get("mclient") === "hd" ?
													(!(claims && claims.includes("rewards_uploadfulfilment")) ? "" : (item.RewardStatus === "Reward Available" ? (<StyledTableCell><DeleteIcon style={{ float: "right" }} variant="contained" color="primary" onClick={(event) => this.handleDeleteReward(event, item.RewardID)} >Remove</DeleteIcon></StyledTableCell>) :
														(<StyledTableCell><DeleteTwoToneIcon style={{ float: "right" }} variant="contained" color="primary" >Remove</DeleteTwoToneIcon></StyledTableCell>))) :
													<StyledTableCell>
														{item.RewardStatus === "Reward Available" ? <DeleteIcon style={{ float: "right" }} variant="contained" color="primary" onClick={(event) => this.handleDeleteReward(event, item.RewardID)} >Remove</DeleteIcon> :
															<DeleteTwoToneIcon style={{ float: "right" }} variant="contained" color="primary" >Remove</DeleteTwoToneIcon>}
													</StyledTableCell>
												}
											</StyledTableRow>
											<TableRow>
												<TableCell colSpan={14} className="no-padding">
													<Collapse hidden={!(expandedItem === index)} in={expandedItem === index}>
														<div className="expanded-view">
															{fetchingDetail ? (
																<Box display="flex" p={10} justifyContent="center" alignItems="center"><CircularProgress color="primary" /></Box>
															) : (
																<Fragment>
																	<Stepper data={rewardProgressData} paymentMethodID={item.PaymentMethodID} />
																	{item.PromotionStatus == 4 && (item.PaymentMethodID =="2" || item.PaymentMethodID ==2) && (item.RewardStatus === "Payment Failed" || item.RewardStatus === "Pending Enrollment")?																		<Box display="flex" p={2} justifyContent="right" alignItems="right" style={{ background: "white" }}>
																			<div className="paymentReinitiateWrapper">
																				{!isPaymentReinitiated ?
																					<Button style={{ float: "right" }} variant="contained" color="primary" onClick={() => this.setRewardToReinitiate(item.RewardID)}>ReInitiate Payment</Button> :
																					<CircularProgress color="primary" style={{ float: "right" }} />}
																			</div>
																		</Box> : null}
																</Fragment>
															)}
														</div>
													</Collapse>
												</TableCell>
											</TableRow>
										</Fragment>
									))
								)}
							</TableBody>
							<StyledTableFooter>
								<TableRow>
									<TablePagination
										rowsPerPageOptions={[10, 25, 50, 100]}
										colSpan={14}
										count={rewards.totalCount || 0}
										rowsPerPage={rowsPerPage}
										page={page}
										SelectProps={{
											inputProps: { 'aria-label': 'rows per page' },
											native: true,
										}}
										onChangePage={this.handlePageChange}
										onChangeRowsPerPage={this.handleRowsPerPageChange}
									/>
								</TableRow>
							</StyledTableFooter>
						</Table>
					</div>
				</Grid>
				<ConfirmDialog
					open={dialogOpen}
					title={""}
					message={"Are you sure you want to reinitiate the failed transaction?"}
					onCancel={() => {
						this.setState({ dialogOpen: false });
					}}
					onConfirm={() => {
						this.setState({ dialogOpen: false });
						this.reInitiatePayment();
					}}
				/>
				{rewards.error && this.renderSnackbar(rewards.error)}
				{showConfirmRemoveDialog && this.renderDeleteDialog('Are you sure you want to delete this reward?', '')}
				{alertMessage && this.renderAlertMessage('', alertMessage)}
				{showPendingModal && this.renderPendingModal("", "Please, ask the banker to enroll in the Zelle network or update preferred payment method to ACH to receive the payment.")}
			</Grid >
		)
	}

	renderSnackbar = message => {
		return <Notification variant="error" message={message} />
	}
	renderDeleteDialog = (title, message) => {
		return <ConfirmDialog title={title} message={message} onCancel={() => this.onCancelDelete()} onConfirm={() => this.onConfirmDelete()} />
	}
	renderAlertMessage = (title, message) => {
		return <AlertDialog title={title} message={message} onConfirm={() => this.hideAlertMessage()} />
	}
	renderPendingModal = (title, message) => {
		return <AlertDialog title={title} message={message} onConfirm={() => this.hidePendingModal()} />
	}
}

export default connect(state => (
	{ ...state.user, ...state.rewards, ...state.permissions }
))(RewardsListView)
