import React, { Component, Fragment } from 'react'
import { Box, Paper, TextField, Button, Grid, CircularProgress, Modal } from '@material-ui/core'
import { connect } from 'react-redux'
import { login, setNewPassword, getClientInfo } from '~/redux/actions/user'
import ResetPassword from './ResetPassword'
import './styles.scss'
import Recaptcha from 'react-recaptcha'

class Login extends Component {
    constructor(props) {
       super(props);
       this.handleRecaptcha = this.handleRecaptcha.bind(this);
    }

    state = {
        processing: false,
        buttonDisabled: true,
        showUpdatePasswordModal: false,
        loginId: null,
        password: null,
        recaptchaResponse: null
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.user.isLoggedIn) {
            if (!nextProps.user.info.PasswordChangedDate) {

            } else {
                const { clientKey } = nextProps.user;
                nextProps.history.push(`/${clientKey}/promotion`);
            }
        }
        return null
    }
    handleInputChange = e => {
        const { recaptchaResponse } = this.state;
        if (this.loginId.value && this.password.value && !this.state.processing && recaptchaResponse && recaptchaResponse.length > 0) {
            this.setState({
                buttonDisabled: false
            })
        } else {
            this.setState({
                buttonDisabled: true
            })
        }
    }
    handleRecaptcha = (e) => {
        console.log(e);
        this.setState({ recaptchaResponse: e });
        console.log(this.state.recaptchaResponse);
        this.handleInputChange();
    }
    processLogin = (event) => {
        event.preventDefault();
        const { recaptchaResponse } = this.state;
        const { clientKey } = this.props.match.params || "";
        try {
            if (this.loginId.value && this.password.value && recaptchaResponse && recaptchaResponse.length > 0 && !this.state.processing) {
                const creds = {
                    loginId: this.loginId.value,
                    password: this.password.value,
                    client: clientKey.toLowerCase()
                }
                this.setState({
                    processing: true,
                    error: null,
                    ...creds
                }, async () => {
                    const loginReponse = await this.props.dispatch(login(creds))
                    //console.log("login user", this.props.user);
                    const { user } = this.props;
                    if (user && user.info && user.info.PasswordChangedDate === null) {
                        this.setState({
                            processing: false,
                            showUpdatePasswordModal: true
                        })
                    }
                    if (!loginReponse) {
                        this.setState({
                            processing: false,
                            error: this.props.user.error || 'An error occured.'
                        })
                    }
                })
            }
        } catch (error) {
            this.setState({
                processing: false,
                error: typeof error === "string" ? error : 'An error occured.'
            })
        }
    }
    processReset = (OldPassword, NewPassword) => {
        const resetData = {
            UniqueUserIdentifier: this.props.user.info.UserName,
            NewPassword,
            OldPassword
        }
        return this.props.dispatch(setNewPassword(resetData))
    }
    render() {
        const { processing, buttonDisabled, showUpdatePasswordModal, error, recaptchaResponse } = this.state;
        const {metadata} = this.props.user;
        const { clientKey } = this.props.match.params || "";

        return (
            <Fragment>
                <div id="login" className="login-container">
                    <Grid container spacing={0}>
                        <Grid item sm={6}>
                            <div className="login-image-container" style={{
                                backgroundImage: `url('${require('~/assets/images/login-bg.jpg')}')`
                            }}></div>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <div className="login-form-container">
                                <div className="centered-container">
                                    <div className="logo-container">
                                        <img alt={metadata && metadata.alt} src={require(`~/assets/images/${clientKey.toLowerCase()}/logo.png`)} />
                                    </div>
                                    <div className="welcome-message">
                                        Welcome!
                                    </div>
                                    <div className="header">
                                        Login here
                                    </div>
                                    <form noValidate onSubmit={this.processLogin}>
                                        <TextField
                                            fullWidth={true}
                                            autoComplete="off"
                                            placeholder="LOGIN ID"
                                            variant="outlined"
                                            onChange={this.handleInputChange}
                                            inputProps={{
                                                ref: el => this.loginId = el
                                            }}
                                        />
                                        <TextField
                                            type="password"
                                            fullWidth={true}
                                            autoComplete="off"
                                            placeholder="PASSWORD"
                                            variant="outlined"
                                            onChange={this.handleInputChange}
                                            inputProps={{
                                                ref: el => this.password = el
                                            }}
                                        />
                                         <Recaptcha
                                            sitekey="6Lcnf9opAAAAAJgF12P3qcuajazEfTZO21BuvHBQ"
                                            render="explicit"
                                            theme="light"
                                            elementID="recaptcha"
                                            verifyCallback={(e) => this.handleRecaptcha(e)}
                                            expiredCallback ={() => this.handleRecaptcha(null)}
                                            onloadCallback={() => console.log("reCaptcha Initialized")} />
                                        <div className="form-footer">
                                            {error && (
                                                <Box color="error.main" className="error">
                                                    {typeof error === 'string' ? this.state.error : 'Something went wrong'}
                                                </Box>
                                            )}
                                            {processing ? (
                                                <CircularProgress color="primary" />
                                            ) : (
                                                    <Button disabled={buttonDisabled} type="submit" fullWidth={false} variant="contained" color="primary">
                                                        LOG IN
                                            </Button>
                                                )}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <Modal
                    open={showUpdatePasswordModal}
                    onClose={() => null}
                >
                    <Paper className="update-password-modal-container">
                        <Grid container justify="center">
                            <Grid item sm={6} xs={12}>
                                <ResetPassword error={this.props.user.error} processReset={this.processReset} />
                            </Grid>
                        </Grid>
                    </Paper>
                </Modal>
            </Fragment>
        )
    }
}

export default connect(state => (
    { ...state.user }
))(Login)